import React, { useState, useRef, MouseEvent } from "react";
import { IconButton } from "@material-ui/core";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import styled from "styled-components";
import { getIdWithExtensionFromUrl } from "src/utils/getIdWithExtensionFromUrl";

interface Props {
  imageUrl: string;
  wrapperStyle?: React.CSSProperties;
  imageContainerStyle?: React.CSSProperties;
  shouldOpenInNewTab?: boolean;
}

export default function ImageManipulator({
  imageUrl,
  wrapperStyle,
  imageContainerStyle,
  shouldOpenInNewTab,
}: Props) {
  const [imageRotation, setImageRotation] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [maxZoomOutLevel] = useState(0.2);

  const [dragging, setDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);

  const imageRef = useRef<HTMLImageElement>(null);

  const rotateImage = (angle: number) => {
    setImageRotation((prevRotation) => (prevRotation + angle) % 360);
    setOffsetX(0);
    setOffsetY(0);
  };

  const zoomIn = () => {
    setZoomLevel((prevZoom) => Math.min(prevZoom * 1.2, 3));
  };

  const zoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(prevZoom / 1.2, maxZoomOutLevel));
  };

  const resetZoom = () => {
    setZoomLevel(1);
    setOffsetX(0);
    setOffsetY(0);
  };

  const handleMouseDown = (e: MouseEvent) => {
    e.preventDefault();

    if (imageRef.current) {
      setDragging(true);
      setStartX(e.clientX - offsetX);
      setStartY(e.clientY - offsetY);
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (dragging && imageRef.current) {
      e.preventDefault();
      const newOffsetX = e.clientX - startX;
      const newOffsetY = e.clientY - startY;

      // Adjust offsets based on rotation
      const radians = (imageRotation * Math.PI) / 180;
      const cos = Math.cos(radians);
      const sin = Math.sin(radians);

      const rotatedOffsetX = cos * newOffsetX + sin * newOffsetY;
      const rotatedOffsetY = cos * newOffsetY - sin * newOffsetX;

      setOffsetX(rotatedOffsetX);
      setOffsetY(rotatedOffsetY);
    }
  };

  const handleOpenInNewTab = () => {
    window.open("/image/" + getIdWithExtensionFromUrl(imageUrl));
  };

  return (
    <div style={{ minHeight: 200, ...wrapperStyle }}>
      <ImageContainer style={imageContainerStyle}>
        <Image
          ref={imageRef}
          src={imageUrl}
          alt="image"
          style={{
            transform: `rotate(${imageRotation}deg) scale(${zoomLevel}) translate(${offsetX}px, ${offsetY}px)`,
          }}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          onMouseOut={handleMouseUp}
        />
      </ImageContainer>
      <ButtonContainer>
        <IconButton onClick={() => rotateImage(90)}>
          <RotateRightIcon />
        </IconButton>
        <IconButton onClick={() => rotateImage(-90)}>
          <RotateLeftIcon />
        </IconButton>
        <IconButton onClick={zoomIn}>
          <ZoomInIcon />
        </IconButton>
        <IconButton onClick={zoomOut}>
          <ZoomOutIcon />
        </IconButton>
        <IconButton onClick={resetZoom}>
          <CancelPresentationIcon />
        </IconButton>
        {shouldOpenInNewTab ? (
          <IconButton onClick={handleOpenInNewTab}>
            <OpenInNewIcon />
          </IconButton>
        ) : null}
      </ButtonContainer>
    </div>
  );
}

const ImageContainer = styled.div({
  display: "block",
  position: "relative",
  overflow: "hidden",
  width: "100%",
  height: "100%",
  minHeight: 150,
});

const Image = styled.img({
  cursor: "grab",
  transition: "transform 100ms",
  position: "absolute",
  width: "100%",
  height: "100%",
  objectFit: "contain",
});

const ButtonContainer = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "10px",
});
