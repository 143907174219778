import {
	Box,
	AppBar,
	Toolbar,
	Typography,
	Button,
	Grid,
	IconButton,
	makeStyles
} from "@material-ui/core"
import React, { useEffect, useState, useCallback, useRef } from "react"
import { Transition } from "react-transition-group"
import { Videocam } from "@material-ui/icons"
import VideoRecorder from "react-video-recorder"
import { useDispatch, useSelector } from "react-redux"
import { addRecords } from "../../reducers/Records/RecordsSlice"
// import Countdown from 'react-video-recorder/lib/defaults/countdown';
import { Countdown } from "./Countdown"
import { useActions } from "../../hooks/useActions"
import { Timer } from "./Timer"
import PlayButton from "../../assets/recorder/play_button.svg"
import PauseButton from "../../assets/recorder/pause_button.svg"
import { RecorderControlsWrapper } from "./RecorderControlsWrapper"
import { PlayPauseButton } from "./PlayPauseButton"
import { ConfirmRecord } from "./ConfirmRecord"
import { ConfirmDeleteButtons } from "./ConfirmDeleteButtons"

const useStyles = makeStyles({
	onHover: {
		"&:hover": {
			background: "#fff",
			opacity: "0.1"
		}
	}
})

export const TranslationRecorder = () => {
	const classes = useStyles()
	const {
		isCameraEnabled,
		isRecording,
		isRunningCountdown,
		isPaused,
		isFullscreen,
		confirmationWindow,
		deleteWindow
	} = useSelector((state) => state.recordsReducer)
	const {
		addRecords,
		toggleCamera,
		clearRecords,
		setIsRecording,
		setIsRunningCountdown,
		setIsPaused,
		setIsRecorderHovered
	} = useActions()
	const duration = 500
	const [isCameraOn, setIsCameraOn] = useState(false)
	const recorder = useRef(null)

	const defaultStyle = {
		transition: `all ${duration}ms ease-in-out`
	}

	const transitionStyles = {
		entering: {
			transformOrigin: "left",
			transform: "scaleX(1)"
		},
		entered: {
			transform: "scaleX(1)",
			transformOrigin: "left"
		},
		exiting: {
			transform: "scaleX(0.15)",
			transformOrigin: "left"
		},
		exited: {
			transform: "scaleX(0.15)",
			transformOrigin: "left"
		}
	}

	const boxTransition = {
		entering: {
			opacity: 0
		},
		entered: {
			opacity: 0
		},
		exiting: {
			opacity: 1
		},
		exited: {
			opacity: 1
		}
	}
	const iconTransition = {
		entering: {
			transform: "scale(1, 1)"
		},
		entered: {
			transform: "scale(1, 1)"
		},
		exiting: {
			transform: "scale(3.3, 0.5)"
		},
		exited: {
			transform: "scale(3.3, 0.5)"
		}
	}

	const smallscreen = {
		height: "100%"
	}

	const fullscreen = {
		height: "90vh",
		width: "90vw",
		position: "absolute",
		left: "5vw",
		top: "2.5vh"
	}

	const setRecorder = (r) => {
		recorder.current = r

		if (!isCameraOn) {
			r.onTurnOnCamera()
			setIsCameraOn(true)
		}

		if (!isRecording && r.streamIsReady) {
			const handleClick = () => {
				setIsRecording(true)

				if (isPaused) {
					r.onResumeRecording()
					setIsPaused(false)
				} else {
					r.onStartRecording()
				}
			}

			return (
				<RecorderControlsWrapper
					firstItem={<PlayPauseButton handleClick={handleClick} isDisabled={false} />}
					secondItem={isPaused ? <ConfirmDeleteButtons /> : ""}
				/>
			)
		}

		if (r.isRunningCountdown) {
			setIsRunningCountdown(true)

			return (
				<>
					<Box
						style={{
							width: 300,
							height: 100,
							position: "absolute",
							display: "flex",
							justifyContent: "center",
							alignItems: "center"
						}}
					>
						<Countdown />
					</Box>
					<RecorderControlsWrapper
						firstItem={<PlayPauseButton handleClick={() => {}} isDisabled={true} />}
						secondItem={""}
					/>
				</>
			)
		}

		if (r.isRecording) {
			setIsRunningCountdown(false)

			const handleClick = () => {
				r.onPauseRecording()
				setIsRecording(false)
				setIsPaused(true)
			}

			return (
				<RecorderControlsWrapper
					firstItem={<PlayPauseButton handleClick={handleClick} isDisabled={false} />}
					secondItem={""}
				/>
			)
		}
	}
	// console.log('isRecording', isRecording);
	// console.log("isCameraEnabled", isCameraEnabled);
	return (
		<>
			<Transition in={isCameraEnabled} timeout={duration}>
				{(state) => (
					<Box
						style={{
							width: 420,
							height: 236,
							backgroundColor: "#008DFF",
							zIndex: 100,
							...defaultStyle,
							...transitionStyles[state]
						}}
						id="publisher-element"
					>
						{isCameraEnabled ? (
							<Box
								style={isFullscreen ? fullscreen : smallscreen}
								onMouseOver={() => setIsRecorderHovered(true)}
								onMouseLeave={() => setIsRecorderHovered(false)}
							>
								<VideoRecorder
									onRecordingComplete={addRecords}
									renderDisconnectedView={() => {}}
									renderActions={setRecorder}
									countdownTime={4000}
								/>
							</Box>
						) : (
							<Transition in={isCameraEnabled} timeout={duration}>
								{(secState) => (
									<Box
										style={{
											width: 420,
											height: 365,
											backgroundColor: "#008DFF",
											position: "absolute",
											zIndex: 101,
											alignItems: "center",
											display: "flex",
											justifyContent: "center",
											...defaultStyle
											// ...boxTransition[secState],
										}}
									>
										<Videocam
											style={{
												width: 75,
												height: 75,
												color: "#fff",
												alignSelf: "center",
												transform: "scale(3.3, 0.5)",
												...defaultStyle,
												...boxTransition[state]
											}}
										/>
									</Box>
								)}
							</Transition>
						)}
					</Box>
				)}
			</Transition>
			{(confirmationWindow || deleteWindow) && (
				<ConfirmRecord recorder={recorder} setIsCameraOn={setIsCameraOn} />
			)}
		</>
	)
}
