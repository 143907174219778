import { useState, useEffect } from "react";
import { convertUnixTimestampToDateString } from "src/utils/CustomDate";

interface DateAndTimeComponents {
  date: DateComponents;
  time: TimeComponents;
}

interface DateComponents {
  year: string;
  month: string;
  day: string;
}

interface TimeComponents {
  hours: string;
  minutes: string;
}

function splitDateString(item: string): string[] | string {

  if (item.includes(":")) {
    return item.trim().split(":");
  }
  if (item.includes("/")) {
    return item.trim().split("/");
  }
  return item;
}

function useUnixTimeFormat(unixTimestamp: number): DateAndTimeComponents {
  const [dateComponents, setDateComponents] = useState<DateComponents>({
    year: "",
    month: "",
    day: "",
  });

  const [timeComponents, setTimeComponents] = useState<TimeComponents>({
    hours: "",
    minutes: "",
  });

  useEffect(() => {
    const [[localYear, localMounth, localDay], [localHours, localMinutes]] =
      convertUnixTimestampToDateString(unixTimestamp).split(",").map(splitDateString);

    setDateComponents({ day: localDay, month: localMounth, year: localYear });
    setTimeComponents({ hours: localHours, minutes: localMinutes });
  }, [unixTimestamp]);

  return {
    date: dateComponents,
    time: timeComponents,
  };
}

export default useUnixTimeFormat;
