// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable global-require */

const staticConfigFactory = require('./StaticConfig');

const StaticConfig = staticConfigFactory();

/**
 * @typedef errorReportingFactoryDeps
 * @property {StaticConfig} staticConfig
 */

module.exports = function errorReportingFactory(/** @type errorReportingFactoryDeps */ deps = {}) {
  const APIKEY = deps.APIKEY || require('../ot/api_key.js');
  const logging = deps.logging || require('../helpers/log')('errorReporting');
  const staticConfig = deps.staticConfig || StaticConfig.onlyLocal();
  const isEnabled = staticConfig.enableErrorReporting;

  let context = deps.global;
  if (!context && typeof window !== 'undefined') {
    context = window;
  }

  let Raven = deps.Raven;
  if (!Raven && context) {
    Raven = require('raven-js');
  }

  const whitelistUrlPattern = /(opentok|ot|tb|tokbox)(?:\.min)?\.js/;

  /*
   * Blocks error reporting until it has been explicitly enabled.
   *
   * The otError tag workaround is necessary because raven-js will
   * automatically wrap global error listeners. We prevent this by
   * only accepting errors that have the otError tag set by our
   * generateTags() function. It is deleted because it's not used
   * after this point.
   */
  const shouldSendCallback = (errorData) => {
    if (!isEnabled) {
      return false;
    }

    if (errorData && errorData.tags && errorData.tags.otError) {
      delete errorData.tags.otError; // eslint-disable-line no-param-reassign
      return true;
    }

    return false;
  };

  const installRaven = () => {
    if (Raven) {
      Raven
        .config(staticConfig.sentryDSN, {
          whitelistUrls: [whitelistUrlPattern],
          logger: 'webrtc-js',
          release: staticConfig.version,
          autoBreadcrumbs: false,
          shouldSendCallback,
        })
        .install()
        .noConflict();
    }
  };

  /*
   * The rawError object must be normalised to an instance of Error because
   * raven-js will cast anything else to a string. This prevents objects
   * like ExceptionEvent from being reported as "[object Object]".
   *
   * @param {Error|Object|String} rawError
   * @return {Error|String}
   */
  const normaliseError = (rawError) => {
    let error;

    if (rawError instanceof Error || typeof rawError === 'string') {
      error = rawError;
    } else if (rawError == null) {
      error = new Error('Undefined or null error was reported!');
    } else {
      error = new Error(rawError.message);
      ['name', 'title', 'code', 'stack'].forEach((key) => {
        if (rawError[key]) {
          error[key] = rawError[key];
        }
      });
    }

    if (error instanceof Error && !error.stack) {
      try {
        throw error;
      } catch (e) {
        // IE populates the error's .stack when it is thrown, nothing to do here
      }
    }

    return error;
  };

  /*
   * We generate a fingerprint because some unrelated errors can end up
   * being grouped together as the same issue, particularly normalised
   * errors that end up with identical stacktraces.
   * https://docs.sentry.io/learn/rollups/#customize-grouping-with-fingerprints
   */
  const generateFingerprint = (error) => {
    const fingerprint = ['{{ default }}'];

    if (typeof error === 'string') {
      fingerprint.push(`message::${error}`);
    } else if (error != null) {
      ['message', 'name', 'title', 'code'].forEach((key) => {
        if (error[key]) {
          fingerprint.push(`${key}::${error[key]}`);
        }
      });
    }

    return fingerprint;
  };

  const generateTags = (error) => {
    const tags = {
      otError: true,
      partnerId: APIKEY.value,
      buildHash: staticConfig.buildHash,
    };

    if (error instanceof Error) {
      ['name', 'title', 'code'].forEach((key) => {
        if (error[key]) {
          tags[`error.${key}`] = error[key];
        }
      });
    }

    return tags;
  };

  const errorReporting = {
    /*
     * Reports an error to Sentry.io if error reporting has been enabled.
     *
     * @param {Error|Object|String} rawError
     * @return {String} Report event ID
     */
    send(rawError) {
      if (!isEnabled || !Raven) {
        return null;
      }

      const error = normaliseError(rawError);

      const options = {
        fingerprint: generateFingerprint(error),
        tags: generateTags(error),
      };

      Raven.captureException(error, options);

      const lastId = Raven.lastEventId();
      logging.debug(`Reported error with ID: ${lastId}`, error, options);
      return lastId;
    },
  };

  const attachGlobalListener = () => {
    context.addEventListener('error', (e) => {
      if (e.error == null) {
        // unfortunately we can't do much about errors we know nothing about
        return;
      }
      errorReporting.send(e.error);
    });
  };

  if (isEnabled) {
    attachGlobalListener();
    installRaven();
  }

  return errorReporting;
};
