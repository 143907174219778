import React from "react";
import { Phone } from "@material-ui/icons";
import { IconButton, Input } from "@material-ui/core";
import { Icon, IconName } from "src/components/Icon";
import styled from "styled-components";
// import { CallService } from "src/services/CallService";
import toast from "react-hot-toast";
import { ContactsService } from "src/services/ContactsService";
import { CallService } from "src/services/CallService";
import { useCallContext } from "src/reducers/Call/CallProvider";

interface Props {
  isVisible: boolean;
  setIsVisible: () => void;
}

export function CallbackModal({ isVisible, setIsVisible }: Props) {
  const [phone, setPhone] = React.useState("");
  // @ts-ignore
  const { callState } = useCallContext();

  const handleCallback = async (phone: string) => {
    try {
      const contact = await handleGetContact(phone);

      if (contact.success) {
        CallService.makeCall(contact.id, {
          session_id: callState.selectedCall.session_id,
        })
          .then((data: any) => {
            if (!data.success) {
              toast.error(data.message);
              return;
            } else {
              toast("Дзвінок згенерований, очікуйте зʼєднання приблизно 30 секунд");
              setPhone("");
              setIsVisible()
            }
          })
          .catch(console.log);
      }
    } catch (error) {
      console.log("handleCallback", error);
    }
  };

  const handleGetContact = async (value: string) => {
    const cleanedValue = value.replace(/se1/gi, "").trim();

    const processedResponse = async (data: any) => {
      //   console.log("responce contact data", data);
      try {
        if (!data || !data.success) {
          toast(data.message);

          return;
        } else {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    };

    const defaultCountryCode = "+38";
    const phoneRegex = /^\d{9,14}$/;

    const hasCountryCode = cleanedValue.startsWith(defaultCountryCode);
    const isValidPhoneNumber = phoneRegex.test(cleanedValue) || hasCountryCode;

    if (isValidPhoneNumber) {
      const phoneNumber = hasCountryCode ? cleanedValue : defaultCountryCode + cleanedValue;
      return ContactsService.getContactByPhoneNumber(phoneNumber.trim()).then(processedResponse);
    } else {
      return ContactsService.getContactByDeafId(cleanedValue.trim()).then(processedResponse);
    }
  };

  return isVisible ? (
    <ContentWrapper>
      <div>
        <IconButton
          disabled={!Boolean(phone.length)}
          onClick={setIsVisible}
          style={{ marginLeft: "auto" }}
        >
          <Icon name={IconName.CROSS} />
        </IconButton>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Input
          style={{ flex: 1 }}
          value={phone}
          onChange={(e) => {
            setPhone(e.target.value);
          }}
          placeholder="Введіть номер телефону або айді клієнта"
        />
        <IconButton onClick={() => handleCallback(phone)}>
          <Phone />
        </IconButton>
      </div>
    </ContentWrapper>
  ) : null;
}

const ContentWrapper = styled.div({
  width: "340px",
  height: "auto",
  // backgroundColor: "white",
  borderRadius: "8px",
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  zIndex: 1000,
  padding: "8px",
  position: "absolute",
  right: 0,
  bottom: 0,
  backgroundColor: "#fff",
});
