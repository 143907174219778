import React from 'react'
import { Box, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const Buttons = ({ onCancel, onSwitch, switchDisable }) => {
    const { t } = useTranslation()

    return (
        <Box style={styles.wrapper}>
            <Button
                variant="outlined"
                color="primary"
                size="large"
                onClick={onCancel}
                style={{
                    ...styles.button,
                    marginRight: 20
                }}
            >
                {t('cancel')}
            </Button>
            <Button
                variant="contained"
                color="primary"
                size="large"
                disabled={switchDisable}
                onClick={onSwitch}
                style={styles.button}
            >
                {t('switch')}
            </Button>
        </Box>
    )
}

export default Buttons

const styles = {
    wrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '20px 20px 0'
    },
    button: {
        width: 'auto'
    }
}
