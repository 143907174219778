import React, { useState, useContext, createContext, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface PopupContextType {
  openPopup: ({ id, text, callback }: { id: number; text: string; callback: () => void }) => void;
  closePopup: (id: number) => void;
}

const PopupContext = createContext<PopupContextType | undefined>(undefined);

export const usePopupContext = (): PopupContextType => {
  const context = useContext(PopupContext);
  if (context === undefined) {
    throw new Error("usePopupContext must be used within a PopupProvider");
  }
  return context;
};

interface PopupProviderProps {
  children: ReactNode;
}

export const PopupProvider: React.FC<PopupProviderProps> = ({ children }) => {
  const [openPopups, setOpenPopups] = useState<
    { text: string; callback: () => void; id: number }[]
  >([]);

  const openPopup = ({
    id,
    text,
    callback,
  }: {
    id: number;
    text: string;
    callback: () => void;
  }): void => {
    setOpenPopups([...openPopups, { id, text, callback }]);
  };

  const closePopup = (id: number): void => {
    setOpenPopups(openPopups.filter((popup) => popup.id !== id));
  };

  return (
    <PopupContext.Provider value={{ openPopup, closePopup }}>
      {children}
      {openPopups.map(({ id, callback, text }) => {
        return (
          <CustomPopup
            isOpen
            onClose={() => {
              callback();
              closePopup(id);
            }}
            key={id}
          >
            <p>{text}</p>
          </CustomPopup>
        );
      })}
    </PopupContext.Provider>
  );
};

const CustomPopup = ({
  isOpen,
  onClose,
  children,
}: {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}) => {
  const { t } = useTranslation();
  if (!isOpen) return null;

  return (

      <CustomPopupContent>
        {children}
        <CustomButton onClick={onClose}>{t("call.connect")}</CustomButton>
      </CustomPopupContent>

  );
};

export const CustomPopupWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CustomPopupContent = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  align-items: center;
  gap: 8px;
`;

export const CustomButton = styled.button`
  background: #007bff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
`;
