import React, { CSSProperties, Dispatch, SetStateAction } from "react";
import { Box, Typography } from "@material-ui/core";
import OperatorItem from "./OperatorItem";
import { useTranslation } from "react-i18next";
import { Operator } from "src/types";

interface Props {
  list: Operator[] | null;
  operator: Operator | null;
  setOperator: Dispatch<SetStateAction<any>>;
}

const OperatorList = ({ list, operator, setOperator }: Props) => {
  const { t } = useTranslation();
  const hasItems = list && list.length > 0;

  return (
    <Box style={styles.wrapper}>
      {hasItems ? (
        list?.map((item) => (
          <OperatorItem key={item.id} item={item} operator={operator} setOperator={setOperator} />
        ))
      ) : (
        <Typography style={styles.nothinkText}>{t("nothinkOperators")}</Typography>
      )}
    </Box>
  );
};

export default OperatorList;

const styles: Record<string, CSSProperties> = {
  wrapper: {
    maxHeight: 302,
    overflowY: "scroll",
    borderTop: "1px solid #C4C4C4",
    borderBottom: "1px solid #C4C4C4",
  },
  nothinkText: {
    textAlign: "center",
    margin: "30px 0",
  },
};
