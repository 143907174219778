import { reasonTypes } from "src/pages/CallPage/types";
import { CallStatus } from "./CallStatus";

interface Minute {
  id: number;
  type: string;
  status: string;
  order_id: number | null;
  ordered: number;
  client_id: number;
  left: number;
  active_till: string;
  created_at: string;
  updated_at: string;
}

export enum CallTypes {
  CLIENT_CALL = "CLIENT_CALL",
  CONNECT_PRO_CALL = "CONNECT_PRO_CALL",
  PARTNER_CALL = "PARTNER_CALL",
  WIDGET_CALL = "WIDGET_CALL",
  QR_CALL = "QR_CALL",
  CONNECT_PRO_WEB_CALL = "CONNECT_PRO_WEB_CALL",
  CONNECT_PRO_WEB_GROUP_CALL = "CONNECT_PRO_WEB_GROUP_CALL",
}

export interface CallDataType {
  name: string;
  client_name: string | null;
  client_phone: string;
  display_reason: string;
  reason: reasonTypes;
  session_id: string;
  started_at: number;
  answered_at: number | null;
  ended_at: number;
  locked_at: number | null;
  status: CallStatus;
  rate: number;
  review: string;
  unlim: boolean;
  tags: string[];
  gender: string | null;
  date_of_birth: string | null;
  social_unity: boolean;
  minutes: Minute[];
  type: CallTypes;
  interpreter_id: number | null;
  commented: boolean;
  note: string | null;
  id: number;
  call?: any;
  deaf_id: null | string;
  client_id: number;
}
