import React, { CSSProperties } from "react";
import { Box } from "@material-ui/core";
import Lottie from "react-lottie";
import loader from "src/assets/lottie/loader.json";

interface Props {
  wrapperStyles?: CSSProperties;
}

export default function Loader({ wrapperStyles }: Props) {
  return (
    <Box
      style={{
        width: "100%",
        height: "100%",
        ...wrapperStyles,
      }}
    >
      <Lottie
        options={{
          animationData: loader,
          loop: true,
          autoplay: true,
        }}
      />
    </Box>
  );
}
