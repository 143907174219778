import React, { PureComponent, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import {
  Container,
  Text,
  Controls,
  ButtonMacMaximize,
  ButtonMacClose,
  ButtonMacMinimize,
  ButtonWindows,
  CloseButtonWindows,
} from "./styles";
import { Divider, Typography, Grid } from "@material-ui/core";
import styles from "./stylo.module.css";
import { ExitToApp, Settings, SettingsOutlined } from "@material-ui/icons";
import { AuthService } from "../../services/AuthService";
import { AuthContext } from "../../reducers/AuthReducer";
import { INTERPRETER_STATE } from "../../constants/defaultConstants";
import i18n from "../../translations/helpers/translations";
import { useTranslation } from "react-i18next";
import { useCallContext } from "../../reducers/Call/CallProvider";
import { CallActionTypes } from "../../reducers/Call/CallActionsTypes";
import { IconName, Icon } from "../Icon";
import { InterpreterStatus, RoutesMap } from "src/types";
import { RequestService } from "src/services/RequestService";
import { useNavigate } from "react-router-dom";

const Titlebar = (props) => {
  const { t } = useTranslation();
  const { callState, activityChange } = useCallContext();
  const navigate = useNavigate();

  const [authState, dispatch] = useContext(AuthContext);

  const statusOperatorHandler = (status) => {
    activityChange(status);
  };

  const isAuth = authState.authenticated();

  const RenderInterpreter = () => {
    const languagesArray = [
      {
        type: "lang",
        label: "Українська",
        value: "uk",
      },
      {
        type: "lang",
        label: "English",
        value: "en",
      },
      {
        type: "lang",
        label: "русский",
        value: "ru",
      },
    ];
    const dropdownItems = [
      {
        type: "button",
        label: t(InterpreterStatus.ONLINE),
        id: InterpreterStatus.ONLINE,
        onClick: () => {
          statusOperatorHandler(InterpreterStatus.ONLINE);
        },
      },
      {
        type: "button",
        label: t(InterpreterStatus.AWAY),
        id: InterpreterStatus.AWAY,
        onClick: () => {
          statusOperatorHandler(InterpreterStatus.AWAY);
        },
      },
      { type: "langSelector" },
      { type: "divider" },
      {
        type: "button",
        label: t("logout"),
        Icon: ExitToApp,
        onClick: () => {
          AuthService.signOut(() => {
            dispatch({
              type: INTERPRETER_STATE.SIGN_OUT,
            });
          });

          setTimeout(() => {
            navigate(RoutesMap.SIGN_IN, { replace: true });
          }, 1000);
        },
      },
    ];
    return (
      <div className={styles.dropdown}>
        <button className={styles.dropbtn}>
          {isAuth && (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              className={styles[`operatorStatus_${callState.operatorStatus}`]}
            >
              <Typography
                style={{
                  color: "#fff",
                  fontSize: "12px",
                  fontWeight: "600",
                }}
              >
                {t(callState.operatorStatus)}
              </Typography>
            </Grid>
          )}
          <div
            style={{
              minWidth: 200,
              height: 26,
              display: "flex",
              flexDirection: "row",
              alignSelf: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              style={{
                color: "#fff",
                alignSelf: "center",
                textAlign: "center",
                fontSize: 12,
                fontWeight: "500",
                marginRight: 10,
                color: "#A2ABB4",
              }}
            >
              {AuthService?.getUser()?.email}
            </Typography>
          </div>
        </button>
        <div className={styles.dropdown_content}>
          {dropdownItems.map((el, i) =>
            el.type === "divider" ? (
              <Divider key={el.type + "_" + i} />
            ) : el.type === "langSelector" ? (
              <div key={el.type + "_" + i} className={styles.left}>
                {t("changeLanguage")}
                <span className={styles.left_container}>
                  {languagesArray.map((el) => (
                    <span
                      key={el.value}
                      className={styles.left_item}
                      onClick={() => i18n.changeLanguage(el.value)}
                    >
                      {el.label}
                    </span>
                  ))}
                </span>
              </div>
            ) : (
              <div
                key={el.type + "_" + i}
                onClick={() => (el.type === "lang" ? i18n.changeLanguage(el.value) : el.onClick())}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: el.type === "button" && !el.id ? "space-between" : "flex-start",
                  alignItems: "center",
                }}
              >
                {el && el.id && el.id === callState.operatorStatus && (
                  <div className={styles[`operatorStatusIcon_${el.id}`]}></div>
                )}
                {el.label}
                {el.Icon && <el.Icon style={{ color: "#A2ABB4" }} />}
              </div>
            )
          )}
        </div>
      </div>
    );
  };

  const { backgroundColor, title } = props;

  return (
    <Container backgroundColor={backgroundColor}>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center", marginLeft: "8px" }}
      >
        <Icon name={IconName.LOGO} color="#00AAF6" size="32" />
        <Text key="title-text">{title}</Text>
      </div>
      <RenderInterpreter />
    </Container>
  );
};

Titlebar.defaultProps = {
  title: null,
  backgroundColor: "#000000",
};

Titlebar.propTypes = {
  title: PropTypes.string,
  backgroundColor: PropTypes.string,
};

export default Titlebar;
