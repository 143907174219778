import React from "react";
import styled from "styled-components";
import { Box, ButtonBase, IconButton, Typography, Input } from "@material-ui/core";
import { Icon, IconName } from "src/components/Icon";
import { CallService } from "src/services/CallService";

interface Props {
  isVisible: boolean;
  setIsVisible: () => void;
}

export function EmergencyDictionary({ isVisible, setIsVisible }: Props) {
  const [emergencyList, setEmergencyList] = React.useState<
    { id: number; name: string; phone: string }[]
  >([]);
  const [visibleItems, setVisibleItems] = React.useState<number[]>([]);
  const [filterValue, setFilterValue] = React.useState("");

  React.useEffect(() => {
    CallService.getEmergencyNumbers().then((d: any) => {
      if (d.success) {
        setEmergencyList(d.numbers);
      } else {
        console.error(d.message);
      }
    });

    return () => {
      setVisibleItems([]);
      setFilterValue("");
    };
  }, []);

  const filteredMap = React.useMemo(() => {
    return emergencyList
      .filter((el) => {
        return el.name.toLowerCase().trim().includes(filterValue.toLowerCase().trim());
      })
      .sort((a, b) => a.id - b.id);
  }, [filterValue, emergencyList]);

  return isVisible ? (
    <ContentWrapper>
      <Box
        style={{
          position: "sticky",
          top: 0,
          backgroundColor: "#fff",
          display: "flex",
          alignItems: "center",
          zIndex: 1,
        }}
      >
        <Input
          style={{ flex: 1 }}
          value={filterValue}
          onChange={(e) => {
            setFilterValue(e.target.value);
          }}
          placeholder="Пошук"
        />
        <IconButton onClick={setIsVisible} style={{ alignSelf: "flex-end" }}>
          <Icon name={IconName.CROSS} />
        </IconButton>
      </Box>
      <ul>
        {filteredMap.map((el) => (
          <li key={el.id.toString()}>
            <ButtonBase
              style={{ width: "100%", justifyContent: "flex-start" }}
              onClick={() =>
                setVisibleItems((prev) =>
                  prev.includes(el.id) ? prev.filter((id) => id !== el.id) : [...prev, el.id]
                )
              }
            >
              <Typography>
                {el.name} {visibleItems.includes(el.id) ? "-" : "+"}
              </Typography>
            </ButtonBase>
            {visibleItems.includes(el.id) ? <p>{el.phone}</p> : null}
          </li>
        ))}
      </ul>
    </ContentWrapper>
  ) : null;
}

const ContentWrapper = styled.div({
  width: "340px",
  height: "60vh",
  // backgroundColor: "white",
  borderRadius: "8px",
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  zIndex: 1000,
  padding: "8px",
  position: "absolute",
  right: 0,
  bottom: 0,
  backgroundColor: "#fff",
  paddingTop: 0,
});
