import moment from 'moment';
import React, { Component } from 'react';

export class Timer extends Component {
	componentDidMount() {
		this.interval = setInterval(this.update, 1000);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	update = this.forceUpdate.bind(this);
	start = new Date();

	render() {

		const now = new Date();
		const diff = now.getTime() - this.props.start;
		const timer = moment(diff).utc();
		return diff < 0 //kostil
			? '00:00'
			: timer.format(timer._i < 3600 * 1000 ? 'mm:ss' : 'HH:mm:ss');
	}
}
