import { createSlice } from "@reduxjs/toolkit";

export const RecordsSlice = createSlice({
	name: 'records',
	initialState: {
		record: null,
		recordToClear: null,
		isCameraEnabled: false,
		isRecording: false,
		isRunningCountdown: false,
		isPaused: false,
		isRecorderHovered: false,
		isFullscreen: false,
		confirmationWindow: false,
		deleteWindow: false
	},
	reducers: {
		addRecords: (state, action) => {
			state.record = action.payload;
		},
		clearRecords: (state, action) => {
			state.recordToClear = action.payload;
		},
		toggleCamera: (state, action) => {
			state.isCameraEnabled = action.payload;
		},
		setIsRecording: (state, action) => {
			state.isRecording = action.payload;
		},
		setIsRunningCountdown: (state, action) => {
			state.isRunningCountdown = action.payload;
		},
		setIsPaused: (state, action) => {
			state.isPaused = action.payload;
		},
		setIsRecorderHovered: (state, action) => {
			state.isRecorderHovered = action.payload;
		},
		setIsFullscreen: (state, action) => {
			state.isFullscreen = action.payload;
		},
		callConfirmationWindow: (state, action) => {
			state.confirmationWindow = action.payload;
		},
		callDeleteWindow: (state, action) => {
			state.deleteWindow = action.payload;
		},
	}
});

export const {
	addRecords,
	clearRecords,
	toggleCamera,
	setIsRecording,
	setIsRunningCountdown,
	setIsPaused,
	setIsRecorderHovered,
	setIsFullscreen,
	callConfirmationWindow,
	callDeleteWindow
} = RecordsSlice.actions;
export default RecordsSlice.reducer;
