import { bindActionCreators } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import * as fileActions from '../reducers/Translations/FileSlice';
import * as recordsAction from '../reducers/Records/RecordsSlice';
import * as translationActions from '../reducers/Translations/TranslationsSlice';

export const useActions = () => {
	const dispatch = useDispatch();

	return bindActionCreators({
		...fileActions,
		...recordsAction,
		...translationActions
	 }, dispatch);
};
