/*
 * Environment details
 *
 * Contains information about the current environment.
 *
 * @property name The name of the Environment (Chrome, FF, Node, etc)
 * @property version Usually a Float, except in Node which uses a String
 * @property userAgent The raw user agent
 *
 */

const env = {
  version: -1, // @todo why is the default -1?
  name: 'unknown',
};

if (process && process.versions && typeof process.versions.node === 'string') {
  env.name = 'Node';
  env.isNode = true;
  env.version = parseInt(process.versions.node, 10);
  env.userAgent = `${env.name} ${env.version}`;
} else {
  env.userAgent = global.navigator.userAgent.toLowerCase(); // @todo why lowercase?
  env.appName = global.navigator.appName;
  env.navigatorVendor = undefined;
  env.name = 'unknown';

  if (env.userAgent.indexOf('opera') > -1 || env.userAgent.indexOf('opr/') > -1) {
    env.name = 'Opera';
    env.isOpera = true;

    if (/opr\/([0-9]{1,}[.0-9]{0,})/.exec(env.userAgent) !== null) {
      env.version = parseFloat(RegExp.$1);
    }
  } else if (env.userAgent.indexOf('firefox') > -1) {
    env.name = 'Firefox';
    env.isFirefox = true;

    if (/firefox\/([0-9]{1,}[.0-9]{0,})/.exec(env.userAgent) !== null) {
      env.version = parseFloat(RegExp.$1);
    }
  } else if (env.appName === 'Microsoft Internet Explorer') {
    // IE 10 and below
    env.name = 'IE';
    env.isIE = true;

    if (/msie ([0-9]{1,}[.0-9]{0,})/.exec(env.userAgent) !== null) {
      env.version = parseFloat(RegExp.$1);
    }
  } else if (env.userAgent.match(/edge?\/(\d+)(?:\.\d+)+$/)) {
    env.name = 'Edge';
    env.isEdge = true;

    const version = RegExp.$1;

    env.version = parseInt(version, 10);
  } else if (env.appName === 'Netscape' && env.userAgent.indexOf('trident') > -1) {
    // IE 11+
    env.name = 'IE';
    env.isIE = true;

    if (/trident\/.*rv:([0-9]{1,}[.0-9]{0,})/.exec(env.userAgent) !== null) {
      env.version = parseFloat(RegExp.$1);
    }
  } else if (env.userAgent.indexOf('chrome') > -1) {
    env.name = 'Chrome';
    env.isElectron = env.userAgent.indexOf('electron/') > -1;

    if (!env.isElectron) {
      env.isChrome = true;
    }

    if (/chrome\/([0-9]{1,}[.0-9]{0,})/.exec(env.userAgent) !== null) {
      env.version = parseFloat(RegExp.$1);
    }
  } else if (global.navigator.vendor &&
    global.navigator.vendor.toLowerCase().indexOf('apple') > -1) {
    env.name = 'Safari';
    env.isSafari = true;

    if (/version\/([0-9]{1,}[.0-9]{0,})/.exec(env.userAgent) !== null) {
      env.version = parseFloat(RegExp.$1);
    }
  } else if (env.userAgent.indexOf('applewebkit') > -1) {
    // From https://stackoverflow.com/questions/4460205/detect-ipad-iphone-webview-via-javascript
    if (env.userAgent.indexOf('version') === -1) {
      env.isIosWebView = true;
    }
  }

  // The Chromium-based Edge was released on this version
  env.isChromiumEdge = env.name === 'Edge' && env.version >= 79;

  // Prior versions ofo Edge versions were based on a proprietary, non-Chromium
  // code base
  env.isLegacyEdge = env.name === 'Edge' && env.version < 79;

  env.userAgent = global.navigator.userAgent;
  env.protocol = global.location.protocol;
  env.hostName = global.location.hostName;
}

module.exports = env;
