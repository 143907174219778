import React, { ReactNode, createContext, useReducer } from "react";
import { INTERPRETER_STATE } from "../constants/defaultConstants";
import { AuthService } from "../services/AuthService";
import { useDispatch } from "react-redux";
// import { setCurrentInterpreter } from "./Interpreter";
// import { InterpreterService } from "src/services/InterpreterService";
// import { Operator } from "src/types";
import { fetchCurrentInterpreter } from "./Interpreter/operations";
import { InterpreterService } from "src/services/InterpreterService";
import { InterpreterStatus } from "src/types";
// import { InterpreterStatus } from "src/types";
// import { InterpreterService } from "src/services/InterpreterService";

interface AuthState {
  authenticated: () => boolean;
  error: any;
}

interface Action {
  type: string;
  payload?: {
    rememberMe?: boolean;
    user?: { email: string; id: number; token: string };
  };
}

const initialState: AuthState = {
  authenticated: () => {
    const user = AuthService.getUser();
    if (user == null) {
      return false;
    }
    return true;
  },
  error: null,
};

const Reducer = (state: AuthState, action: Action) => {
  switch (action.type) {
    case INTERPRETER_STATE.SIGN_IN:
      if (action.payload?.rememberMe) {
        AuthService.setUser(action.payload.user);
      }
      return {
        ...state,
        ...action.payload?.user,
      };
    case INTERPRETER_STATE.SIGN_OUT:
      AuthService.setUser(null);
      return { ...initialState };
    case INTERPRETER_STATE.AUTH_ERROR:
      AuthService.setUser(null);
      return { ...initialState };
    default:
      return state;
  }
};

const AuthReducer = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  const dispatchRedux = useDispatch();

  React.useEffect(() => {
    const handleBeforeUnload = async (e: BeforeUnloadEvent): Promise<string | undefined | any> => {
      const url = window.location.href;
      const imageExtensions = [".jpg", ".jpeg", ".png", ".gif", ".bmp"];
      const isImage = imageExtensions.some((ext) => url.toLowerCase().includes(ext));

      if (isImage) return;

      e.preventDefault();

      const confirmationMessage = "Are you sure you want to leave this page?";
      e.returnValue = confirmationMessage;
      if (confirmationMessage) {
        try {
          await InterpreterService.handleActivity(InterpreterStatus.OFFLINE);

          setTimeout(async () => {
            await InterpreterService.handleActivity(InterpreterStatus.ONLINE);
          }, 5000);
        } catch (error) {
          console.error("Error handling beforeunload:", error);
        }
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    // window.addEventListener("unload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      // window.removeEventListener("unload", handleBeforeUnload);
    };
  }, []);

  React.useEffect(() => {
    const isAuth = state.authenticated();

    if (!isAuth) return;

    dispatchRedux(fetchCurrentInterpreter());
  }, [dispatchRedux, state]);

  return <AuthContext.Provider value={[state, dispatch]}>{children}</AuthContext.Provider>;
};

export const AuthContext = createContext<[AuthState, React.Dispatch<Action>]>([
  initialState,
  () => {},
]);

export default AuthReducer;
