import React, { useState, useEffect, useMemo } from "react";

interface IntersectionObserverOptions {
  root: Element | null;
  rootMargin: string;
  threshold: number;
  triggerOnce?: boolean;
}

export const useObserver = (
  options: IntersectionObserverOptions,
  targetRef: React.RefObject<Element>
): boolean => {
  const [isVisible, setIsVisible] = useState(false);

  const callbackFunction: IntersectionObserverCallback = (entries, observer) => {
    const [entry] = entries;
    if (entry.isIntersecting) {
      setIsVisible(true);
      if (options.triggerOnce) {
        observer.unobserve(entry.target);
      }
    } else {
      setIsVisible(false);
    }
  };
  // const callbackFunction: IntersectionObserverCallback = (entries) => {
  //   const [entry] = entries;
  //   setIsVisible(entry.isIntersecting);
  // };

  const optionsMemo = useMemo(() => {
    return options;
  }, [options]);

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, optionsMemo);
    const currentTarget = targetRef.current;

    if (currentTarget) {
      observer.observe(currentTarget);
    }

    return () => {
      if (currentTarget) {
        observer.unobserve(currentTarget);
      }
    };
  }, [targetRef, optionsMemo]);

  return isVisible;
};
