import { createSlice } from "@reduxjs/toolkit";
import { Admission } from "src/types";

interface AdmissionsState {
  currentAdmission: null | Admission;
  newAdmissions: Admission[];
  admissions: Admission[];
  filteredAdmissions: Admission[];
}

const initialState: AdmissionsState = {
  currentAdmission: null,
  newAdmissions: [],
  admissions: [],
  filteredAdmissions: [],
};

const admissionsSlice = createSlice({
  name: "admissions",
  initialState,
  reducers: {
    setCurrentAdmition(state, { payload }) {
      state.currentAdmission = payload;
    },
    clearCurrentAdmission(state) {
      state.currentAdmission = null;
    },
    setNewAdmissions(state, action) {
      state.newAdmissions = action.payload;
    },
    setAdmissions(state, action) {
      state.admissions = action.payload;
    },
    setFilteredAdmissions(state, action) {
      state.filteredAdmissions = action.payload;
    },
    clearFilteredAdmissions(state) {
      state.filteredAdmissions = [];
    },
    deleteAdmission(state, action) {
      const id = action.payload;
      state.admissions = state.admissions.filter((admission) => admission.id !== id);
      state.newAdmissions = state.newAdmissions.filter((admission) => admission.id !== id);
      state.filteredAdmissions = state.filteredAdmissions.filter(
        (admission) => admission.id !== id
      );
    },
    approveAdmission(state, action) {
      const id = action.payload;

      if (state.currentAdmission && state.currentAdmission.id === id) {
        state.currentAdmission.validated = true;
      }
      state.newAdmissions = state.newAdmissions.filter((admission) => admission.id !== id);
      state.admissions = state.admissions.map((admission) =>
        admission.id === id ? { ...admission, validated: true } : admission
      );
      state.filteredAdmissions = state.filteredAdmissions.map((admission) =>
        admission.id === id ? { ...admission, validated: true } : admission
      );
    },
    updateAdmission(state, action) {
      const updatedAdmission = action.payload;
      state.admissions = state.admissions.map((admission) =>
        admission.id === updatedAdmission.id ? { ...admission, ...updatedAdmission } : admission
      );
      state.newAdmissions = state.newAdmissions.map((admission) =>
        admission.id === updatedAdmission.id ? { ...admission, ...updatedAdmission } : admission
      );
      state.filteredAdmissions = state.filteredAdmissions.map((admission) =>
        admission.id === updatedAdmission.id ? { ...admission, ...updatedAdmission } : admission
      );
    },
    appendNewAdmissions(state, action) {
      state.newAdmissions = [...state.newAdmissions, ...action.payload];
    },
    appendAdmissions(state, action) {
      state.admissions = [...state.admissions, ...action.payload];
    },
    appendFilteredAdmissions(state, action) {
      state.filteredAdmissions = [...state.filteredAdmissions, ...action.payload];
    },
  },
});

export const {
  clearCurrentAdmission,
  setCurrentAdmition,
  deleteAdmission,
  setAdmissions,
  setFilteredAdmissions,
  setNewAdmissions,
  updateAdmission,
  appendAdmissions,
  appendFilteredAdmissions,
  appendNewAdmissions,
  clearFilteredAdmissions,
  approveAdmission,
} = admissionsSlice.actions;
export default admissionsSlice.reducer;
