import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAvailableReasons,
  selectSessionIdToSubmit,
  selectUserReasonSelected,
} from "src/reducers/Reasons/selectors";
import { CallReason } from "src/types";
import styled from "styled-components";
import { CustomRadio } from "src/components/CustomRadio";
import { CallService } from "src/services/CallService";
import {
  resetSessionIdToSubmit,
  resetUserReasonSelected,
  setShouldDisplayReasonForm,
} from "src/reducers/Reasons";
import { Button } from "@material-ui/core";

interface Props {
  isOpen: boolean;
  handleClose: () => void;
}

export default function ReasonModal({ isOpen, handleClose }: Props) {
  const [currentReason, setCurrentReason] = useState<CallReason | null>(null);
  const [otherReason, setOtherReason] = useState("other");
  const { t } = useTranslation();
  const availableReasons = useSelector(selectAvailableReasons);
  const dispatch = useDispatch();
  const selectUserReason = useSelector(selectUserReasonSelected);
  const selectSessionId = useSelector(selectSessionIdToSubmit);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const isSelectedOtherReason = currentReason === CallReason.OTHER;

  const submitReason = useCallback(async () => {
    const response: any = await CallService.putReasonBySessionId({
      sessionId: selectSessionId,
      data: {
        reason: currentReason,
        ...(isSelectedOtherReason && { other_reason: otherReason }),
      },
    });

    if (!response.success) {
      return;
    }

    handleClose();

    dispatch(resetSessionIdToSubmit());
    dispatch(resetUserReasonSelected());
    dispatch(setShouldDisplayReasonForm(false));

    setCurrentReason(null);
    setOtherReason("");
  }, [handleClose, currentReason, selectSessionId, dispatch, isSelectedOtherReason, otherReason]);

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentReason(e.target.value as CallReason);
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setOtherReason(event.target.value);
  };

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key >= "1" && event.key <= String(availableReasons?.length)) {
        const index = parseInt(event.key, 10) - 1;
        if (availableReasons && index >= 0 && index < availableReasons.length) {
          setCurrentReason(availableReasons[index].slug);
        }
      }
      if (textareaRef.current) {
        textareaRef.current.focus();
      }
      if (event.code === "Enter" && currentReason) {
        submitReason();
      }
    };

    if (isOpen) {
      document.addEventListener("keydown", handleKeyPress);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [isOpen, availableReasons, currentReason, submitReason]);

  const isDisabledSubmit = useMemo(() => {
    if (!Boolean(currentReason)) {
      return true;
    }
    // if (isSelectedOtherReason) {
    //   return !Boolean(otherReason);
    // }

    return false;
  }, [currentReason]);

  return isOpen ? (
    <Backdrop>
      <Modal>
        <Title>{t("chooseReason")}</Title>
        <Subtitle>
          {t("userChoose")} <Chip>{t(`reasons.${selectUserReason}`)}</Chip>
        </Subtitle>
        <ReasonsList>
          {availableReasons?.map((reason: { slug: CallReason; label: string }) => {
            return (
              <li key={reason.slug}>
                <CustomRadio
                  handleCheck={handleCheck}
                  checked={reason.slug === currentReason}
                  label={reason.label}
                  value={reason.slug}
                />
              </li>
            );
          })}
        </ReasonsList>
        {isSelectedOtherReason ? (
          <TextArea
            ref={textareaRef}
            placeholder={t("reasonPlaceholder")}
            onChange={handleChange}
            value={otherReason}
          />
        ) : null}
        <Button
          // type="submit"
          disabled={isDisabledSubmit}
          style={{ width: "100%", fontSize: 14 }}
          variant="outlined"
          onClick={submitReason}
        >
          {t("submitReason")}
        </Button>
      </Modal>
    </Backdrop>
  ) : null;
}

const Backdrop = styled.div({
  position: "fixed",
  zIndex: 200,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
});

const Modal = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: 10,
  borderRadius: 8,
  padding: 16,
  position: "absolute",
  zIndex: 300,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#fff",
  width: "413px",
  height: "auto",
});

const Title = styled.h2({
  fontSize: "1rem",
  textAlign: "center",
});

const Subtitle = styled.h3({
  fontSize: "1rem",
  padding: 8,
});

const Chip = styled.span({
  color: "#008dff",
});

const ReasonsList = styled.ul({
  "& > li:not(:last-child)": {
    marginBottom: 8,
  },
});

const TextArea = styled.textarea({
  borderRadius: 8,
  padding: 8,
  width: "100%",
  resize: "none",
  height: "auto",
  minHeight: "100px",
  maxHeight: "200px",
  overflowY: "auto",
});
