import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { Modal, Typography, Box } from "@material-ui/core";
import Controls from "./components/Controls";
import OperatorList from "./components/OperatorList";
import Buttons from "./components/Buttons";
import SuccessfullySwitched from "./SuccessfullySwitched";
import { useTranslation } from "react-i18next";
// import { useCallContext } from "../../reducers/Call/CallProvider";
// import { useDispatch } from "react-redux";
import { InterpreterService } from "src/services/InterpreterService";
// import { AuthService } from "../../services/AuthService";

// import { useDispatch } from "react-redux";
import { InterpreterStatus, Operator } from "src/types";
import { useSelector } from "react-redux";
import { selectCurrentInterpreter } from "src/reducers/Interpreter/selectors";
import { useCallContext } from "src/reducers/Call/CallProvider";
import toast from "react-hot-toast";
// import { setShouldDisplayReasonForm } from "src/reducers/Reasons";

interface Props {
  isOpen: boolean;
  setIsOpen: () => void;
}

const SwitchOperator = ({ isOpen, setIsOpen }: Props) => {
  const { t } = useTranslation();
  const availableStatuses: ("all" | InterpreterStatus)[] = [
    "all",
    ...Object.values(InterpreterStatus),
  ];
  const { callState, sessionRef, publisher, endCall } = useCallContext() as any;
  const [allOperators, setAllOperators] = useState<Operator[]>([]);
  const [statusFilter, setStatusFilter] = useState<string | InterpreterStatus>(
    InterpreterStatus.ONLINE
  );
  const [searchValue, setSearchValue] = useState("");
  //   const [languagesList] = useState([]);
  // const [filteredOperators, setFilteredOperators] = useState(null);
  const [selectedOperatorToSwitch, setSelectedOperatorToSwitch] = useState<Operator | null>(null);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  // const { switchOperator } = useCallContext() as any;
  // const dispatch = useDispatch();
  const currentInterpreter: Operator = useSelector(selectCurrentInterpreter);

  useEffect(() => {
    async function fetchData() {
      const data = await InterpreterService.getInterpreters();

      const filteredData = data.data.filter(
        (operator: Operator) => operator.id !== currentInterpreter.id
      );
      setAllOperators(filteredData);
    }

    fetchData();
  }, [currentInterpreter.id]);

  // useEffect(() => {
  //   async function fetch() {
  //     const data: any = await InterpreterService.getInterpreters();
  //     if (!data.success) return;

  //     return data.data;
  //   }

  //   fetch().then((data) => {
  //     setAllOperators(data.filter((operator: Operator) => operator.id !== currentInterpreter.id));
  //   });
  // }, []);
  // console.log("allOperators", allOperators);
  // console.log("selectedOperatorToSwitch", selectedOperatorToSwitch);

  const handleClose = () => {
    setIsOpen();
    // setFilteredOperators(null);
    setSelectedOperatorToSwitch(null);
  };

  const handleSwitch = async () => {
    if (selectedOperatorToSwitch === null) return;

    InterpreterService.redirectToInterpreterById({
      interpreter_id: selectedOperatorToSwitch.id,
      session_id: callState.selectedCall.session_id,
    })
      .then(() => {
        sessionRef.current.sessionHelper.session.unpublish(publisher.current);
        setIsOpen();
        endCall();
        setIsSuccessModalOpen(true);
      })
      .catch((e) => {
        toast(e);
        throw new Error(e);
      });
  };

  const handleChangeStatus = (value: string | InterpreterStatus) => {
    setStatusFilter(value);
  };
  const handleSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };
  const visibleOperators = useMemo(() => {
    if (statusFilter === "all" && !searchValue) return allOperators;

    return allOperators.filter((operator) => {
      const matchesStatus = statusFilter === "all" || operator.online === statusFilter;
      const matchesSearch =
        !searchValue || operator.name.toLowerCase().includes(searchValue.toLowerCase());

      return matchesStatus && matchesSearch;
    });
  }, [statusFilter, searchValue, allOperators]);

  //   useEffect(() => {
  //     setAllOperators(
  //       callState.operators.filter(
  //         (el) => el?.activity !== "offline" && el.login !== AuthService.getUser().email
  //       )
  //     );
  //   }, [callState.operators]);

  const switchDisable = selectedOperatorToSwitch === null;

  //   const switchUser = () => {
  //     switchOperator(switchedOperator.socketId);
  //   };

  return (
    <>
      <SuccessfullySwitched
        isOpen={isSuccessModalOpen}
        setIsOpen={setIsSuccessModalOpen}
        switchedOperator={selectedOperatorToSwitch}
        handleClose={handleClose}
      />
      <Modal open={isOpen} onClose={handleClose} style={styles.modal}>
        <Box style={styles.container}>
          <Box style={styles.header}>
            <Typography style={styles.title}>{t("switchOperator")}</Typography>
            <Controls
              setFilterValue={handleChangeStatus}
              statusFilter={statusFilter}
              availableStatuses={availableStatuses}
              searchValue={searchValue}
              setSearchValue={handleSearchValueChange}
              //   allOperators={allOperators}
              // allOperators={mockOperators as any}
              // setFilteredOperators={setFilteredOperators}
              //   languagesList={languagesList}
            />
          </Box>
          <OperatorList
            list={visibleOperators}
            // list={filteredOperators ?? allOperators}
            operator={selectedOperatorToSwitch}
            setOperator={setSelectedOperatorToSwitch}
          />
          <Buttons onCancel={handleClose} onSwitch={handleSwitch} switchDisable={switchDisable} />
        </Box>
      </Modal>
    </>
  );
};

export default SwitchOperator;

const styles = {
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow:
      "0px 8px 10px rgba(0, 0, 0, 0.2), 0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12)",
  },
  container: {
    backgroundColor: "#fff",
    width: "500px",
    borderRadius: 10,
    paddingTop: 20,
    paddingBottom: 20,
  },
  header: {
    padding: "5px 20px 17px 11px",
  },
  title: {
    fontSize: 18,
    fontWeight: "600",
    marginBottom: 17,
  },
};
