import { createSlice } from "@reduxjs/toolkit";
import { GroupTranslation } from "src/types";

interface GroupCallState {
  groupCallsList: GroupTranslation[];
  currentGroupSession: GroupTranslation | null;
}

const initialState: GroupCallState = {
  groupCallsList: [],
  currentGroupSession: null,
};

const groupCallSlice = createSlice({
  name: "groupCalls",
  initialState,
  reducers: {
    setCalls(state, action) {
      state.groupCallsList = [...action.payload];
    },
    updateCall(state, action) {

      const foundedItem = state.groupCallsList.find((el) => el.id === action.payload.id);

      if (foundedItem) {
        Object.assign(foundedItem, { ...action.payload });
      }
    },
    removeCall(state, action) {
      state.groupCallsList = [
        ...state.groupCallsList.filter((session) => session.id !== action.payload),
      ];
    },
    appendNewCall(state, action) {
      state.groupCallsList = [action.payload, ...state.groupCallsList];
    },
    updateSpeakerStatusOnline(state, action) {
      const index = state.groupCallsList.findIndex((group) => group.id === action.payload);
      Object.assign(state.groupCallsList[index], { speaker_connected: true });

      if (state.currentGroupSession && state.currentGroupSession.id === action.payload) {
        Object.assign(state.currentGroupSession, { speaker_connected: true });
      }
    },
    updateSpeakerStatusOffline(state, action) {
      const index = state.groupCallsList.findIndex((group) => group.id === action.payload);
      Object.assign(state.groupCallsList[index], { speaker_connected: false });

      if (state.currentGroupSession && state.currentGroupSession.id === action.payload) {
        Object.assign(state.currentGroupSession, { speaker_connected: false });
      }
    },
    updateListenersAmount(state, action) {
      const index = state.groupCallsList.findIndex((group) => group.id === action.payload.group_id);
      Object.assign(state.groupCallsList[index], {
        listeners: state.groupCallsList[index].listeners + action.payload.amount,
      });

      if (state.currentGroupSession && state.currentGroupSession.id === action.payload) {
        Object.assign(state.currentGroupSession, {
          listeners: state.currentGroupSession.listeners + action.payload.amount,
        });
      }
    },
    setCurrentGroupSession(state, action) {
      state.currentGroupSession = action.payload;
    },
    cleanCurrentGroupSession(state) {
      state.currentGroupSession = null;
    },
  },
});

export const {
  setCalls,
  cleanCurrentGroupSession,
  setCurrentGroupSession,
  updateSpeakerStatusOnline,
  updateSpeakerStatusOffline,
  updateListenersAmount,
  appendNewCall,
  updateCall,
  removeCall,
} = groupCallSlice.actions;
export default groupCallSlice.reducer;
