import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@material-ui/core";
import { Button, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import moment from "moment";
import {
  PhoneAndroidOutlined,
  Close,
  PlaceOutlined,
  WcOutlined,
  EmailOutlined,
  CakeOutlined,
  SignLanguageOutlined,
} from "@mui/icons-material";
import toast from "react-hot-toast";

import Logo from "src/Logo";
import {
  approveAdmission,
  clearCurrentAdmission,
  deleteAdmission,
  selectCurrentAddmission,
} from "src/reducers/Admissions";
import { ImageManipulator } from "../ImagePlayground";
import { AdmissionService } from "src/services/AdmissionService";

import Complete from "../../assets/translation_icon_complete.svg";
import Pending from "../../assets/translation_icon.svg";
import { Admission } from "src/types";

export const AdmissionPage = () => {
  const currentAdmission = useSelector(selectCurrentAddmission);
  const [duplicates, setDuplicates] = React.useState<Admission[]>([]);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  React.useEffect(() => {
    return () => {
      handleClearCurrentAdmisson();
    };
  }, []);

  React.useEffect(() => {
    if (!currentAdmission) return;

    setDuplicates(() => []);
    if (currentAdmission.lastName) {
      handleFetchFilteredAdmissions({
        last_name: currentAdmission.lastName,
        first_name: "",
        patronymic: "",
      });
    } else {
      toast(`${currentAdmission.firstName} призвіще неомжливо розпізнати`);
    }
  }, [currentAdmission]);

  const handleFetchFilteredAdmissions = React.useCallback(
    ({
      first_name,
      last_name,
      patronymic,
    }: {
      first_name: string;
      last_name: string;
      patronymic: string;
    }) => {
      AdmissionService.getFilteredAdmissions({
        first_name,
        last_name,
        patronymic,
      })
        .then((res: any) => {
          if (!res.success) {
            toast.error(res.message);
            return;
          } else {
            const duplicates = res.deaf.filter((el: Admission) => el.id !== currentAdmission.id);
            setDuplicates(duplicates);
          }
        })
        .catch()
        .finally(() => {});
    },
    [currentAdmission]
  );

  const handleClearCurrentAdmisson = React.useCallback(() => {
    dispatch(clearCurrentAdmission());
    setDuplicates(() => []);
  }, [dispatch]);

  const handleApprove = React.useCallback(
    async (id: number) => {
      try {
        const res: any = await AdmissionService.patchAdmissionById(id, { validated: true });

        if (!res.success) {
          toast.error(res.message);
        } else {
          dispatch(approveAdmission(id));
          setDuplicates((prev) =>
            prev.map((el) => (el.id === id ? { ...el, validate: true } : el))
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    [dispatch]
  );

  const handleReject = React.useCallback(
    async (id: number) => {
      try {
        const res: any = await AdmissionService.deleteAdmissionById(id);

        if (!res.success) {
          toast(res.message);
          return;
        }

        dispatch(deleteAdmission(id));
        setDuplicates((prev) => prev.filter((el) => el.id !== id));

        if (currentAdmission && currentAdmission.id === id && !Boolean(duplicates.length)) {
          handleClearCurrentAdmisson();
        }
      } catch (error) {
        console.log(error);
      }
    },
    [currentAdmission, dispatch, duplicates, handleClearCurrentAdmisson]
  );

  return currentAdmission || Boolean(duplicates.length) ? (
    <Wrapper>
      {currentAdmission ? (
        <AdmissionView
          admission={currentAdmission}
          handleApprove={handleApprove}
          handleClearCurrentAdmisson={handleClearCurrentAdmisson}
          handleReject={handleReject}
        />
      ) : null}
      {Boolean(duplicates.length) ? (
        <>
          <Typography style={{ textAlign: "center" }} variant="h2">
            {t("duplicates")}
          </Typography>
          {duplicates.map((el) => (
            <AdmissionView
              key={el.id}
              admission={el}
              handleApprove={handleApprove}
              handleReject={handleReject}
            />
          ))}
        </>
      ) : null}
    </Wrapper>
  ) : (
    <div
      style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}
    >
      <Logo />
    </div>
  );
};

function AdmissionView({
  admission,
  handleClearCurrentAdmisson,
  handleApprove,
  handleReject,
}: {
  admission: Admission;
  handleClearCurrentAdmisson?: () => void;
  handleApprove: (id: number) => void;
  handleReject: (id: number) => void;
}) {
  const [isLoadingApprove, setIsLoadingApprove] = React.useState(false);
  const [isLoadingReject, setIsLoadingReject] = React.useState(false);

  const { t } = useTranslation();

  const processedRender = React.useCallback((data: any, children: React.ReactNode) => {
    return Boolean(data) ? children : null;
  }, []);

  return (
    <>
      <Typography>
        Заявку створено: {moment(admission.created_at).format("DD.MM.YYYY HH:mm")}
      </Typography>
      <Header>
        <InfoContainer>
          <Typography variant="h3">
            {admission.firstName || admission.lastName || admission.patronymic
              ? `${admission.lastName || ""} ${admission.firstName || ""} ${
                  admission.patronymic || ""
                }`.trim()
              : t("anonymous")}
          </Typography>
          <InfoImage src={admission.validated ? Complete : Pending} />
        </InfoContainer>
        {/* <Typography variant="h3">{admission.serial_number}</Typography> */}
        {handleClearCurrentAdmisson ? (
          <IconButton onClick={handleClearCurrentAdmisson}>
            <Close />
          </IconButton>
        ) : null}
      </Header>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(200px, 250px))",
          gap: 12,
          marginBottom: 24,
        }}
      >
        <FlexGroup>
          {processedRender(
            admission.client.profile?.gender,
            <div style={{ display: "flex", alignItems: "center" }}>
              <WcOutlined />
              <Typography>{t(admission.client.profile?.gender as string)}</Typography>
            </div>
          )}
          {processedRender(
            admission.client.profile?.email,
            <div style={{ display: "flex", alignItems: "center" }}>
              <EmailOutlined />
              <Typography>{admission.client.profile?.email}</Typography>
            </div>
          )}
          {processedRender(
            admission.phone,
            <div style={{ display: "flex", alignItems: "center" }}>
              <PhoneAndroidOutlined />
              <Typography>{admission.phone}</Typography>
            </div>
          )}
          {processedRender(
            admission.client.profile?.birthday2,
            <div style={{ display: "flex", alignItems: "center" }}>
              <CakeOutlined />
              <Typography>
                {moment(admission.client.profile?.birthday2).format("DD.MM.YYYY")}
              </Typography>
            </div>
          )}
          {processedRender(
            admission.client.profile?.deaf_status,
            <div style={{ display: "flex", alignItems: "center" }}>
              <SignLanguageOutlined />
              <Typography>{t(admission.client.profile?.deaf_status as string)}</Typography>
            </div>
          )}
          {processedRender(
            admission.place_of_registration,
            <div style={{ display: "flex", alignItems: "center" }}>
              <PlaceOutlined />
              <Typography>{admission.place_of_registration}</Typography>
            </div>
          )}
        </FlexGroup>
        <FlexGroup>
          {processedRender(
            admission.client.profile?.country,
            <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
              <Typography>{`${t("country")}: `}</Typography>
              <Typography>{admission.client.profile?.country}</Typography>
            </div>
          )}
          {processedRender(
            admission.client.profile?.region,
            <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
              <Typography>{`${t("region")}: `}</Typography>
              <Typography>{admission.client.profile?.region}</Typography>
            </div>
          )}
          {processedRender(
            admission.client.profile?.district,
            <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
              <Typography>{`${t("district")}: `}</Typography>
              <Typography>{admission.client.profile?.district}</Typography>
            </div>
          )}
          {processedRender(
            admission.client.profile?.city,
            <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
              <Typography>{`${t("city")}: `}</Typography>
              <Typography>{admission.client.profile?.city}</Typography>
            </div>
          )}
          {processedRender(
            admission.client.profile?.street && admission.client.profile?.house_number,
            <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
              <Typography>{`${t("house_number")}: `}</Typography>
              <Typography>
                {admission.client.profile?.street + " " + admission.client.profile?.house_number}
              </Typography>
            </div>
          )}
        </FlexGroup>
        <FlexGroup>
          {processedRender(
            admission.msec_number,
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography>МСЕК номер: {admission.msec_number}</Typography>
            </div>
          )}
          {processedRender(
            admission.msec_serial,
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography>МСЕК серія: {admission.msec_serial}</Typography>
            </div>
          )}
        </FlexGroup>
        {/* {Boolean(admission.msec_number) ? (
          <div style={{ display: "flex", alignItems: "center", marginBottom: 16 }}>
            <MedicalInformation />
            <Typography>МСЕК номер: {admission.msec_number}</Typography>
          </div>
        ) : null} */}
        {/* {Boolean(admission.msec_serial) ? (
          <div style={{ display: "flex", alignItems: "center", marginBottom: 16 }}>
            <MedicalInformation />
            <Typography>МСЕК серія: {admission.msec_serial}</Typography>
          </div>
        ) : null} */}
      </div>
      <GridContainer>
        {[admission.identification, admission.msec_front, admission.msec_back].map((url) => {
          return url ? (
            <ImageManipulator
              shouldOpenInNewTab
              key={url}
              imageContainerStyle={{ height: "85%" }}
              imageUrl={url}
            />
          ) : null;
        })}
      </GridContainer>
      <ButtonGroup>
        <Button
          disabled={admission.validated || isLoadingApprove}
          onClick={async () => {
            await setIsLoadingApprove(true);
            await handleApprove(admission.id);
            await setIsLoadingApprove(false);
          }}
          style={{ width: "auto" }}
          variant="contained"
        >
          {isLoadingApprove ? t("loading") : t("approve")}
        </Button>
        <Button
          disabled={isLoadingReject}
          onClick={async () => {
            setIsLoadingReject(true);
            await handleReject(admission.id);
            setIsLoadingReject(false);
          }}
          style={{ width: "auto" }}
          variant="outlined"
        >
          {isLoadingReject ? t("loading") : admission.validated ? t("delete") : t("reject")}
        </Button>
      </ButtonGroup>
    </>
  );
}

const Wrapper = styled.div({
  padding: 8,
  height: "calc(100vh - 40px - 16px)",
  overflowY: "auto",
});

const Header = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: 16,
});

const InfoContainer = styled.div({
  display: "flex",
  gap: 12,
  alignItems: "center",
});

const InfoImage = styled.img({
  width: 32,
  height: 32,
});

const GridContainer = styled.div({
  display: "grid",
  gap: 16,
  // gridTemplateColumns: "repeat(3, 1fr)",
  gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr));",
  gridAutoRows: 500,
});

const ButtonGroup = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: 32,
});

const FlexGroup = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: 4,
});
