import { URI } from "../constants/defaultConstants";
import { AuthService } from "./AuthService";
import { RequestService } from "./RequestService";

export const FoxtrotService = {
    async getFoxtrotDeafId(id:string|number){
    const token = AuthService.getUser().token;
    if (!token) return null;

    try {
      const response = await RequestService({
        data: {},
        headers: {
          "X-Interpreter-Token": token,
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}` as string,
        },
      }).getRequest(URI.foxtrotDeafId + id);
      
      return response;
    } catch (error) {
      console.error("getAdmissions: ", error);
      return null;
    }
  },
}