import { makeStyles, Box, Button, TextareaAutosize } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useActions } from "../../hooks/useActions";
import { usePatchTranslationStatus } from "../../hooks/usePatchTranslationStatus";
import { TranslationsService } from "../../services/TranslationsService";
import { TranslationStatus } from "../../types";
import { AuthService } from "../../services/AuthService";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  button: {
    width: "max-content",
    height: "36px",
    fontSize: "14px",
    textTransform: "uppercase",
  },
  placeholder: {
    width: "98%",
    height: "150px",
    borderRadius: 8,
    border: "1px solid",
    borderColor: "black",
    backgroundColor: "#fafafa",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "12px",
  },
  buttonBox: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    alignSelf: "end",
    padding: "0 8px",
    // width: "100%",
  },
  textAreaWrapper: {
    display: "flex",
    width: "100%",
    gap: 16,
    padding: "0 8px",
    alignItems: "center",
  },
});

export const TranslationButtons = ({ setIsOpen }) => {
  const [value, setValue] = useState("");
  const classes = useStyles();
  const {
    signForTranslation,
    refuseToTranslate,
    setCompleted,
    setCurrentTranslations,
    translationsGetAll,
  } = useActions();
  const { signedForTranslation, selectedId, currentTranslations, completed, all } = useSelector(
    (state) => state.translationsReducer
  );
  const patchStatus = usePatchTranslationStatus();
  const currentUserId = AuthService.getUser().id;
  const { t } = useTranslation();

  const handleOnChange = (e) => {
    setValue(e.target.value);
  };

  const accept = async () => {
    signForTranslation(true);
    await TranslationsService.assignRequest(selectedId);
    patchStatus(TranslationStatus.ASSIGNED);
  };

  // const sendData = () => {
  //   const data = new FormData();
  //   const names = (size) => {
  //     const arr = [];

  //     for (let i = 0; i < size; i++) {
  //       arr.push(`name ${i}.blob`);
  //     }

  //     return arr;
  //   };
  //   const uniqueNames = names(3);

  //   data.append("translationRequestId", selectedId);
  //   currentTranslations.records?.forEach((video, i) => {
  //     if (video === null || typeof video === "string") {
  //       data.append("video", new File([], "empty"));
  //     } else {
  //       data.append("video", video, uniqueNames[i]);
  //     }
  //     // console.log('video', video);
  //   });
  //   TranslationsService.patchTranslation(data);
  //   patchStatus(TranslationStatus.CLOSED);
  //   setCompleted(true);
  // };

  const closeRequest = async (requestId) => {
    const updatedList = all.filter((item) => item.id !== requestId);

    await TranslationsService.closeRequest(requestId);
    setCurrentTranslations(null);
    translationsGetAll(updatedList);
  };

  const handleSendMessage = async () => {
    await TranslationsService.sendRequestMessage(selectedId, value).then((data) => {
      if (Array.isArray(currentTranslations.messages)) {
        setCurrentTranslations({
          ...currentTranslations,
          messages: [...currentTranslations.messages, data.request_message],
        });
      }
      setValue("");
    });
  };

  if (currentTranslations.interpreter_id !== currentUserId && currentTranslations.interpreter_id) {

    return (
      <Box className={classes.placeholder}>
        {t("assignInterpreter") + " " + currentTranslations.interpreter.name}
      </Box>
    );
  }

  return (
    <>
      {currentTranslations.status === TranslationStatus.NEW ? (
        <Box className={classes.buttonBox}>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={() => refuseToTranslate(true)}
          >
            Відмовити
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={accept}
            disableElevation
          >
            Взяти в переклад
          </Button>
        </Box>
      ) : null}
      {currentTranslations.status === TranslationStatus.ASSIGNED ? (
        <Box className={classes.textAreaWrapper}>
          <TextareaAutosize
            value={value}
            onChange={handleOnChange}
            className={classes.textArea}
            style={{ width: "100%", height: "100%", resize: "none" }}
          />
          <Box className={classes.buttonBox}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              style={{ width: "170px" }}
              onClick={() => closeRequest(selectedId)}
            >
              Закрити заявку
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              style={{ width: "170px" }}
              onClick={setIsOpen}
            >
              Записати відео
            </Button>
            <Button
              variant="outlined"
              color="primary"
              className={classes.button}
              style={{ width: "170px" }}
              disabled={!value.length}
              onClick={handleSendMessage}
            >
              Відправити
            </Button>
          </Box>
        </Box>
      ) : null}
    </>
  );
};

//   {
//     currentTranslations.status === TranslationStatus.NEW ? (
//       <>
//         <Button
//           variant="outlined"
//           color="primary"
//           className={classes.button}
//           onClick={() => refuseToTranslate(true)}
//         >
//           Відмовити
//         </Button>
//         <Button
//           variant="contained"
//           color="primary"
//           className={classes.button}
//           onClick={accept}
//           disableElevation
//         >
//           Взяти в переклад
//         </Button>
//       </>
//     ) : (
//       currentTranslations.status !== TranslationStatus.CLOSED &&
//       !completed && (
//         // ) : (
//         <>
//           <Button
//             variant="contained"
//             color="primary"
//             className={classes.button}
//             style={{ width: "170px" }}
//             onClick={sendData}
//           >
//             Закрити заявку
//           </Button>
//           <Button
//             variant="contained"
//             color="primary"
//             className={classes.button}
//             style={{ width: "170px" }}
//             onClick={setIsOpen}
//           >
//             Записати відео
//           </Button>
//         </>
//       )
//     );
//   }
