import { RequestService } from "./RequestService";
import { URI } from "../constants/defaultConstants";
import { useContext } from "react";
import { AuthContext } from "../reducers/AuthReducer";
import { InterpreterService } from "./InterpreterService";
import { InterpreterStatus } from "src/types";

export const AuthService = {
  async signIn(credentials, cb) {
    try {
      const user = await RequestService({}).instance.post(URI.login, credentials);
      // console.log('user service ==>>>', user);
      if (!user.success) {
        throw new Error(user.message)
      }
      cb(user);
      return user;
    } catch (error) {
      console.error("indent", error);
      throw error;
    }
  },
  async signOut(cb, login) {
    try {
      await InterpreterService.handleActivity(InterpreterStatus.OFFLINE);
      await RequestService({
        headers: {
          "X-Interpreter-Token": AuthService.getUser().auth_token,
          "Content-Type": "application/json",
        },
      }).postRequest(URI.logout);
      cb();
    } catch (error) {
      console.log("ERROR LOGOUT");
      this.setUser(null);
      window.location.reload();
    }
  },

  async blockUser({ session_id }) {
    try {
      const response = await RequestService({
        headers: {
          "X-Interpreter-Token": AuthService.getUser().auth_token,
          "Content-Type": "application/json",
        },
        data: { session_id },
      }).postRequest(URI.blockClient);

      console.log("response block user: ", response);
    } catch {
      console.log("cant block user");
    }
  },
  getUser() {
    return JSON.parse(localStorage.getItem(`${process.env.REACT_ENV_TYPE}_user`));
  },
  setUser(user) {
    localStorage.setItem(`${process.env.REACT_ENV_TYPE}_user`, JSON.stringify(user));
  },
};
// hgajxdwv
