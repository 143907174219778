import { URI } from "../constants/defaultConstants";
import { RequestService } from "./RequestService";
import { AuthService } from "./AuthService";
import { AuthorType } from "../types";

export const TranslationsService = {
  async getRequestMessages(requestId) {
    try {
      const response = await RequestService({}).getRequest(
        `/api/interpreter/requests/${requestId}?lang=ru`
      );
      return response;
    } catch (error) {
      console.log("getRequestMessages error:", error);
    }
  },
  async getAllRequests(page = 1) {
    try {
      const response = await RequestService({}).getRequest(
        // `${URI.translations}?per_page=5&page=${page}`
        // `${URI.translations}?per_page=${5 * page}`
        URI.translations
      );
      return response;
    } catch (error) {
      console.log("getAllRequests error:", error);
    }
  },
  async getAllUsers(page = 1, limit = 5) {
    try {
      const response = await RequestService({}).getRequest(
        `${URI.getContacts}?page=${page}&limit=${limit}`
      );
      return response;
    } catch (error) {
      console.log("getAllUsers error:", error);
    }
  },
  async getUser(id) {
    try {
      const response = await RequestService({}).getRequest(`${URI.getContactData}/${id}`);
      return response;
    } catch (error) {
      console.log("getUser error:", error);
    }
  },
  async sendRequestVideo(requestId, data, setProgress) {
    const response = await RequestService({
      data,
      headers: {
        "X-Interpreter-Token": AuthService?.getUser()?.token,
        "Content-type": "multipart/form-data",
      },
    })
      .postRequest(`/api/interpreter/requests/${requestId}?lang=ru`, setProgress)
      .then((data) => {
        data.request_message.author_type = AuthorType.INTERPRETER;

        return data;
      })
      .catch((err) => {
        console.log(err);
      });

    return response;
  },
  async sendRequestMessage(requestId, text) {
    try {
      const response = await RequestService({
        data: { type: "text", text },
        headers: {
          "X-Interpreter-Token": AuthService?.getUser()?.token,
          "Content-Type": "application/json",
          // "Content-Length": Buffer.byteLength(data),
        },
      })
        .postRequest(`/api/interpreter/requests/${requestId}?lang=ru`)
        .then((data) => {
          if (!data.success) {
            console.log("sendRequestMessage", data.message);
            return;
          }

          data.request_message.author_type = AuthorType.INTERPRETER;

          return data;
        })
        .catch((err) => {
          console.log(err);
        });

      return response;
    } catch (error) {
      console.log("sendRequestMessage error:", error);
    }
  },
  async rejectTranslation(requestId) {
    try {
      const response = await RequestService({
        data: {},
        headers: {
          "X-Interpreter-Token": AuthService?.getUser()?.token,
          "Content-Type": "application/json",
        },
      })
        .postRequest(`/api/interpreter/requests/${requestId}/reject`)
        .then((d) => d);

      return response;
    } catch (error) {
      console.log("rejectTranslation", error);
    }
  },

  async patchTranslation(data) {
    // for (var pair of data.entries()) {
    // 	// console.log(pair[0]+ ', ' + pair[1], 'formData log');
    // 	// console.log('element type', typeof pair[1]);
    // }

    for (var value of data.values()) {
      console.log(value, typeof value);
    }

    try {
      const response = await RequestService({
        data,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${AuthService?.getUser()?.token}`,
          "X-Interpreter-Token": AuthService?.getUser()?.token,

          //"x-interpreter-app-type": "connectoperator",
          //"x-interpreter-locale": "uk",
        },
      }).postRequest(
        // `${URI.translations}`,
        `${URI.translations}/response/upload`
      );
      return response;
    } catch (error) {
      console.log("patchTranslation error:", error);
    }
  },
  async assignRequest(requestId) {
    const response = await RequestService({}).postRequest(
      `/api/interpreter/requests/${requestId}/assign?lang=ru`
    );

    return response;
  },
  async closeRequest(requestId) {
    try {
      const response = await RequestService({
        headers: {
          "X-Interpreter-Token": AuthService?.getUser()?.token,
        },
      }).postRequest(`/api/interpreter/requests/${requestId}/close?lang=ru`);

      return response;
    } catch (error) {}
  },
  async patchStatus(data) {
    try {
      const response = await RequestService({
        data,
      }).patchRequest(`${URI.translations}/status`);
      // console.log('success', response);

      return response;
    } catch (error) {
      console.log("patchStatus error:", error);
    }
  },

  async postMessage(data) {
    try {
      const response = await RequestService({
        data,
      }).postRequest(`${URI.translations}/refuse`);
      // console.log('success', response);

      return response;
    } catch (error) {
      console.log("postMessage error:", error);
    }
  },
};
