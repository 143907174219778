import React, { useEffect, useRef, useState } from "react";
import { formatTimeFromSeconds, concatString } from "./CustomDate";

const CountdownTimer = ({
  countdownTime,
  options = { minutes: false, days: false, hours: false, seconds: false },
}: {
  countdownTime: number;
  options: { minutes?: boolean; hours?: boolean; days?: boolean; seconds?: boolean };
}) => {
  const ref = useRef<NodeJS.Timeout | null>(null);
  const [time, setTime] = useState(countdownTime);
  const { minutes, hours, days, seconds } = formatTimeFromSeconds(time);

  const handleStartTimer = () => {
    if (!ref.current) {
      ref.current = setInterval(() => {
        setTime((prev) => (prev > 0 ? prev - 1 : 0));
      }, 1000);
    }
  };

  const handleStopTimer = () => {
    if (ref.current) {
      clearInterval(ref.current);
      ref.current = null;
    }
  };

  useEffect(() => {
    handleStartTimer();

    return () => {
      handleStopTimer();
    };
  }, []);

  const parseOptions = {
    days: options.days ? days : false,
    hours: options.hours ? hours : false,
    minutes: options.minutes ? minutes : false,
    seconds: options.seconds ? seconds : false,
  };

  const optionsArray = Object.values(parseOptions).filter(Boolean);

  return <>{concatString(...optionsArray)}</>;
};

export default CountdownTimer;
