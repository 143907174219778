import { IconButton } from "@material-ui/core";
import React from "react";
import { Icon, IconName } from "src/components/Icon";
import { useCallContext } from "src/reducers/Call/CallProvider";
import styled from "styled-components";

import PhoneForwardedOutlined from "../../assets/callback-icon.svg";

interface Props {
  setChatVisible: () => void;
  setSwitchOperatorVisible: () => void;
  setDictionaryVisible: () => void;
  setCallbackModalVisible: () => void;
  newMessagesCount: number;
  wrapperStyle?: React.CSSProperties;
}

export default function AsideTools({
  setChatVisible,
  newMessagesCount,
  setSwitchOperatorVisible,
  setDictionaryVisible,
  setCallbackModalVisible,
  wrapperStyle,
}: Props) {
  const { callState } = useCallContext() as any;

  const isEmergency =
    callState?.selectedCall?.reason?.toLowerCase().trim() === "emergency".toLowerCase().trim();

  return (
    <Wrapper style={wrapperStyle}>
      <IconButton onClick={setChatVisible}>
        <Icon name={IconName.CHAT} color="#008DFF" size="1.75rem" />
        {newMessagesCount > 0 && <Badge>{newMessagesCount}</Badge>}
      </IconButton>
      <IconButton onClick={setSwitchOperatorVisible}>
        <Icon name={IconName.REDIRECT} color="#008DFF" size="1.75rem" />
      </IconButton>
      {isEmergency ? (
        <IconButton onClick={setDictionaryVisible}>
          <Icon name={IconName.DICTIONARY} color="#008DFF" size="1.75rem" />
        </IconButton>
      ) : null}
      <IconButton onClick={setCallbackModalVisible}>
        <img src={PhoneForwardedOutlined} style={{ width: "1.75rem", height: "1.75rem" }} alt=""/>
      </IconButton>
    </Wrapper>
  );
}

const Wrapper = styled.div({
  width: "3rem",
  display: "flex",
  marginLeft: "auto",
  flexDirection: "column",
  alignItems: "center",
  gap: "10px",
});

const Badge = styled.span`
  display: flex;
  color: white;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  background-color: red;
  position: absolute;
  bottom: 8px;
  right: 8px;
  align-items: center;
  justify-content: center;
  font-size: 10px;
`;
