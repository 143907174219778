import { Box, Paper, Tab, Tabs } from "@material-ui/core";
import React from "react";
import RenderGroupSessions from "./RenderGroupSessions";
import { Group, HistoryOutlined } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectCurrentGroupCall } from "src/reducers/GroupCall";
import { GroupTranslation } from "src/types";

export const GroupTab = () => {
  const [currentTab, setCurrentTab] = React.useState(0);
  const selectedGroupCall: GroupTranslation = useSelector(selectCurrentGroupCall);

  return (
    <Box
      style={{
        padding: 0,
        margin: 0,
      }}
    >
      <div style={{ height: selectedGroupCall ? "58.3vh" : "auto", overflow: "auto" }}>
        <TabPanel value={currentTab} index={0}>
          <RenderGroupSessions />
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          <>{selectedGroupCall ? selectedGroupCall.title : "select session"}</>
        </TabPanel>
      </div>
      {selectedGroupCall ? (
        <Box
          style={{
            //   position: "absolute",
            bottom: 0,
            width: 356,
          }}
        >
          <IconLabelTabs value={currentTab} setCurrentTab={setCurrentTab} />
        </Box>
      ) : null}
    </Box>
  );
};

function IconLabelTabs({
  value,
  setCurrentTab,
}: {
  value: number;
  setCurrentTab: React.Dispatch<React.SetStateAction<number>>;
}) {
  const { t } = useTranslation();
  // @ts-ignore-next-line
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <Paper
      square
      style={{
        boxShadow: "0px -4px 5px rgba(0, 0, 0, 0.14)",
      }}
    >
      <Tabs
        style={{
          // height: 80,
          width: "100%",
        }}
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
        aria-label="icon label tabs example"
      >
        <Tab
          icon={<HistoryOutlined />}
          label={t("history")}
          style={{
            minWidth: 0,
            fontSize: 12,
          }}
        />
        <Tab
          icon={<Group />}
          label={t("current")}
          style={{
            minWidth: 0,
            fontSize: 12,
          }}
        />
      </Tabs>
    </Paper>
  );
}

function TabPanel({
  children,
  value,
  index,
  ...other
}: {
  children: React.ReactNode;
  value: number;
  index: number;
}) {
  return (
    <Paper
      square
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={
        {
          // height: `calc(100vh - 120px)`,
          // overflowY: 'auto',
        }
      }
      {...other}
    >
      {value === index && children}
    </Paper>
  );
}
