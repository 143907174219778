export enum CallReason {
  EMERGENCY = "emergency",
  POLICE = "police",
  DOCTOR = "doctor",
  CALL = "call",
  OTHER = "other",
  BPVD = "bpvd",
  ESTORE = "estore",
  BANK = "bank",
  WORK = "work",
  CAR_SERVICE = "car service",
  FIRE_DEPARTMENT = "fire department",
  GAS_SERVICE = "gas service",
  SOCIAL = "social",
  HOTLINE_SE = "hotline_se",
  DISABILITY_RIGHTS = "disability_rights",
  CALLBACK = "callback",
}

export enum ExeptionCallReason {
  EMERGENCY = "emergency",
  POLICE = "police",
  GAS_SERVICE = "gas service",
  FIRE_DEPARTMENT = "fire department",
  TEST = "test", // templ solution
  TALK = "talk", // temp solution
  CALL = "call",
  ESTORE = "estore",
  CALLBACK = "callback",
}
