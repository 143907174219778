import React from 'react'
import { Box, ButtonBase, Divider, Typography } from '@material-ui/core'
import VideoCallIcon from '../../assets/videoCallIcon.svg'
import FireIcon from '../../assets/fireIcon.svg'
import EmergencyIcon from '../../assets/ambulanceIcon.svg'
import GasIcon from '../../assets/gasIcon.svg'
import PoliceIcon from '../../assets/policeIcon.svg'
import CallRequestIcon from '../../assets/callOutIcon.svg'

import { useCallContext } from '../../reducers/Call/CallProvider'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { CallService } from '../../services/CallService'
import { CallActionTypes } from '../../reducers/Call/CallActionsTypes'

export const CallHistoryItem = () => {
    const { callState, dispatchCall } = useCallContext()
    const { historyCalls } = callState
    const { t } = useTranslation()

    const LoadCallCard = async (historyObj) => {
        const caller = historyObj?.listeners?.find((el) => el.type === 'caller') 
        const user = await CallService.getContactData(caller.userId)

        dispatchCall({
            type: CallActionTypes.SET_SELECTED_HISTORY,
            payload: { ...historyObj }
        })

        dispatchCall({
            type: CallActionTypes.SET_SELECTED_CONTACT,
            payload: { user }
        })
    }

    return historyCalls?.map((el) => {
        const callDate = moment(new Date(el.createdAt)).format(
            'DD.MM.YYYY / HH:mm'
        )

        const callLength =
            el.status !== 'missed' &&
            `/ ${moment(new Date(el.endedAt) - new Date(el.initiatedAt))
                .utc()
                .format('HH:mm:ss')}`

        const textColor = el.status === 'missed' ? '#F44336' : '#000'

        const { icon, text } = getMetaData(el.reason)

        return (
            <ButtonBase
                key={el.id}
                style={{
                    width: '100%',
                    paddingTop: 10
                }}
                onClick={() => LoadCallCard(el)}
            >
                <Box style={{ width: 80 }}>
                    <img src={icon} alt={text} />
                </Box>

                <Box
                    style={{
                        padding: 0,
                        width: '100%'
                    }}
                >
                    <Typography variant="body1" style={{ color: textColor }}>
                        {el.caller?.employee?.department?.name}
                    </Typography>
                    <Typography variant="body1" style={{ color: textColor }}>
                        {el.caller?.profile?.last_name}{' '}
                        {el.caller?.profile?.first_name}{' '}
                        {el.caller?.profile?.patronymic}
                    </Typography>
                    <Typography variant="body1" style={{ color: textColor }}>
                        {el?.caller?.login}
                    </Typography>

                    <Typography
                        variant="subtitle1"
                        style={{ color: textColor }}
                    >
                        {t(text)}
                    </Typography>

                    <Typography
                        variant="subtitle1"
                        style={{ color: textColor }}
                    >
                        {callDate} {callLength}
                    </Typography>
                    <Divider
                        style={{
                            width: '100%'
                        }}
                    />
                </Box>
            </ButtonBase>
        )
    })
}

const getMetaData = (reason) => {
    switch (reason) {
        case 'emergency':
            return {
                icon: EmergencyIcon,
                text: 'callTypes.103'
            }
        case 'fire department':
            return {
                icon: FireIcon,
                text: 'callTypes.101'
            }
        case 'police':
            return {
                icon: PoliceIcon,
                text: 'callTypes.102'
            }
        case 'gas service':
            return {
                icon: GasIcon,
                text: 'callTypes.104'
            }
        case 'other':
            return { icon: VideoCallIcon, text: 'callTypes.videoCall' }
        case 'group':
            return { icon: VideoCallIcon, text: 'callTypes.groupCall' }
        case 'request':
            return { icon: CallRequestIcon, text: 'callTypes.callRequest' }
        default:
            return { icon: VideoCallIcon, text: 'callTypes.videoCall' }
    }
}
