import axios from "axios";
// import { useAuth } from "../hooks/useAuth";
import { AuthService } from "./AuthService";
import { InterpreterService } from "./InterpreterService";
import { InterpreterStatus } from "src/types";

export const RequestService = ({
  // data = {},
  data,
  headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${AuthService?.getUser()?.token}`,
    "X-Interpreter-Token": AuthService?.getUser()?.token,
    // "x-interpreter-app-type": "connectoperator",
    // "x-interpreter-locale": "uk",
  },
}) => {
  // console.log('WAS WE WERE HERE???');

  // init request headers
  // console.log("BASE URL: ", process.env.REACT_APP_BASE_URL);
  const requestHeaders = new Headers();
  const abortController = new AbortController();
  const instance = axios.create({
    // baseURL: ('https://dev.api.soc.business'),
    baseURL: process.env.REACT_APP_BASE_URL,
    headers,
    data,
  });

  for (const headerType in headers) {
    if (headers.hasOwnProperty(headerType)) {
      requestHeaders.append(headerType, headers[headerType]);
    }
  }

  // get request
  async function getRequest(url, options = {}) {
    try {
      const response = await instance.get(url, options);
      // console.log("inside get request====>>>>>", response);

      return response;
    } catch (error) {
      console.error("GET REQUEST ERROR: ", error);
      throw error;
    }
  }

  // post request
  async function postRequest(url, setProgress) {
    let config = {
      onUploadProgress: (progressEvent) => {
        // console.log('progressEvent',progressEvent);
        let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
        if (setProgress) {
          setProgress(percentCompleted);
        }
      },
    };
    // console.log("config", config);
    // localStorage.setItem('config',JSON.stringify(config))
    try {
      const response = await instance.post(url, data, config);

      return response;
    } catch (error) {
      console.log("postRequest Error: ", error);
      throw error;
    }
  }

  // patch data
  async function patchRequest(url) {
    try {
      return await instance.patch(url, data);
    } catch (error) {
      console.error("PATCH REQUEST ERROR: ", error);
      throw error;
    }
  }

  async function putRequest(url) {
    try {
      return await instance.put(url, data);
    } catch (error) {
      console.error("PUT REQUEST ERROR: ", error);
      throw error;
    }
  }
  // delete data
  async function deleteRequest(url) {
    try {
      return await instance.delete(url);
    } catch (error) {
      console.error("DELETE REQUEST ERROR: ", error);
      throw error;
    }
  }
  // prepare data
  data = data instanceof FormData ? data : JSON.stringify(data);
  // data.forEach((el) => console.log(el));
  const handleError = (error) => {
    let status = null;
    let code = null;
    let message = null;

    if (error?.status === 200) {
      status = error.status;
      code = error.code;
      message = error.message;
    } else {
      status = error.response?.status;
      code = error.response?.data?.code;
      message = error.response?.message || error.response.data.message || "Щось пiшло не так";
    }

    console.error(`Error in API - status: ${status}, code: ${code}, message ${message}`);
    // if (status === 401 || status === 403) {
    if (status === 401) {
      AuthService.signOut(() => AuthService.setUser(null));
      InterpreterService.handleActivity(InterpreterStatus.OFFLINE);
      return null;
    }
    // if (status === 500) {
    //   AuthService.signOut(() => AuthService.setUser(null));
    //   return null
    // }

    throw new Error(message);
    // return { status, code, message };
  };

  instance.interceptors.response.use(
    (response) => {
      if (Math.round(response.status / 100) !== 2) {
        throw new Error(
          JSON.stringify({
            status: response.status,
            text: response.statusText,
          })
        );
      }

      try {
        // console.log('interceptor response', response);
        response = response?.data;

        return response;
      } catch (err) {
        console.log("interceptor catch", response.status);
        return response;
      }
    },
    (error) => {
      console.log("interceptor error", error);
      return handleError(error);
    }
  );

  return {
    instance,
    abortController,
    getRequest,
    postRequest,
    patchRequest,
    putRequest,
    deleteRequest,
  };
};
