import { createSlice } from "@reduxjs/toolkit";
import { Operator } from "src/types";
import { fetchCurrentInterpreter } from "./operations";

interface InterpreterState {
  currentInterpreter: Operator | null;
}
const initialState: InterpreterState = {
  currentInterpreter: null,
};

const interpreterSlice = createSlice({
  name: "interpreterSlice",
  initialState,
  reducers: {
    setCurrentInterpreter(state, action) {
      state.currentInterpreter = action.payload;
    },
  },
  extraReducers(builder) {
    return builder.addCase(fetchCurrentInterpreter.fulfilled, (state, action) => {
      state.currentInterpreter = action.payload;
    });
  },
});

export const { setCurrentInterpreter } = interpreterSlice.actions;
export default interpreterSlice.reducer;
