export enum RoutesMap {
  MAIN = "/",
  IMAGE_PLAYGROUND = "/image/:imageSrc",
  CALLS = "/calls",
  CONTACTS = "/contacts",
  GROUP_CALLS = "/groupCalls",
  TRANSLATIONS = "/translations",
  SELECTED_CALL = "/call/:callId",
  SIGN_IN = "/signin",
  ADMISSIONS = '/admissions'
}
export enum DynamicParams {
  IMAGE_SRC = "imageSrc",
  CALL_ID = "callId",
}

export interface RouteParams {
  imageSrc: DynamicParams;
}
