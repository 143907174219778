import { CallDataType } from "src/types/CallType";

export const checkPaidMinutes = (sessionData: CallDataType) => {
  const checkGuard =
    !sessionData.minutes || !Array.isArray(sessionData.minutes) || sessionData.minutes.length === 0;
  
  if (checkGuard) {
    return false;
  }

  const lastUpdatedMinute = sessionData.minutes.reduce(
    (max: any, minute: any) => {
      const updatedDate = new Date(minute.updated_at).getTime();
      return updatedDate > max.date ? { date: updatedDate, minute } : max;
    },
    { date: 0, minute: null }
  );

  return lastUpdatedMinute.minute?.order_id !== null;
};
