import React from "react";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

import { Box, Tab, Tabs } from "@material-ui/core";
import { Paper, InputBase, Divider, IconButton } from "@mui/material";

import { AllInboxOutlined, InboxOutlined, SearchOutlined, Clear } from "@mui/icons-material";

import { AdmissionService } from "src/services/AdmissionService";
import { useObserver } from "src/hooks";

import Loader from "src/components/Loader";
import { AdmissionsList } from "./AdmissionsList";
import { useDispatch, useSelector } from "react-redux";
import {
  appendAdmissions,
  appendNewAdmissions,
  clearFilteredAdmissions,
  selectAdmissions,
  selectFilteredAdmissions,
  selectNewAdmissions,
  setAdmissions,
  setCurrentAdmition,
  setFilteredAdmissions,
  setNewAdmissions,
} from "src/reducers/Admissions";

export const AdmissionTab = () => {
  const [tabIndex, setTabIndex] = React.useState(0);
  const [searchFilterValue, setSearchFilterValue] = React.useState("");
  const [isFetchingFilteredAdmissions, setIsFetchingFilteredAdmissions] = React.useState(false);

  const [isInitialNewAdmissionsFetch, setIsInitialNewAdmissionsFetch] = React.useState(true);
  const [isInitialAdmissionsFetch, setIsInitialAdmissionsFetch] = React.useState(false);

  //   const [newAdmissions, setNewAdmissions] = React.useState<Admission[]>([]);
  //   const [admissions, setAdmissions] = React.useState<Admission[]>([]);
  //   const [filteredAdmissions, setFilteredAdmissions] = React.useState<Admission[]>([]);
  const newAdmissions = useSelector(selectNewAdmissions);
  const admissions = useSelector(selectAdmissions);
  const filteredAdmissions = useSelector(selectFilteredAdmissions);

  const [hasMoreNewAdmissions, setHasMoreNewAdmissions] = React.useState(false);
  const [hasMoreAdmissions, setHasMoreAdmissions] = React.useState(false);

  const newAdmissionsPageRef = React.useRef(1);
  const admissionsPageRef = React.useRef(1);

  const newAdmissionsObserverRef = React.useRef(null);
  const admissionsObserverRef = React.useRef(null);

  const observerOptions = {
    root: null,
    rootMargin: "10px",
    threshold: 0.1,
    triggerOnce: false,
  };
  const isVisibleNewAdmissions = useObserver(observerOptions, newAdmissionsObserverRef);
  const isVisibleAdmissions = useObserver(observerOptions, admissionsObserverRef);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const perPage = 20;

  React.useEffect(() => {
    handleFetchNewAdmissions({ page: newAdmissionsPageRef.current, isFetchingFirstTime: true });
  }, []);

  React.useEffect(() => {
    handleFetchAdmissions({ page: admissionsPageRef.current, isFetchingFirstTime: true });
  }, []);

  React.useEffect(() => {
    if (hasMoreNewAdmissions && isVisibleNewAdmissions) {
      newAdmissionsPageRef.current = newAdmissionsPageRef.current + 1;
      handleFetchNewAdmissions({ page: newAdmissionsPageRef.current, isFetchingFirstTime: false });
    }
  }, [isVisibleNewAdmissions, hasMoreNewAdmissions]);

  React.useEffect(() => {
    if (hasMoreAdmissions && isVisibleAdmissions) {
      admissionsPageRef.current = admissionsPageRef.current + 1;
      handleFetchAdmissions({
        page: admissionsPageRef.current,
        isFetchingFirstTime: false,
      });
    }
  }, [isVisibleAdmissions, hasMoreAdmissions]);

  const handleFetchNewAdmissions = React.useCallback(
    ({ page, isFetchingFirstTime }: { page: number; isFetchingFirstTime: boolean }) => {
      isFetchingFirstTime && setIsInitialNewAdmissionsFetch(true);

      AdmissionService.getNewAdmissions({
        page,
        perPage,
      })
        .then((res: any) => {
          if (!res.success) {
            toast.error(res.message);
            return;
          }
          if (isFetchingFirstTime) {
            dispatch(setNewAdmissions(res.deaf.data));
          } else {
            dispatch(appendNewAdmissions(res.deaf.data));
          }
          if (newAdmissionsPageRef.current < res.deaf.last_page) {
            setHasMoreNewAdmissions(true);
          } else {
            setHasMoreNewAdmissions(false);
          }

        })
        .catch(() => {
          setHasMoreNewAdmissions(false);
        })
        .finally(() => {
          setIsInitialNewAdmissionsFetch(false);
        });
    },
    []
  );

  const handleFetchAdmissions = React.useCallback(
    ({ page, isFetchingFirstTime }: { page: number; isFetchingFirstTime: boolean }) => {
      isFetchingFirstTime && setIsInitialAdmissionsFetch(true);

      AdmissionService.getAdmissions({ page, perPage })
        .then((res: any) => {
          if (!res.success) {
            toast.error(res.message);
            return;
          }

          if (isFetchingFirstTime) {
            dispatch(setAdmissions(res.deaf.data));
          } else {
          dispatch(appendAdmissions(res.deaf.data));

          }

          if (admissionsPageRef.current < res.deaf.last_page) {
            setHasMoreAdmissions(true);
          } else {
            setHasMoreAdmissions(false);
          }
          // dispatch(appendAdmissions(res.deaf.data));
          //   setAdmissions((prev) => {
          //     return [...prev, ...res.deaf.data];
          //   });
        })
        .catch(() => {
          setHasMoreAdmissions(false);
        })
        .finally(() => {
          setIsInitialAdmissionsFetch(false);
        });
    },
    []
  );

  const handleFetchFilteredAdmissions = React.useCallback(
    ({
      first_name,
      last_name,
      patronymic,
    }: {
      first_name: string;
      last_name: string;
      patronymic: string;
    }) => {
      setIsFetchingFilteredAdmissions(true);
      AdmissionService.getFilteredAdmissions({
        first_name,
        last_name,
        patronymic,
      })
        .then((res: any) => {
          if (!res.success) {
            toast.error(res.message);
            return;
          }
          dispatch(setFilteredAdmissions(res.deaf));
        })
        .catch()
        .finally(() => {
          setIsFetchingFilteredAdmissions(false);
        });
    },
    []
  );

  const handleFetchAdmissionById = React.useCallback(
    (id: number) => {
      AdmissionService.getAdmissionById(id).then((res: any) => {
        if (!res.success) {
          toast.error(res.message);
          return;
        }
        dispatch(setCurrentAdmition(res.deaf));
      });
    },
    [dispatch]
  );

  const handleOnChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchFilterValue(e.target.value);
  }, []);

  const handleClearFilteredAdmissions = React.useCallback(() => {
    setSearchFilterValue("");
    dispatch(clearFilteredAdmissions());
  }, []);

  const handleSubmit = React.useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();

      const [last_name = "", first_name = "", patronymic = ""] = searchFilterValue
        .toLowerCase()
        .replace(/\s+/g, " ")
        .trim()
        .split(" ")
        .map((el) => el.trim());
      if (!Boolean(last_name.length)) {
        toast("Призвіще обовʼязкове для пошуку");
        return;
      }
      handleFetchFilteredAdmissions({ first_name, last_name, patronymic });
    },
    [searchFilterValue]
  );

  return (
    <Box
      style={{
        padding: 0,
        margin: 0,
      }}
    >
      <TabPanel value={tabIndex} index={0}>
        {isInitialNewAdmissionsFetch ? (
          <Loader />
        ) : (
          <AdmissionsList onAdmissionClick={handleFetchAdmissionById} items={newAdmissions} />
        )}
        {isInitialNewAdmissionsFetch || !hasMoreNewAdmissions ? null : (
          <div ref={newAdmissionsObserverRef}>
            <Loader />
          </div>
        )}
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        {isInitialAdmissionsFetch ? (
          <Loader />
        ) : (
          <AdmissionsList onAdmissionClick={handleFetchAdmissionById} items={admissions} />
        )}
        {isInitialAdmissionsFetch || !hasMoreAdmissions ? null : (
          <div ref={admissionsObserverRef}>
            <Loader />
          </div>
        )}
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <Paper
          onSubmit={handleSubmit}
          component="form"
          style={{
            padding: "2px 4px",
            display: "flex",
            alignItems: "center",
            // width: 400,
            justifyContent: "center",
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder={t("searchAdmissionsPlaceholder")}
            onChange={handleOnChange}
            value={searchFilterValue}
          />
          <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
            <SearchOutlined />
          </IconButton>
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <IconButton
            onClick={handleClearFilteredAdmissions}
            type="button"
            color="primary"
            sx={{ p: "10px" }}
            aria-label="directions"
          >
            <Clear />
          </IconButton>
        </Paper>
        {isFetchingFilteredAdmissions ? (
          <Loader />
        ) : (
          <AdmissionsList onAdmissionClick={handleFetchAdmissionById} items={filteredAdmissions} />
        )}
      </TabPanel>
      <Box style={{ position: "absolute", bottom: 0, width: 356 }}>
        <IconLabelTabs value={tabIndex} setValue={setTabIndex} />
      </Box>
    </Box>
  );
};

function IconLabelTabs({
  value,
  setValue,
}: {
  value: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
}) {
  const { t } = useTranslation();
  const handleChange = (_event: React.ChangeEvent<{}>, newValue: any) => {
    setValue(newValue);
  };

  return (
    <Paper
      square
      style={{
        boxShadow: "0px -4px 5px rgba(0, 0, 0, 0.14)",
      }}
    >
      <Tabs
        style={{
          height: 80,
          width: "100%",
        }}
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
        aria-label="icon label tabs example"
      >
        <Tab
          icon={<InboxOutlined />}
          label={t("newAdmissions")}
          style={{
            minWidth: 0,
            fontSize: 12,
          }}
        />
        <Tab
          icon={<AllInboxOutlined />}
          label={t("admissionHistory")}
          style={{
            position: "relative",
            minWidth: 0,
            fontSize: 12,
          }}
        />
        <Tab
          icon={<SearchOutlined />}
          label={t("searchPlaceholder")}
          style={{
            position: "relative",
            minWidth: 0,
            fontSize: 12,
          }}
        />
      </Tabs>
    </Paper>
  );
}

function TabPanel({
  index,
  value,
  children,
  ...other
}: {
  children?: React.ReactNode;
  value: number;
  index: number;
}) {
  return (
    <Paper
      square
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={
        {
          //   height: `calc(100vh - 120px)`,
          // overflowY: 'auto',
        }
      }
      {...other}
    >
      {value === index && children}
    </Paper>
  );
}
