import React from "react";
import { Icon, IconName } from "./components/Icon";

export default function Logo() {
  return <Icon name={IconName.LOGO} color="#00AAF6" size="107" />;
  // return (
  //   <svg width="104" height="107" viewBox="0 0 104 107" fill="none" xmlns="http://www.w3.org/2000/svg">
  //     <path d="M67.306 72.6221C52.4632 72.6221 39.7185 64.3824 34.0408 51.1159C33.5721 50.0171 34.0827 48.7458 35.1847 48.2785C36.2868 47.8083 37.5618 48.3202 38.0305 49.419C43.0135 61.0637 54.232 68.2992 67.306 68.2992C85.0671 68.2992 99.5165 53.9951 99.5165 36.414C99.5165 18.7189 85.1257 4.32295 67.4371 4.32295C55.3257 4.32295 44.4336 10.9798 39.007 21.6954C38.4685 22.7608 37.1656 23.192 36.0943 22.6496C35.0257 22.1127 34.596 20.8108 35.1373 19.7453C41.3032 7.56655 53.6796 0 67.4371 0C87.5168 0 103.852 16.3348 103.852 36.414C103.852 56.3791 87.4582 72.6221 67.306 72.6221Z" fill="#00AAF6"/>
  //     <path d="M36.549 106.388C29.5266 106.388 22.7469 104.432 16.8795 100.719L5.03037 103.884C4.28544 104.085 3.48471 103.87 2.93508 103.325C2.38545 102.78 2.17341 101.984 2.37429 101.236L5.5521 89.4076C1.91673 83.6604 0 77.0313 0 70.1797C0 50.1006 16.3354 33.7657 36.4151 33.7657C50.1726 33.7657 62.549 41.3323 68.7149 53.5138C69.2561 54.5793 68.8265 55.8812 67.7579 56.4181C66.6866 56.9577 65.3836 56.5266 64.8452 55.4639C59.4186 44.7483 48.5265 38.0914 36.4151 38.0914C18.7265 38.0914 4.33566 52.4874 4.33566 70.1825C4.33566 76.4277 6.14079 82.4587 9.56412 87.6384C10.0077 88.1697 10.1835 88.8874 9.99936 89.5746L7.53579 98.7434L16.7316 96.2871C17.4682 96.0896 18.2382 96.2926 18.7795 96.8017C24.0498 100.248 30.185 102.068 36.549 102.068C49.6229 102.068 60.8415 94.8322 65.8244 83.1875C66.2932 82.0887 67.5654 81.5768 68.6702 82.047C69.7723 82.5143 70.2829 83.7856 69.8141 84.8844C64.1393 98.1453 51.3918 106.388 36.549 106.388Z" fill="#00AAF6"/>
  //   </svg>
  // );
}
