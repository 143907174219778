const pick = require('lodash/pick');
const assign = require('lodash/assign');
const find = require('lodash/find');
const getStatsHelpers = require('./get_stats_helpers.js');

const watchFrameRate = (getStats, delay = 5000) => {
  let timeoutId;
  let destroyed = false;
  const lastStat = {};

  const getVideoTrackStat = (stats = []) => (
    find(stats, stat => getStatsHelpers.isVideoTrackStat(stat))
  );

  const calculateFrameRate = ({ timestamp, framesDecoded } = {}) => {
    if (framesDecoded >= lastStat.framesDecoded && timestamp > lastStat.timestamp) {
      return (framesDecoded - lastStat.framesDecoded) /
        ((timestamp - lastStat.timestamp) / 1000);
    }
    return 0;
  };

  (function query() {
    if (destroyed) {
      return;
    }
    getStats((error, stats) => {
      if (destroyed) {
        return;
      }

      if (!error) {
        const stat = getVideoTrackStat(stats);
        if (stat) {
          assign(lastStat, pick(stat, ['timestamp', 'framesDecoded']));
        } else if (!lastStat.timestamp) {
          assign(lastStat, {
            timestamp: Date.now(),
            framesDecoded: 0,
          });
        }
      }

      timeoutId = setTimeout(query, delay);
    });
  }());

  return {
    getFrameRateFromStats(stats) {
      const stat = getVideoTrackStat(stats);
      return calculateFrameRate(stat);
    },
    destroy() {
      destroyed = true;
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }
    },
  };
};

module.exports = watchFrameRate;
