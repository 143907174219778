import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import { AuthService } from "src/services/AuthService";
import { RoutesMap } from "src/types";

export default function PublicRoute() {
  const user = AuthService.getUser();
  const authToken = user ? user.token : null;

  return authToken ? <Navigate to={RoutesMap.MAIN} replace /> : <Outlet />;
}
