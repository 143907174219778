import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from '../en.json';
import uk from '../uk.json';
import ru from '../ru.json';

const resources = {
	en: { translation: en },
	uk: { translation: uk },
	ru: { translation: ru },
};

i18n.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		resources,
		fallbackLng: 'en',
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	});

export default i18n;
