import { makeStyles, AppBar, IconButton, Toolbar, MenuItem, Box } from "@material-ui/core"
import React, { useState } from "react"
import zoomOutIcon from "../../assets/toolBar/zoom-out.svg"
import zoomInIcon from "../../assets/toolBar/zoom-in.svg"
import fullSizeIcon from "../../assets/toolBar/full-size.svg"
import fullWidthIcon from "../../assets/toolBar/full-width.svg"
import realSizeIcon from "../../assets/toolBar/real-size.svg"
import rotateLeftIcon from "../../assets/toolBar/rotate-left.svg"
import rotateRightIcon from "../../assets/toolBar/rotate-right.svg"
import cross from "../../assets/toolBar/cross.svg"
import dropDownArrow from "../../assets/toolBar/dropdown_arrow.svg"
import { useActions } from "../../hooks/useActions"
import { useSelector } from "react-redux"
import OutsideClickHandler from "react-outside-click-handler"

const useStyles = makeStyles({
	toolBar: {
		minHeight: "40px"
	},
	selectBox: {
		width: "80px",
		height: "28px",
		display: "inline-block",
		background: "#fff",
		borderRadius: "2px",
		color: "#000"
	},
	dropDownMenu: {
		padding: "21px 49px 0 42px",
		"& > div": {
			cursor: "pointer",
			"&:hover": {
				background: "#f1f1f1"
			}
		}
	}
})

export const TranslationToolBar = () => {
	const classes = useStyles()
	const { setZoom, setRotation, setIsPressed, toggleCamera } = useActions()
	const { zoom, rotation } = useSelector((state) => state.fileReducer)
	const [isMenuCalled, setIsMenuCalled] = useState(false)

	const handleMenuZoom = (value) => {
		setZoom(value)
		setIsMenuCalled(false)
	}

	const closeFile = () => {
		setIsPressed(false)
		toggleCamera(false)
		setZoom(1)
		setRotation(0)
	}

	const handleAction = (mode) => {
		const rotatingFactor = 0.25
		const scalingFactor = 0.2
		const rsFactor = 0.2

		if (rotation % 0.5 !== 0) {
			setZoom(zoom - rsFactor)
		} else {
			setZoom(zoom + rsFactor)
		}

		switch (mode) {
			case "right":
				setRotation(rotation + rotatingFactor)
				break
			case "left":
				setRotation(rotation - rotatingFactor)
				break
			case "zoomIn":
				setZoom(zoom + scalingFactor)
				break
			case "zoomOut":
				setZoom(zoom - scalingFactor)
				break
			default:
				return
		}
	}

	return (
		<AppBar position="static" elevation={0}>
			<Toolbar className={classes.toolBar}>
				<Box
					style={{
						flexGrow: 1,
						display: "flex",
						alignItems: "center"
					}}
				>
					<IconButton onClick={() => handleAction("zoomOut")}>
						<img src={zoomOutIcon} alt="zoomOutIcon" />
					</IconButton>
					<IconButton onClick={() => handleAction("zoomIn")}>
						<img src={zoomInIcon} alt="zoomInIcon" />
					</IconButton>
					<Box className={classes.selectBox}>
						<Box
							style={{
								display: "flex",
								justifyContent: "space-around",
								paddingTop: "0.25em"
							}}
							onClick={() => setIsMenuCalled(true)}
						>
							{`${Math.round(zoom * 100)}%`}
							<img src={dropDownArrow} style={{ display: "inline-block" }} />
						</Box>
						{isMenuCalled && (
							<OutsideClickHandler onOutsideClick={() => setIsMenuCalled(false)}>
								<Box
									onOutsideClick={() => setIsMenuCalled(false)}
									style={{
										position: "absolute",
										width: "260px",
										height: "370px",
										background: "#fff",
										marginTop: "5px",
										zIndex: "2",
										display: "block"
									}}
								>
									<Box className={classes.dropDownMenu}>
										<div onClick={() => handleMenuZoom(0.1)}>10%</div>
										<div onClick={() => handleMenuZoom(0.25)}>25%</div>
										<div onClick={() => handleMenuZoom(0.5)}>50%</div>
										<div onClick={() => handleMenuZoom(0.75)}>75%</div>
										<div onClick={() => handleMenuZoom(1)}>100%</div>
										<div onClick={() => handleMenuZoom(1.25)}>125%</div>
										<div onClick={() => handleMenuZoom(1.5)}>150%</div>
										<div onClick={() => handleMenuZoom(2)}>200%</div>
										<div onClick={() => handleMenuZoom(4)}>400%</div>
										<div onClick={() => handleMenuZoom(8)}>800%</div>
										<div onClick={() => handleMenuZoom(16)}>1600%</div>
										<div onClick={() => handleMenuZoom(24)}>2400%</div>
										<div onClick={() => handleMenuZoom(32)}>3200%</div>
										<div onClick={() => handleMenuZoom(64)}>6400%</div>
									</Box>
								</Box>
							</OutsideClickHandler>
						)}
					</Box>
					<IconButton onClick={() => handleAction("left")}>
						<img src={rotateLeftIcon} alt="rotateLeftIcon" />
					</IconButton>
					<IconButton onClick={() => handleAction("right")}>
						<img src={rotateRightIcon} alt="rotateRightIcon" />
					</IconButton>
				</Box>
				<IconButton onClick={closeFile}>
					<img src={cross} alt="cross" />
				</IconButton>
			</Toolbar>
		</AppBar>
	)
}
