import { URI } from "../constants/defaultConstants";
import { AuthService } from "./AuthService";
import { RequestService } from "./RequestService";

export const ContactsService = {
  async getContactByPhoneNumber(phoneNumber) {
    try {
      const response = await RequestService({
        headers: {
          "X-Interpreter-Token": AuthService.getUser().token,
          "X-Interpreter-Region": "ua",
        },
      }).getRequest(URI.getContactByPhoneNumber + phoneNumber);

      return response;
    } catch (error) {
      console.error("getContactByPhone: ", error);
    }
  },
  async getContactByDeafId(deafId) {
    try {
      const response = await RequestService({
        headers: {
          "X-Interpreter-Token": AuthService.getUser().token,
          "X-Interpreter-Region": "ua",
        },
      }).getRequest(URI.getContactByDeafId + deafId);

      return response;
    } catch (error) {
      console.error("getContactByDeafId: ", error);
    }
  },
  // POST /interpreter/make_video/{clientId}
  // 'video' => 'required|file|mimes:mp4'
  async sendVideoMessage({ clientId, data, setProgress }) {
    console.log("sendVideoMessage", data);
    try {
      const response = await RequestService({
        data,
        headers: {
          "X-Interpreter-Token": AuthService.getUser().token,
          "Content-type": "multipart/form-data",
        },
      })
        .postRequest(URI.makeVideo + clientId, setProgress)
        .then((d) => d);

      return response;
    } catch (error) {
      console.error("sendVideoMessage: ", error);
    }
  },
};
