// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-param-reassign, global-require, max-len,  prefer-rest-params */
/* eslint-disable no-use-before-define */

const isFunction = require('lodash/isFunction');
const isObject = require('lodash/isObject');

module.exports = function initPublisherFactory(deps = {}) {
  const Errors = deps.Errors || require('../Errors.js');
  const ExceptionCodes = deps.ExceptionCodes || require('../exception_codes.js');
  const logging = deps.logging || require('../../helpers/log')('publisherInit');
  const otError = deps.otError || require('../../helpers/otError.js')();
  const OTHelpers = deps.OTHelpers || require('../../common-js-helpers/OTHelpers.js');
  const Publisher = deps.Publisher || require('./index.js')();
  const sessionObjects = deps.sessionObjects || require('../session/objects.js');

  /**
  * <p class="mSummary">
  *   Initializes and returns a Publisher object. You can then pass this Publisher
  *   object to <code>Session.publish()</code> to publish a stream to a session.
  * </p>
  * <p>
  *   <i>Note:</i> If you intend to reuse a Publisher object created using
  *   <code>OT.initPublisher()</code> to publish to different sessions sequentially,
  *   call either <code>Session.disconnect()</code> or <code>Session.unpublish()</code>.
  *   Do not call both. Then call the <code>preventDefault()</code> method of the
  *   <code>streamDestroyed</code> or <code>sessionDisconnected</code> event object to prevent the
  *   Publisher object from being removed from the page.
  * </p>
  *
  * @param {Object} targetElement (Optional) The DOM element or the <code>id</code> attribute of the
  * existing DOM element used to determine the location of the Publisher video in the HTML DOM. See
  * the <code>insertMode</code> property of the <code>properties</code> parameter. If you do not
  * specify a <code>targetElement</code>, the application appends a new DOM element to the HTML
  * <code>body</code>.
  *
  * <p>
  *       The application throws an error if an element with an ID set to the
  *       <code>targetElement</code> value does not exist in the HTML DOM.
  * </p>
  *
  * @param {Object} properties (Optional) This object contains the following properties (each of which
  * are optional):
  * </p>
  * <ul>
  * <li>
  *   <strong>audioBitrate</strong> (Number) &#151; The desired bitrate for the published audio,
  *   in bits per second. The supported range of values is 6,000 - 510,000. (Invalid values are
  *   ignored.) Set this value to enable high-quality audio (or to reduce bandwidth usage with
  *   lower-quality audio).
  *   <p>
  *   The following are recommended settings:
  *   <ul>
  *     <li>8,000 - 12,000 for narrowband (NB) speech</li>
  *     <li>16,000 - 20,000 for wideband (WB) speech</li>
  *     <li>28,000 - 40,000 for full-band (FB) speech</li>
  *     <li>48,000 - 64,000 for full-band (FB) music</li>
  *   </ul>
  *   </p>
  *   <p>
  *   The default value is 40,000.
  *   </p>
  *   <p>
  *     Currently, this setting is not supported in streams published in Firefox.
  *   </p>
  * </li>
  * <li>
  *   <strong>audioFallbackEnabled</strong> (Boolean) &#151; Whether the stream will use the
  *   audio-fallback feature (<code>true</code>) or not (<code>false</code>). The audio-fallback
  *   feature is available in sessions that use the OpenTok Media Router. With the audio-fallback
  *   feature enabled (the default), when the server determines that a stream's quality has degraded
  *   significantly for a specific subscriber, it disables the video in that subscriber in order to
  *   preserve audio quality. For streams that use a camera as a video source, the default setting is
  *   <code>true</code> (the audio-fallback feature is enabled). The default setting is
  *   <code>false</code> (the audio-fallback feature is disabled) for screen-sharing streams, which
  *   have the <code>videoSource</code> property set to <code>"application"</code>,
  *   <code>"screen"</code>, or <code>"window"</code> in the <code>OT.initPublisher()</code>
  *   options. For more information, see the Subscriber
  *   <a href="Subscriber.html#event:videoDisabled">videoDisabled</a> event and
  *   <a href="http://tokbox.com/opentok/tutorials/create-session/#media-mode">the OpenTok Media
  *   Router and media modes</a>.
  * </li>
  * <li>
  *   <strong>audioSource</strong> (String, MediaStreamTrack, Boolean, or null) &#151;
  *    The ID of the audio input device (such as a
  *    microphone) to be used by the publisher. You can obtain a list of available devices, including
  *    audio input devices, by calling the <a href="#getDevices">OT.getDevices()</a> method. Each
  *    device listed by the method has a unique device ID. If you pass in a device ID that does not
  *    match an existing audio input device, the call to <code>OT.initPublisher()</code> fails with an
  *    error (error code 1500, "Unable to Publish") passed to the completion handler function.
  *    <p>
  *    If you set this property to <code>null</code> or <code>false</code>, the browser does not
  *    request access to the microphone, and no audio is published.
  *    </p>
  *    <p>
  *    You can also set this property to an audio
  *    <a href="https://developer.mozilla.org/en-US/docs/Web/API/MediaStreamTrack">
  *    MediaStreamTrack</a> object. This lets you use the object as the audio source for
  *    the published stream. For example, you can get an array of audio MediaStreamTrack objects
  *    from the <code>audioTracks</code> property of an HTMLMediaElement object. Or you can call
  *    the <code>getLocalStreams()</code> or <code>getRemoteStreams()</code> method of an
  *    RTCPeerConnection object to get an array of MediaStream objects, and then call the
  *    <code>getAudioTracks()</code> method of one of the MediaStream objects to get an audio
  *    MediaStreamTrack object.
  *    </p>
  * </li>
  * <li>
  *   <strong>disableAudioProcessing</strong> (Boolean) &#151; Whether to disable echo cancellation,
  *   automatic gain control, and noise suppression for the published audio. You may want to set
  *   this to <code>true</code> when publishing high-quality audio (by setting the
  *   <code>audioBitrate</code> property of the <code>OT.initPublisher()</code> options). The
  *   default value is <code>false</code>.
  * </li>
  * <li>
  *   <strong>enableStereo</strong> (Boolean) &#151; Whether to publish stereo audio. The default
  *   value is <code>false</code>.
  *   <p>
  *   <i>Note:</i> Some browsers (such as Chome 73+) do not support
  *   echo cancellation for stereo audio (see <a target="_blank"
  *   href="https://bugs.chromium.org/p/webrtc/issues/detail?id=10465">this
  *   Chrome issue report</a>).
  * </li>
  * <li>
  *   <strong>facingMode</strong> (String) &#151; The preferred camera position to use for the
  *   video source. Generally, this setting only applies to mobile devices. This can be set to
  *   one of the following values:
  *   <p>
  *     <ul>
  *       <li>
  *         <code>"user"</code> &mdash; The front-facing camera.
  *       </li>
  *       <li>
  *         <code>"environment"</code> &mdash; The back camera.
  *       </li>
  *       <li>
  *         <code>"left"</code> &mdash; The camera facing the user but to the left
  *         (uncommon).
  *       </li>
  *       <li>
  *         <code>"right"</code> &mdash; The camera facing the user but to the right
  *         (uncommon).
  *       </li>
  *     </ul>
  *   </p>
  * </li>
  * <li>
  *   <strong>fitMode</strong> (String) &#151; Determines how the video is displayed if the its
  *     dimensions do not match those of the DOM element. You can set this property to one of the
  *     following values:
  *     <p>
  *     <ul>
  *       <li>
  *         <code>"cover"</code> &mdash; The video is cropped if its dimensions do not match those of
  *         the DOM element. This is the default setting for videos publishing a camera feed.
  *       </li>
  *       <li>
  *         <code>"contain"</code> &mdash; The video is letterboxed if its dimensions do not match
  *         those of the DOM element. This is the default setting for screen-sharing videos.
  *       </li>
  *     </ul>
  * </li>
  * <li>
  *   <strong>frameRate</strong> (Number) &#151; The desired frame rate, in frames per second,
  *   of the video. Valid values are 30, 15, 7, and 1. The published stream will use the closest
  *   value supported on the publishing client. The frame rate can differ slightly from the value
  *   you set, depending on the browser of the client. And the video will only use the desired
  *   frame rate if the client configuration supports it.
  *   <br><br><p>If the publisher specifies a frame rate, the actual frame rate of the video stream
  *   is set as the <code>frameRate</code> property of the Stream object, though the actual frame rate
  *   will vary based on changing network and system conditions. If the developer does not specify a
  *   frame rate, this property is undefined. Also, if the video source is a video MediaStreamTrack
  *   object (see the <code>videoSource</code>), this value is ignored and the frame rate is
  *   defined by the MediaStreamTrack object, which you can get by calling the
  *   <code>getConstraints()</code> method of the MediaStreamTrack object.
  *   </p>
  *   <p>
  *   For sessions that use the OpenTok Media Router (sessions with
  *   the <a href="http://tokbox.com/opentok/tutorials/create-session/#media-mode">media mode</a>
  *   set to routed, lowering the frame rate proportionally reduces the maximum bandwidth the stream
  *   uses. However, in sessions with the media mode set to relayed, lowering the frame rate does not
  *   reduce the stream's bandwidth.
  *   </p>
  *   <p>
  *   You can also restrict the frame rate of a Subscriber's video stream. To restrict the frame rate
  *   a Subscriber, call the <code>restrictFrameRate()</code> method of the subscriber, passing in
  *   <code>true</code>.
  *   (See <a href="Subscriber.html#restrictFrameRate">Subscriber.restrictFrameRate()</a>.)
  *   </p>
  * </li>
  * <li>
  *   <strong>height</strong> (Number or String) &#151; The desired initial height of the displayed Publisher
  *   video in the HTML page (default: 198 pixels). You can specify the number of pixels as either
  *   a number (such as 300) or a string ending in "px" (such as "300px"). Or you can specify a
  *   percentage of the size of the parent element, with a string ending in "%" (such as "100%").
  *   <i>Note:</i> To resize the publisher video, adjust the CSS of the publisher's DOM element
  *   (the <code>element</code> property of the Publisher object) or (if the height is specified as
  *   a percentage) its parent DOM element (see
  *   <a href="https://tokbox.com/developer/guides/customize-ui/js/#video_resize_reposition">Resizing
  *   or repositioning a video</a>).
  * </li>
  * <li>
  *   <strong>insertDefaultUI</strong> (Boolean) &#151; Whether to use the default OpenTok UI
  *   (<code>true</code>, the default) or not (<code>false</code>). The default UI element contains
  *   user interface controls, a video loading indicator, and automatic video cropping or
  *   letterboxing, in addition to the video. (If you leave <code>insertDefaultUI</code> set to
  *   <code>true</code>, you can control individual UI settings using the <code>fitMode</code>,
  *   <code>showControls</code>, and <code>style</code> options.)
  *   <p>
  *   If you set this option to <code>false</code>, OpenTok.js does not insert a default UI element
  *   in the HTML DOM, and the <code>element</code> property of the Publisher object is undefined.
  *   Instead, the Publisher object dispatches a
  *   <a href="Publisher.html#event:videoElementCreated">videoElementCreated</a> event when
  *   the <code>video</code> element (or in Internet Explorer the <code>object</code> element
  *   containing the video) is created. The <code>element</code> property of the event object is a
  *   reference to the Publisher's <code>video</code> (or <code>object</code>) element. Add it to
  *   the HTML DOM to display the video.
  *   <p>
  *   Set this option to <code>false</code> if you want to move the Publisher's <code>video</code>
  *   (or <code>object</code>) element in the HTML DOM.
  *   <p>
  *   If you set this to <code>false</code>, do not set the <code>targetElement</code> parameter.
  *   (This results in an error passed into to the <code>OT.initPublisher()</code> callback
  *   function.) To add the video to the HTML DOM, add an event listener for the
  *   <code>videoElementCreated</code> event, and then add the <code>element</code> property of
  *   the event object into the HTML DOM.
  * </li>
  * <li>
  *   <strong>insertMode</strong> (String) &#151; Specifies how the Publisher object will be
  *   inserted in the HTML DOM. See the <code>targetElement</code> parameter. This string can
  *   have the following values:
  *   <p>
  *   <ul>
  *     <li><code>"replace"</code> &#151; The Publisher object replaces contents of the
  *       targetElement. This is the default.</li>
  *     <li><code>"after"</code> &#151; The Publisher object is a new element inserted after
  *       the targetElement in the HTML DOM. (Both the Publisher and targetElement have the
  *       same parent element.)</li>
  *     <li><code>"before"</code> &#151; The Publisher object is a new element inserted before
  *       the targetElement in the HTML DOM. (Both the Publisher and targetElement have the same
  *       parent element.)</li>
  *     <li><code>"append"</code> &#151; The Publisher object is a new element added as a child
  *       of the targetElement. If there are other child elements, the Publisher is appended as
  *       the last child element of the targetElement.</li>
  *   </ul></p>
  * <li>
  *   <strong>maxResolution</strong> (Object) &#151; Sets the maximum resolution to stream.
  *   This setting only applies to when the <code>videoSource</code> property is set to
  *   <code>"application"</code>, <code>"screen"</code>, or <code>"window"</code>
  *   (when the publisher is screen-sharing). The resolution of the
  *   stream will match the captured screen region unless the region is greater than the
  *   <code>maxResolution</code> setting. Set this to an object that has two properties:
  *   <code>width</code> and <code>height</code> (both numbers). The maximum value for each of
  *   the <code>width</code> and <code>height</code> properties is 1920, and the minimum value
  *   is 10.
  * </li>
  * <li>
  *   <strong>mirror</strong> (Boolean) &#151; Whether the publisher's video image
  *   is mirrored in the publisher's page. The default value is <code>true</code>
  *   (the video image is mirrored), except for a screen-sharing video (when the
  *   <code>videoSource</code> property is set to <code>"application"</code>,
  *   <code>"screen"</code>, or <code>"window"</code>
  *   (in which case the default is <code>false</code>). This property
  *   does not affect the display on subscribers' views of the video.
  * </li>
  * <li>
  *   <strong>name</strong> (String) &#151; The name for this stream. The name appears at
  *   the bottom of Subscriber videos. The default value is "" (an empty string). Setting
  *   this to a string longer than 1000 characters results in an runtime exception.
  * </li>
  * <li>
  *   <strong>publishAudio</strong> (Boolean) &#151; Whether to initially publish audio
  *   for the stream (default: <code>true</code>). This setting applies when you pass
  *   the Publisher object in a call to the <code>Session.publish()</code> method.
  * </li>
  * <li>
  *   <strong>publishVideo</strong> (Boolean) &#151; Whether to initially publish video
  *   for the stream (default: <code>true</code>). This setting applies when you pass
  *   the Publisher object in a call to the <code>Session.publish()</code> method.
  * </li>
  * <li>
  *   <strong>resolution</strong> (String) &#151; The desired resolution of the video. The format
  *   of the string is <code>"widthxheight"</code>, where the width and height are represented in
  *   pixels. Valid values are <code>"1280x720"</code>, <code>"640x480"</code>, and
  *   <code>"320x240"</code>. The published video will only use the desired resolution if the
  *   client configuration supports it. Some browsers and clients do not support each of these
  *   resolution settings.
  *   <br><br><p>
  *   The requested resolution of a video stream is set as the <code>videoDimensions.width</code> and
  *   <code>videoDimensions.height</code> properties of the Stream object.
  *   </p>
  *   <p>
  *   The default resolution for a stream (if you do not specify a resolution) is 640x480 pixels.
  *   If the client system cannot support the resolution you requested, the stream will use the
  *   next largest setting supported.
  *   </p>
  *   <p>
  *   The actual resolution used by the Publisher is returned by the <code>videoHeight()</code> and
  *   <code>videoWidth()</code> methods of the Publisher object. The actual resolution of a
  *   Subscriber video stream is returned by the <code>videoHeight()</code> and
  *   <code>videoWidth()</code> properties of the Subscriber object. These may differ from the values
  *   of the <code>resolution</code> property passed in as the <code>properties</code> property of the
  *   <code>OT.initPublisher()</code> method, if the browser does not support the requested
  *   resolution, this value is ignored. Also, if the video source is a video MediaStreamTrack
  *   object (see the <code>videoSource</code>), this value is ignored and the resolution
  *   is defined by the MediaStreamTrack object, which you can get by calling the
  *   <code>getConstraints()</code> method of the MediaStreamTrack object.
  *   </p>
  * </li>
  * <li>
  *   <strong>showControls</strong> (Boolean) &#151; Whether to display the built-in user interface
  *   controls (default: <code>true</code>) for the Publisher. These controls include the name
  *   display, the audio level indicator, and the microphone control button. You can turn off all user
  *   interface controls by setting this property to <code>false</code>. You can control the display
  *   of individual user interface controls by leaving this property set to <code>true</code> (the
  *   default) and setting individual properties of the <code>style</code> property.
  * </li>
  * <li>
  *   <strong>style</strong> (Object) &#151; An object containing properties that define the initial
  *   appearance of user interface controls of the Publisher. The <code>style</code> object includes
  *   the following properties:
  *     <ul>
  *       <li><code>audioLevelDisplayMode</code> (String) &mdash; How to display the audio level
  *       indicator. Possible values are: <code>"auto"</code> (the indicator is displayed when the
  *       video is disabled), <code>"off"</code> (the indicator is not displayed), and
  *       <code>"on"</code> (the indicator is always displayed).</li>
  *
  *       <li><code>archiveStatusDisplayMode</code> (String) &mdash; How to display the archive status
  *       indicator. Possible values are: <code>"auto"</code> (the indicator is displayed when the
  *       session is being recorded), <code>"off"</code> (the indicator is not displayed). If you
  *       disable the archive status display indicator, you can display your own user interface
  *       notifications based on the <code>archiveStarted</code> and <code>archiveStopped</code>
  *       events dispatched by the Session object.</li>
  *
  *       <li><code>backgroundImageURI</code> (String) &mdash; A URI for an image to display as
  *       the background image when a video is not displayed. (A video may not be displayed if
  *       you call <code>publishVideo(false)</code> on the Publisher object). You can pass an http
  *       or https URI to a PNG, JPEG, or non-animated GIF file location. You can also use the
  *       <code>data</code> URI scheme (instead of http or https) and pass in base-64-encrypted
  *       PNG data, such as that obtained from the
  *       <a href="Publisher.html#getImgData">Publisher.getImgData()</a> method. (For example,
  *       you could set the property to a value returned by calling <code>getImgData()</code> on
  *       a previous Publisher object.) If the URL or the image data is invalid, the property
  *       is ignored (the attempt to set the image fails silently).</li>
  *
  *       <li><code>buttonDisplayMode</code> (String) &mdash; How to display the microphone controls
  *       Possible values are: <code>"auto"</code> (controls are displayed when the stream is first
  *       displayed and when the user mouses over the display), <code>"off"</code> (controls are not
  *       displayed), and <code>"on"</code> (controls are always displayed).</li>
  *
  *       <li><code>nameDisplayMode</code> (String) &#151; Whether to display the stream name.
  *       Possible values are: <code>"auto"</code> (the name is displayed when the stream is first
  *       displayed and when the user mouses over the display), <code>"off"</code> (the name is not
  *       displayed), and <code>"on"</code> (the name is always displayed).</li>
  *   </ul>
  * </li>
  * <li>
  *   <strong>videoSource</strong> (String, MediaStreamTrack, Boolean, or null) &#151;
  *    The ID of the video input device (such as a
  *    camera) to be used by the publisher. You can obtain a list of available devices, including
  *    video input devices, by calling the <a href="#getDevices">OT.getDevices()</a> method. Each
  *    device listed by the method has a unique device ID. If you pass in a device ID that does not
  *    match an existing video input device, the call to <code>OT.initPublisher()</code> fails with an
  *    error (error code 1500, "Unable to Publish") passed to the completion handler function.
  *    <p>
  *    If you set this property to <code>null</code> or <code>false</code>, the browser does not
  *    request access to the camera, and no video is published. In a voice-only call, set this
  *    property to <code>null</code> or <code>false</code> for each Publisher.
  *    </p>
  *   <p>
  *    To publish a screen-sharing stream, set this property to <code>"application"</code>,
  *    <code>"screen"</code>, or <code>"window"</code>. In Firefox, the screen sharing source
  *    reflects the value set. For example, if you set the property to <code>"window"</code>,
  *    Firefox will prompt the end user to select the <i>window</i> to share. In other browsers,
  *    setting the <code>videoSource</code> property to any of these values results in a prompt
  *    that asks the user to determine the screen-sharing source. Call
  *    <a href="OT.html#checkScreenSharingCapability">OT.checkScreenSharingCapability()</a>
  *    to check if screen sharing is supported and which video sources (<code>"application"</code>,
  *    <code>"screen"</code>, or <code>"window"</code>) are supported. For screen-sharing streams,
  *    the following are default values for other properties: <code>audioFallbackEnabled == false</code>,
  *    <code>maxResolution == {width: 1920, height: 1920}</code>, <code>mirror == false</code>,
  *    <code>scaleMode == "fit"</code>. Also, the default <code>scaleMode</code> setting for
  *    subscribers to the stream is <code>"fit"</code>.
  *   </p>
  *   <p>
  *    You can also set this property to a video
  *    <a href="https://developer.mozilla.org/en-US/docs/Web/API/MediaStreamTrack">
  *    MediaStreamTrack</a> object. This lets you use the MediaStreamTrack object as the
  *    video source for the published stream. For example, you can get a CanvasCaptureMediaStream
  *    object by calling the <code>captureStream()</code> method of a HTMLCanvasElement, and then
  *    you can call the <code>getVideoTracks()</code> method of the CanvasCaptureMediaStream object
  *    to get a video MediaStreamTrack object. Or you can get an array of video MediaStreamTrack
  *    object from the <code>videoTracks</code> property of an HTMLMediaElement object. Or you can
  *    call the <code>getLocalStreams()</code> or <code>getRemoteStreams()</code> method of an
  *    RTCPeerConnection object to get an array of MediaStream objects, and then call the
  *    <code>getVideoTracks()</code> method of one of the MediaStream objects to get an array
  *    of video MediaStreamTrack objects. Note that by default the publisher uses a microphone
  *    as the audio source, so be sure to set the <code>audioSource</code> option if you want
  *    to use a MediaStreamTrack as the audio source or if you do not want to publish an audio
  *    track. The default <code>mirror</code> setting for a Publisher with a MediaStreamTrack video
  *    source is <code>false</code>. You can also call
  *    <a href="OT.html#getUserMedia">OT.getUserMedia()</a> to get a MediaStream object that uses
  *    the microphone and camera as the audio and video sources.
  *   </p>
  * </li>
  * <li>
  *   <strong>width</strong> (Number or String) &#151; The desired initial width of the displayed Publisher
  *   video in the HTML page (default: 264 pixels). You can specify the number of pixels as either
  *   a number (such as 400) or a string ending in "px" (such as "400px"). Or you can specify a
  *   percentage of the size of the parent element, with a string ending in "%" (such as "100%").
  *   <i>Note:</i> To resize the publisher video, adjust the CSS of the publisher's DOM element
  *   (the <code>element</code> property of the Publisher object) or (if the width is specified as
  *   a percentage) its parent DOM element (see
  *   <a href="https://tokbox.com/developer/guides/customize-ui/js/#video_resize_reposition">Resizing
  *   or repositioning a video</a>).
  * </li>
  * </ul>
  * @param {Function} completionHandler (Optional) A function to be called when the method succeeds
  * or fails in initializing a Publisher object. This function takes one parameter &mdash;
  * <code>error</code>. On success, the <code>error</code> object is set to <code>null</code>. On
  * failure, the <code>error</code> object has two properties: <code>code</code> (an integer) and
  * <code>message</code> (a string), which identify the cause of the failure. The method succeeds
  * when the user grants access to the camera and microphone. The method fails if the user denies
  * access to the camera and microphone. The <code>completionHandler</code> function is called
  * before the Publisher dispatches an <code>accessAllowed</code> (success) event or an
  * <code>accessDenied</code> (failure) event.
  * <p>
  * The following code adds a <code>completionHandler</code> when calling the
  * <code>OT.initPublisher()</code> method:
  * </p>
  * <pre>
  * var publisher = OT.initPublisher('publisher', null, function (error) {
  *   if (error) {
  *     console.log(error);
  *   } else {
  *     console.log("Publisher initialized.");
  *   }
  * });
  * </pre>
  *
  * @returns {Publisher} The Publisher object.
  * @see <a href="Session.html#publish">Session.publish()</a>
  * @method OT.initPublisher
  * @memberof OT
  */
  return function initPublisher(targetElement, properties, completionHandler) {
    logging.debug(`OT.initPublisher(${targetElement})`);

    // To support legacy (apikey, targetElement, properties) users
    // we check to see if targetElement is actually an apikey. Which we ignore.
    if (typeof targetElement === 'string' && !document.getElementById(targetElement)) {
      targetElement = properties;
      properties = completionHandler;
      completionHandler = arguments[3];
    }

    if (typeof targetElement === 'function') {
      completionHandler = targetElement;
      properties = undefined;
      targetElement = undefined;
    } else if (isObject(targetElement) && !(OTHelpers.isElementNode(targetElement))) {
      completionHandler = properties;
      properties = targetElement;
      targetElement = undefined;
    }

    if (typeof properties === 'function') {
      completionHandler = properties;
      properties = undefined;
    }

    let errMsg;

    if (properties && !isObject(properties)) {
      errMsg = 'properties argument to Publisher constructor, if provided, should be an object';
      properties = undefined;
    }

    if (properties && properties.insertDefaultUI === false && targetElement) {
      errMsg = 'You cannot specify a target element if insertDefaultUI is false';
    }

    const publisher = new Publisher(properties || {});
    sessionObjects.publishers.add(publisher);

    const triggerCallback = function triggerCallback() {
      if (completionHandler && isFunction(completionHandler)) {
        completionHandler(...arguments);
        completionHandler = undefined;
      }
    };

    if (errMsg !== undefined) {
      logging.error(errMsg);

      triggerCallback(otError(
        Errors.INVALID_PARAMETER,
        new Error(errMsg),
        ExceptionCodes.INVALID_PARAMETER
      ));
    }

    const removeInitSuccessAndCallComplete = function removeInitSuccessAndCallComplete(err) {
      publisher.off('publishComplete', removeHandlersAndCallComplete);
      triggerCallback(err);
    };

    let removeHandlersAndCallComplete = function removeHandlersAndCallComplete(err) {
      publisher.off('initSuccess', removeInitSuccessAndCallComplete);

      // We're only handling the error case here as we're just
      // initing the publisher, not actually attempting to publish.
      if (err) { triggerCallback(err); }
    };

    publisher.once('initSuccess', removeInitSuccessAndCallComplete);
    publisher.once('publishComplete', removeHandlersAndCallComplete);

    publisher.publish(targetElement);

    return publisher;
  };
};
