const { createSlice } = require("@reduxjs/toolkit");


export const FileSlice = createSlice({
	name: 'fileSlice',
	initialState: {
		zoom: 1,
		rotation: 0
	},
	reducers: {
		setZoom: (state, action) => {
			state.zoom = action.payload
		},
		setRotation: (state, action) => {
			state.rotation = action.payload
		},
	}
});

export const { setZoom, setRotation } = FileSlice.actions;

export default FileSlice.reducer;
