import { useCallback, useState } from "react";

export default function useIsOpen(initialState = false) {
  const [isOpen, setIsOpen] = useState(initialState);

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);
  const toggle = useCallback(() => setIsOpen((prevState) => !prevState), []);

  return { isOpen, open, close, toggle };
}
