import React, { useState, useEffect } from "react";
import { formatTimeFromSeconds } from "src/utils/CustomDate";

function TimeCounter({ startedAt }: { startedAt: number }) {
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    const initialDifference = Math.floor(Date.now() / 1000) - startedAt;

    setCurrentTime(initialDifference);

    const interval = setInterval(() => {
      setCurrentTime((prevTime) => prevTime + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [startedAt]);

  const { minutes, seconds } = formatTimeFromSeconds(currentTime);

  return <>{minutes + ":" + seconds}</>;
}

export default TimeCounter;
