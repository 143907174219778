import React, { FormEvent } from "react";
// import { Button } from "@mui/material";
import { convertUnixTimestampToDateString } from "src/utils/CustomDate";
import styled from "styled-components";
// import useSound from "use-sound";
// import message from "src/assets/message.wav";
import { useTranslation } from "react-i18next";
import { IconButton, TextField } from "@material-ui/core";
import { CallService } from "src/services/CallService";
import { AuthorType } from "src/types";
import { Icon, IconName } from "src/components/Icon";

interface Message {
  from: AuthorType;
  message: string;
  created_at: number;
  socket: null;
}

interface Props {
  sessionId: string;
  messages: Message[];
  isVisible: boolean;
  setIsVisible: () => void;
}

const CallChat = ({ sessionId, messages, isVisible, setIsVisible }: Props) => {
  const [messageToSend, setMessageToSend] = React.useState("");
  // const [playSound] = useSound(message, {
  //   interrupt: true,
  //   volume: 1,
  //   loop: false,
  // });
  const { t } = useTranslation();
  // useEffect(() => {
  //   if (messages.length === 0) return;

  //   playSound();
  // }, [messages.length, playSound]);

  async function handleMessageSubmit(e: FormEvent) {
    e.preventDefault();
    await CallService.sendChatMessageToClient({
      message: messageToSend,
      session_id: sessionId,
    });

    setMessageToSend("");
  }

  return isVisible ? (
    <ContentWrapper>
      <MessagesWrapper>
        {messages.length ? (
          messages.map(({ message, created_at, from }) => {
            const [_, time] = convertUnixTimestampToDateString(created_at).split(",");

            return (
              <MessageBox author={from} key={created_at}>
                <p style={{ width: "90%",marginBottom:12 }}>{message}</p>
                {created_at ? <TimeChip author={from}>{time}</TimeChip> : null}
              </MessageBox>
            );
          })
        ) : (
          <Placeholder>{t("call.chatMessagePlaceholder")}</Placeholder>
        )}
      </MessagesWrapper>
      <form
        onSubmit={handleMessageSubmit}
        style={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "white",
          borderRadius: 4,
          padding: 8,
        }}
      >
        <IconButton onClick={setIsVisible} style={{ alignSelf: "flex-start" }}>
          <Icon name={IconName.CROSS} />
        </IconButton>
        <TextField
          style={{ flexGrow: 1 }}
          placeholder={t("chatPlaceholder")}
          value={messageToSend}
          onChange={(e) => setMessageToSend(e.target.value)}
        />
        <IconButton type="submit" style={{ borderRadius: 4, padding: "6px" }}>
          <Icon size="32px" name={IconName.SEND_ICON} />
        </IconButton>
      </form>
    </ContentWrapper>
  ) : null;
};

export default CallChat;

const MessageBox = styled.div<{ author: AuthorType }>`
  display: flex;
  /* width: 50%; */
  margin-left: auto;
  flex-direction: column;
  /* max-height: 600px; */
  border: 1px solid #7dabd8;
  /* flex-basis: 70%; */
  background-color: ${({ author }) => (author === AuthorType.CLIENT ? "#008dff" : "#fff")};
  color: ${({ author }) => (author === AuthorType.CLIENT ? "#fff" : "black")};
  padding: 8px;
  /* overflow: hidden; */
  border-radius: 8px;
  border-top-left-radius: ${({ author }) => (author === AuthorType.INTERPRETER ? "0px" : "")};
  border-top-right-radius: ${({ author }) => (author === AuthorType.CLIENT ? "0px" : "")};
`;

const Placeholder = styled.p({
  padding: "16px",
  textAlign: "center",
  backgroundColor: "white",
  borderRadius: 4,
});

const TimeChip = styled.p<{ author: AuthorType }>(({ author }) => ({
  textAlign: "end",
  // color: "#7dabd8",
  color: author === AuthorType.CLIENT ? "#fff" : "black",
}));

const ContentWrapper = styled.div({
  width: "340px",
  maxHeight: "80vh",
  // backgroundColor: "white",
  borderRadius: "8px",
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  zIndex: 1000,
  padding: "8px",
  position: "absolute",
  right: 0,
  bottom: 0,
});

const MessagesWrapper = styled.div({
  // display: "inline-flex",
  display: 'block',
  maxHeight: '65dvh',
  overflowY:'auto',
  flexDirection: "column",
  justifyContent: "flex-end",
  alignItems: "flex-end",
  flexGrow: 1,
  padding: "8px",
  opacity: 0.3,

  "&:hover": {
    opacity:1
  },

  "&>div:not(:last-child)": {
    marginBottom: "8px",
  },
});
