export function hasMatchingId(array1: any[], array2: any[]) {
  const idSet = new Set(array1.map((obj) => obj.id));
  return array2.some((obj) => idSet.has(obj.id));
}

export function findMatchingIndex(array1: any[], array2: any[]) {
  const idSet = new Set(array1.map((obj) => obj.id));
  const matchingObj = array2.find((obj) => idSet.has(obj.id));
  return matchingObj ? array1.findIndex((obj) => obj.id === matchingObj.id) : -1;
}

export function areArraysSimilarByStatus(array1:any[], array2:any[]) {
  if (array1.length !== array2.length) {
    return false;
  }

  for (let i = 0; i < array1.length; i++) {
    if (array1[i].status !== array2[i].status) {
      return false;
    }
  }

  return true;
}
