import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeProvider, createTheme } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import * as ReactDOMClient from "react-dom/client";
import { Toaster } from "react-hot-toast";

import { App } from "./App";
import { store } from "./reducers/store";
import AuthReducer from "src/reducers/AuthReducer";

import "./translations/helpers/translations";
import "./index.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#2196F3 !important",
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: "Roboto",
    h3: {
      textAlign: "center",
      alignSelf: "center",
      fontWeight: 300,
      fontSize: 40,
    },
    h4: {
      textAlign: "center",
      alignSelf: "center",
      userSelect: "none",
      fontWeight: 500,
      color: "#fff",
    },
    h5: {
      textAlign: "center",
      alignSelf: "center",
      color: "rgba(0, 0, 0, 0.54)",
      userSelect: "none",
    },
    h6: {
      textAlign: "left",
      alignSelf: "center",
      fontSize: 20,
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: 14,
      color: "#757575",
      alignSelf: "left",
      textAlign: "left",
      letterSpacing: 0.25,
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: 14,
      color: "#fff",
    },
    body1: {
      fontSize: 16,
      alignSelf: "left",
      textAlign: "left",
      color: "rgba(0, 0, 0, 0.87)",
      letterSpacing: 0.15,
    },
    body2: {
      fontSize: 16,
      fontWeight: 400,
      wordBreak: "break-word",
      textAlign: "left",
    },
    button: {
      fontSize: 10,
      fontWeight: 500,
      width: 64,
      textTransform: "capitalize",
    },
  },
});

const root = ReactDOMClient.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <AuthReducer>
        <Toaster />
        <BrowserRouter>
          <CssBaseline />
          <App />
        </BrowserRouter>
      </AuthReducer>
    </Provider>
  </ThemeProvider>
);
