import { makeStyles, Paper, Typography, Box, Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useActions } from '../../hooks/useActions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
	confirmSave: {
		position: 'absolute',
		width: '300px',
		height: '165px',
		background: '#fff',
		top: 'calc(50vh - 100px)',
		right: 'calc(50vw - 150px)',
		padding: '34px 27px',
		zIndex: '5',
	},
	contentBox: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		height: '100%',
	},
	buttonBox: {
		display: 'flex',
		gap: '9px',
	},
	button: {
		width: '120px',
		height: '36px',
		textTransform: 'uppercase',
		fontSize: '12px',
	},
	confirmSecondary: {
		position: 'absolute',
		width: '300px',
		height: '95px',
		background: '#fff',
		top: 'calc(50vh - 100px)',
		right: 'calc(50vw - 150px)',
		padding: '34px 27px',
		zIndex: '5',
	},
	secondaryText: {
		fontSize: '17px',
		fontWeight: '400',
		textAlign: 'center',
	},
});

export const ConfirmRecord = ({ recorder, setIsCameraOn }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { confirmationWindow, deleteWindow, record } = useSelector(
		(state) => state.recordsReducer
	);
	const myState = useSelector((state) => state.recordsReducer);
	const {
		callConfirmationWindow,
		callDeleteWindow,
		setIsRecording,
		setIsPaused,
		toggleCamera,
	} = useActions();
	const [confirmationSecondary, callConfirmationSecondary] = useState(false);
	const [deleteSecondary, callDeleteSecondary] = useState(false);

	const handleDelete = () => {
		setIsCameraOn(false);
		setIsRecording(false);
		setIsPaused(false);
		callDeleteSecondary(true);
	};

	const handleSave = () => {
		recorder.current.onStopRecording();
		setIsRecording(false);
		setIsPaused(false);
		setIsCameraOn(false);
		toggleCamera(false);
		callConfirmationSecondary(true);
	};

	if (deleteSecondary) {
		setTimeout(() => {
			callDeleteSecondary(false);
			callDeleteWindow(false);
		}, 3000);

		return (
			<Paper className={classes.confirmSecondary} elevation={10}>
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					height="100%"
				>
					<Typography className={classes.secondaryText}>
						{t('translationsTab.deleted')}
					</Typography>
				</Box>
			</Paper>
		);
	}

	if (confirmationSecondary) {
		setTimeout(() => {
			callConfirmationSecondary(false);
			callConfirmationWindow(false);
		}, 3000);

		return (
			<Paper className={classes.confirmSecondary} elevation={10}>
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					height="100%"
				>
					<Typography className={classes.secondaryText}>
						{t('translationsTab.saved')}
					</Typography>
				</Box>
			</Paper>
		);
	}

	if (deleteWindow) {
		return (
			<Paper className={classes.confirmSave} elevation={10}>
				<Box className={classes.contentBox}>
					<Typography className={classes.secondaryText}>
						{t('translationsTab.sureDelete')}
					</Typography>
					<Box className={classes.buttonBox}>
						<Button
							variant="contained"
							color="primary"
							className={classes.button}
							onClick={handleDelete}
						>
							{t('translationsTab.delete')}
						</Button>
						<Button
							variant="outlined"
							color="primary"
							className={classes.button}
							onClick={() => callDeleteWindow(false)}
						>
							{t('translationsTab.cancel')}
						</Button>
					</Box>
				</Box>
			</Paper>
		);
	}

	if (confirmationWindow) {
		return (
			<Paper className={classes.confirmSave} elevation={10}>
				<Box className={classes.contentBox}>
					<Typography className={classes.secondaryText}>
						{t('translationsTab.endAndSave')}
					</Typography>
					<Box className={classes.buttonBox}>
						<Button
							variant="contained"
							color="primary"
							className={classes.button}
							onClick={handleSave}
						>
							{t('translationsTab.save')}
						</Button>
						<Button
							variant="outlined"
							color="primary"
							className={classes.button}
							onClick={() => callConfirmationWindow(false)}
						>
							{t('translationsTab.cancel')}
						</Button>
					</Box>
				</Box>
			</Paper>
		);
	}
};
