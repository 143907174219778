/* eslint-disable no-underscore-dangle */

const get = require('lodash/get');
const cloneDeep = require('lodash/cloneDeep');
/**
 * Provides an interface around the raw session info from Anvil
 *
 * @class SessionInfo
 * @param {Object} rawSessionInfo The raw session info from Anvil
 *
 */
class SessionInfo {
  constructor(rawSessionInfo = {}) {
    Object.defineProperty(this, '_rawSessionInfo', {
      value: cloneDeep(rawSessionInfo),
    });
  }
  /**
   * @type {String}
   * @readonly
   */
  get sessionId() {
    return this._rawSessionInfo.session_id;
  }
  /**
   * @type {String}
   * @readonly
   */
  get partnerId() {
    return this._rawSessionInfo.partner_id;
  }
  /**
   * @type {String}
   * @readonly
   */
  get messagingServer() {
    return this._rawSessionInfo.messaging_server_url;
  }
  /**
   * @type {String}
   * @readonly
   */
  get mediaServerName() {
    return this._rawSessionInfo.media_server_hostname;
  }
  /**
   * @type {String}
   * @readonly
   */
  get messagingURL() {
    return this._rawSessionInfo.messaging_url;
  }
  /**
   * @type {String}
   * @readonly
   */
  get symphonyAddress() {
    return this._rawSessionInfo.symphony_address;
  }
  /**
   * @type {Array}
   * @readonly
   */
  get iceServers() {
    return this._rawSessionInfo.ice_servers;
  }
  /**
   * Simulcast is tri-state:
   * * true - simulcast is on for this session
   * * false - simulcast is off for this session
   * * undefined - the developer can choose
   * @type {Boolean|Undefined}
   * @readonly
   */
  get simulcast() {
    return get(this._rawSessionInfo, 'properties.simulcast');
  }
  /**
   * @type {Boolean}
   * @readonly
   */
  get reconnection() {
    return get(this._rawSessionInfo, 'properties.reconnection', false);
  }
  /**
   * @type {Boolean}
   * @readonly
   */
  get renegotiation() {
    return get(this._rawSessionInfo, 'properties.renegotiation', false);
  }
  /**
   * @type {Boolean}
   * @readonly
   */
  get p2pEnabled() {
    return get(this._rawSessionInfo, 'properties.p2p.preference.value') === 'enabled';
  }
  /**
   * If this contains a valid codec, it will be prioritized
   *
   * @type {String}
   * @readonly
   */
  get priorityVideoCodec() {
    return get(this._rawSessionInfo, 'properties.priorityVideoCodec', '');
  }
  /**
   * Is true if H264 codec is enabled
   *
   * @type {Boolean}
   * @readonly
   */
  get h264() {
    return get(this._rawSessionInfo, 'properties.h264', true);
  }
  /**
   * Is true if VP8 codec is enabled
   *
   * @type {Boolean}
   * @readonly
   */
  get vp8() {
    return get(this._rawSessionInfo, 'properties.vp8', true);
  }
  /**
   * Is true if VP9 codec is enabled
   *
   * @type {Boolean}
   * @readonly
   */
  get vp9() {
    return get(this._rawSessionInfo, 'properties.vp9', true);
  }
  /**
   * clientCandidates can be "relay". Other values are unknown.
   *
   * @type {String|Undefined}
   * @readonly
   */
  get clientCandidates() {
    const rawValue = get(this._rawSessionInfo, 'properties.clientCandidates');
    return rawValue === 'relayed' ? 'relay' : rawValue;
  }
  get peerRegeneration() {
    return get(this._rawSessionInfo, 'properties.peerRegeneration', true);
  }
}

module.exports = SessionInfo;
