import WebSocketImport from 'ws';

export default (() => {
  if (global.WebSocket) {
    return global.WebSocket;
  }

  if (!WebSocketImport.CONNECTING) {
    // The ws module really should define these statics like browsers do.
    WebSocketImport.CONNECTING = 0;
    WebSocketImport.OPEN = 1;
    WebSocketImport.CLOSING = 2;
    WebSocketImport.CLOSED = 3;
  }

  return WebSocketImport;
})();
