import { createSlice } from '@reduxjs/toolkit';

export const paginationSlice = createSlice({
	name: 'pagination',
	initialState: {
		page: 0,
	},
	reducers: {
		switchPage: (state, action) => {
			state.page = action.payload;
		},
	},
});

export const { switchPage } = paginationSlice.actions;
export default paginationSlice.reducer;
