import React from 'react';
import { Modal, Typography, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const SuccessfullySwitched = ({
	isOpen,
	setIsOpen,
	switchedOperator,
	handleClose,
}) => {
	const { t } = useTranslation();
	const handleCloseSuccessModal = () => {
		setIsOpen(false);
		handleClose();
	};

	return (
		<Modal
			open={isOpen}
			onClose={handleCloseSuccessModal}
			style={styles.container}
		>
			<Box style={styles.wrapper}>
				<Typography style={styles.title}>
					{t('switchOperatorSuccess')}
				</Typography>
				<Box style={styles.operator}>
					{/* <img src={switchedOperator?.icon} alt="Operator Logo" /> */}
					<Typography style={styles.operatorText}>
						{switchedOperator?.name}
					</Typography>
				</Box>
			</Box>
		</Modal>
	);
};

export default SuccessfullySwitched;

const styles = {
	container: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	wrapper: {
		maxWidth: 310,
		backgroundColor: '#fff',
		borderRadius: 10,
		padding: '30px 24px 40px',
		boxShadow:
			'0px 8px 10px rgba(0, 0, 0, 0.2), 0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12)',
	},
	title: {
		textAlign: 'center',
		marginBottom: 20,
		fontSize: 18,
	},
	operator: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	operatorText: {
		fontSize: 16,
		marginLeft: 10,
	},
};
