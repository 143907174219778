import React, { ChangeEvent } from "react";
import { Box, FormControl, Select, MenuItem, Input } from "@material-ui/core";
// import LangItem from "./LangItem";
import { InterpreterStatus } from "src/types";
import { useTranslation } from "react-i18next";

interface Props {
  // allOperators: Operator[];
  // setFilteredOperators: Dispatch<SetStateAction<null>>;
  statusFilter: string;
  setFilterValue: (value: string) => void;
  searchValue: string;
  setSearchValue: (e: ChangeEvent<HTMLInputElement>) => void;
  availableStatuses: ("all" | InterpreterStatus)[];
}

const Controls = ({
  setFilterValue,
  statusFilter,
  availableStatuses,
  searchValue,
  setSearchValue,
}: Props) => {
  const { t } = useTranslation();
  // const languagesList = Object.values(AvailableLanguages);
  // const [statusFilter, setStatusFilter] = useState("all");
  // const [langFilter, setLangFilter] = useState("all");
  //   useEffect(() => {
  //     const hasLangFilter = langFilter !== "all";
  //     const hasStatusFilter = statusFilter !== "all";
  //     if (hasLangFilter || hasStatusFilter) {
  //       let preparedOperators = allOperators;

  //       if (hasStatusFilter) {
  //         preparedOperators = preparedOperators.filter((item) => item.activity === statusFilter);
  //       }

  //       if (hasLangFilter) {
  //         preparedOperators = preparedOperators.filter((item) =>
  //           item.languages.find((el) => el === langFilter)
  //         );
  //       }

  //       setFilteredOperators(preparedOperators);
  //     } else {
  //       setFilteredOperators(null);
  //     }
  //   }, [langFilter, statusFilter]);

  const handleChangeStatus = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    // setStatusFilter(event.target.value as string);
    setFilterValue(event.target.value as string);
  };
  // const handleChangeLanguage = (
  //   event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  // ) => {
  //   setLangFilter(event.target.value as string);
  // };

  return (
    <Box style={styles.wrapper}>
      <FormControl style={styles.item}>
        <Select
          style={{ textTransform: "capitalize" }}
          value={statusFilter}
          onChange={handleChangeStatus}
          inputProps={{ "aria-label": "Without label" }}
        >
          {availableStatuses.map((item) => {
            return (
              <MenuItem style={{ textTransform: "capitalize" }} key={item} value={item}>
                {t(item)}
              </MenuItem>
            );
          })}
          {/* <MenuItem value={"all"}>All</MenuItem>
          <MenuItem value={"online"}>Free</MenuItem>
          <MenuItem value={"away"}>Paused</MenuItem> */}
        </Select>
      </FormControl>
      <FormControl style={styles.item}>
        <Input value={searchValue} onChange={setSearchValue} type={"search"} />
        {/* <Select
          value={langFilter}
          onChange={handleChangeLanguage}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem value={"all"}>All</MenuItem>
          {languagesList.map((lang) => (
            <MenuItem value={lang} key={lang}>
              {lang}
            </MenuItem>
          ))}
        </Select> */}
      </FormControl>
    </Box>
  );
};

export default Controls;

const styles = {
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  item: {
    width: "45%",
  },
};
