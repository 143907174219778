import React, { useEffect } from "react";
import { Box } from "@material-ui/core";

import Titlebar from "src/components/Titlebar/Titlebar";
import { CallProvider } from "src/reducers/Call/CallProvider";
import BookingModal from "../BookingModal/BookingModal";
import { BasePanel } from "../BasePanel";
import { AuthContext } from "src/reducers/AuthReducer";
import { PageRouter } from "src/pages/PageRouter";
import { GroupCallProvider } from "src/providers/GroupCallProvider";
import { PopupProvider } from "src/providers/PopupProvider";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetchAllReasons } from "src/reducers/Reasons/operations";

const title = document.getElementsByTagName("title")[0];

export default function SharedLayout() {
  const [authState] = React.useContext(AuthContext);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  useEffect(() => {
    const currentLanguage = i18n.language;

    dispatch(fetchAllReasons(currentLanguage));
  }, [dispatch, i18n.language]);

  return (
    <PopupProvider>
      <CallProvider>
          <GroupCallProvider>
            <Titlebar title={title.innerText} backgroundColor="#fff" />
            <BookingModal />
            <Box
              style={{
                justifyContent: "flex-start",
                flexDirection: "row",
                width: "100%",
                height: "100%",
                display: "flex",
                flex: 1,
              }}
            >
              <Box>
                <BasePanel authState={authState} />
              </Box>
              <Box style={{ width: "100%" }}>
                <PageRouter />
              </Box>
            </Box>
          </GroupCallProvider>
      </CallProvider>
    </PopupProvider>
  );
}
