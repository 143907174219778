export const ENV_TYPE = "stage";

export const COMMENT_TYPES = {
  TRANSLATION_REQUEST: "TRANSLATION_REQUEST",
  CLIENT_CALL: "CLIENT_CALL",
  CLIENT_ENTITY: "CLIENT_ENTITY",
};

export const INTERPRETER_STATE = {
  SIGN_IN: "SIGN_IN",
  SIGN_OUT: "SIGN_OUT",
  AUTH_ERROR: "AUTH_ERROR",
};

export const URI = {
  getTranslationById: "requests/",
  getCalls: "/api/interpreter/get_calls?lang=ru",
  //   translations: "v2/translation_requests",
  translations: "/api/interpreter/requests",
  // login: '/v2/auth/login/email',
  login: "/api/interpreter/login?lang=ru",
  // logout: "/v2/auth/logout",
  logout: "/api/interpreter/logout",
  getContacts: "/v2/user",
  findContacts: "/v2/user/login",
  getContactData: "v2/user/login/edit",
  callHist: "/v2/call/history",
  getIncomingCalls: "/v2/call/initiated",
  status: "/api/v3/interpreter/status",
  //   blockClient: "/api/interpreter/block_client",
  blockClient: "/api/interpreter/block_client?lang=ru",
  getClientCalls: "/api/interpreter/get_calls",
  getGroupCalls: "/api/interpreter/group?lang=ru",
  //   getTranslationRequests: "/api/v3/interpreter/requests",
  getTranslationRequests: "/api/v3/interpreter/requests?lang=ru",
  answerCall: "/api/interpreter/answer_call",
  getInterpreters: "/api/v3/interpreter",
  note: "api/v3/interpreter/note",
  clients: "api/v3/interpreter/clients",
  chatClient: "api/interpreter/send_message",
  getReasons: "/api/interpreter/reasons",
  putReasonBySessionId: "/api/interpreter/calls",
  redirectCall: "/api/interpreter/redirect_call",
  getContactByPhoneNumber: "/api/interpreter/client/",
  makeCall: "/api/interpreter/make_call/",
  makeVideo: "/api/interpreter/make_video/",
  answerGroupWebProCall: "/api/interpreter/answer_group",
  endGroupWebProCall: "/api/interpreter/end_group",
  getPlannedGroups: "/api/interpreter/planned_group",
  stats: "/api/interpreter/stats",
  admissions: "/api/interpreter/deaf",
  newAdmissions: "/api/interpreter/deaf/new",
  getFilteredAdmissions: "/api/interpreter/deaf/FIO",
  getContactByDeafId: "/api/interpreter/client/id/",
  foxtrotDeafId: "/api/interpreter/foxtrot/",
  getEmergencyNumbers: "/api/client/emergency_numbers",
};

export const itemList = [
  {
    label: "Заблокировать",
    id: 1,
  },
  {
    label: "Сменить тему вызова",
    id: 2,
  },
];

export const dayCorrectName = [
  "день", // 1, 21, 31, 41...
  "дня", // 2-4, 22-24, 32-34, 42-44...
  "дней", // 5-20, 25-30, 35-40...
];

export const hourCorrectName = [
  "час", // 1, 21, 31, 41...
  "часа", // 2-4, 22-24, 32-34, 42-44...
  "часов", // 5-20, 25-30, 35-40...
];

export const minCorrectName = [
  "минута", // 1, 21, 31, 41...
  "минуты", // 2-4, 22-24, 32-34, 42-44...
  "минут", // 5-20, 25-30, 35-40...
];
