import { createAsyncThunk } from "@reduxjs/toolkit";

import { CallService } from "src/services/CallService";
import { CallReason, ExeptionCallReason } from "src/types/CallReason";

export const fetchAllReasons = createAsyncThunk(
  "interpreter/reasons",
  async (lang: string = "uk") => {
    try {
      const [langWithoutRegion] = lang.split("-");
      
      const { reasons }: any = await CallService.getReasons(langWithoutRegion);
      const exeptions = Object.values(ExeptionCallReason);
      const filteredReasons = reasons.filter(
        (reason: { slug: CallReason; label: string }) => !exeptions.includes(reason.slug as any)
      );

      return filteredReasons;
    } catch (error) {
      console.error("Error fetching current interpreter:", error);
    }
  }
);
