import React from "react";
import { ButtonBase, Typography } from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { PhoneAndroidOutlined } from "@mui/icons-material";

import Complete from "../../assets/translation_icon_complete.svg";
import Pending from "../../assets/translation_icon.svg";

import { Admission } from "src/types";
import Logo from "src/Logo";

export function AdmissionsList({
  items,
  onAdmissionClick,
}: {
  items: Admission[];
  onAdmissionClick: (id: number) => void;
}) {
  return Boolean(items.length) ? (
    <ul style={{}}>
      {items.map((item) => {
        return (
          <li key={item.id}>
            <AdmissionsItem onAdmissionClick={onAdmissionClick} item={item} />
          </li>
        );
      })}
    </ul>
  ) : (
    <div
      style={{
        marginTop: 32,
        display: "flex",
        alignItems: "center",
        gap: 4,
        flexDirection: "column",
      }}
    >
      <Logo />
      <Typography>Нічого немає</Typography>
    </div>
  );
}

function AdmissionsItem({
  item,
  onAdmissionClick,
}: {
  item: Admission;
  onAdmissionClick: (id: number) => void;
}) {
  const { t } = useTranslation();

  return (
    <ButtonBase
      onClick={() => {
        onAdmissionClick(item.id);
      }}
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        gap: 8,
        padding: 8,
        borderBottom: "1px solid rgb(204, 204, 204)",
      }}
    >
      <div style={{ width: 40, height: 40 }}>
        <img src={item.validated ? Complete : Pending} />
      </div>
      <div style={{ flex: 1, userSelect: "text" }}>
        <Typography variant="h6" style={{ marginBottom: 8 }}>
          {item.firstName || item.lastName || item.patronymic
            ? `${item.lastName || ""} ${item.firstName || ""} ${item.patronymic || ""}`.trim()
            : t("anonymous")}
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <PhoneAndroidOutlined />: <Typography variant="body1">{item.phone}</Typography>
          </div>
          {/* <div style={{ display: "flex", alignItems: "center" }}>
            <Numbers />: {item.serial_number}
          </div> */}
        </div>
      </div>
    </ButtonBase>
  );
}
