import { createSlice } from "@reduxjs/toolkit";
import { CallReason } from "src/types";
import { fetchAllReasons } from "./operations";

interface initialStateType {
  reasons: { slug: Partial<CallReason>; label: string }[] | null;
  userReasonSelected: CallReason | null;
  sessionIdToSubmit: string | null;
  shouldDisplayReasonForm: boolean;
}

const initialState: initialStateType = {
  reasons: null,
  userReasonSelected: null,
  sessionIdToSubmit: null,
  shouldDisplayReasonForm: false,
};

const reasonsSlice = createSlice({
  name: "reasonsSlice",
  initialState,
  reducers: {
    setUserReasonSelected(state, action) {
      state.userReasonSelected = action.payload;
    },
    resetUserReasonSelected(state) {
      state.userReasonSelected = null;
    },
    setSessionIdToSubmit(state, action) {
      state.sessionIdToSubmit = action.payload;
    },
    resetSessionIdToSubmit(state) {
      state.sessionIdToSubmit = null;
    },
    setShouldDisplayReasonForm(state, action) {
      state.shouldDisplayReasonForm = action.payload;
    },
  },
  extraReducers(builder) {
    return builder.addCase(fetchAllReasons.fulfilled, (state, action) => {
      state.reasons = action.payload;
    });
  },
});

export const {
  resetUserReasonSelected,
  setUserReasonSelected,
  resetSessionIdToSubmit,
  setSessionIdToSubmit,
  setShouldDisplayReasonForm,
} = reasonsSlice.actions;
export default reasonsSlice.reducer;
