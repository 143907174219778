import React, { useState, useEffect, CSSProperties } from "react";
import { Box, Typography, FormControlLabel, Checkbox } from "@material-ui/core";
// import LangItem from "./LangItem";
import { useTranslation } from "react-i18next";
import { Operator } from "src/types";

interface Props {
  item: Operator | null;
  operator: Operator | null;
  setOperator: (operator: Operator | null) => void;
}

const OperatorItem = ({ item, operator, setOperator }: Props) => {
  const { t } = useTranslation();
  const [isOperatorSelected, setIsOperatorSelected] = useState(false);
  // console.log("operator", operator);
  const hasSelectedOperator = operator?.id === item?.id;

  useEffect(() => {
    setIsOperatorSelected(hasSelectedOperator);
  }, [operator, hasSelectedOperator]);

  const handleSelect = () => {
    if (hasSelectedOperator) {
      setOperator(null);
    } else {
      setOperator(item);
    }
  };
  const statuses = {
    online: {
      backgroundColor: "#1CB522",
    },
    away: {
      backgroundColor: "#EFBE29",
    },
    offline: {
      backgroundColor: "#EFBE29",
    },
  };

  return item ? (
    <Box
      style={{
        ...styles.container,
        ...{
          backgroundColor: hasSelectedOperator ? "#F0F1F9" : "#FFF",
        },
      }}
    >
      <Box>
        <Box
          style={{
            ...styles.status,
            ...statuses[item.online as keyof typeof statuses],
          }}
        >
          <Typography style={styles.statusText}>{t(item.online)}</Typography>
        </Box>
        <Typography style={styles.title}>{item.name}</Typography>
        <Typography style={styles.title}>{item.email}</Typography>
        {/* {item?.languages && (
          <Box style={styles.languages}>
            {item.languages.map((lang) => (
              <LangItem text={lang} key={lang} />
            ))}
          </Box>
        )} */}
      </Box>
      <Box style={styles.checkboxWrapper}>
        <FormControlLabel
          control={
            <Checkbox onChange={handleSelect} color="primary" checked={isOperatorSelected} />
          }
          label={t("select")}
        />
      </Box>
    </Box>
  ) : null;
};

export default OperatorItem;

const styles: Record<string, CSSProperties> = {
  container: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    borderTop: "1px solid #C4C4C4",
    borderBottom: "1px solid #C4C4C4",
  },
  avatar: {
    width: 30,
    height: 30,
    marginRight: 10,
  },
  status: {
    width: 50,
    height: 16,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 99,
  },
  statuses: {
    online: {
      backgroundColor: "#1CB522",
    },
    away: {
      backgroundColor: "#EFBE29",
    },
    offline: {
      backgroundColor: "#EFBE29",
    },
  } as CSSProperties,
  statusText: {
    color: "#ffffff",
    fontSize: 10,
  },
  title: {
    fontSize: 16,
    margin: "7px 0",
  },
  languages: {
    display: "flex",
  },
  checkboxWrapper: {
    marginLeft: "auto",
  },
};
