import { createAsyncThunk } from "@reduxjs/toolkit";

import { InterpreterService } from "src/services/InterpreterService";
// import { setCurrentInterpreter } from "./InterpreterSlice";
import { AuthService } from "src/services/AuthService";
import { Operator } from "src/types";

export const fetchCurrentInterpreter = createAsyncThunk(
  "interpreter/currentInterpreter",
  async () => {
    try {
      const currentUser = AuthService.getUser();

      const currentInterpreter = (await InterpreterService.getInterpreters()).data.find(
        (interpreter: Operator) => interpreter.id === currentUser.id
      );
      return currentInterpreter;
      //   dispatch(setCurrentInterpreter(currentInterpreter));
    } catch (error) {
      console.error("Error fetching current interpreter:", error);
    }
  }
);
