export enum CallActionTypes {
  SET_INCOMING_CALLS = "SET_CALLS",
  CLEAR_INCOMING_CALLS = "CLEAR_INCOMING_CALLS",
  TAKE_CALL = "TAKE_CALL",
  END_CALL = "END_CALL",
  PREVIEW_CALL = "PREVIEW_CALL",
  DELETE_CALL = "DELETE_CALL",
  GET_CONTACTS = "GET_CONTACTS",
  CLEAR_CONTACTS = "CLEAR_CONTACTS",
  SET_CALL_STATUS = "SET_CALL_STATUS",
  GET_CALL_HISTORY = "GET_CALL_HISTORY",
  SET_SELECTED_CONTACT = "SET_SELECTED_CONTACT",
  CLEAR_CALL_HISTORY = "CLEAR_CALL_HISTORY",
  SET_OTHERS_CALLS = "SET_OTHERS_CALLS",
  SET_QUESTIONER = "SET_QUESTIONER",
  REMOVE_QUESTIONER = "REMOVE_QUESTIONER",
  SET_SELECTED_HISTORY = "SET_SELECTED_HISTORY",
  SET_OPERATOR_STATUS = "SET_OPERATOR_STATUS",
  PUSH_NEW_SOCKET_CALL = "PUSH_NEW_SOCKET_CALL",
  UPDATE_CALL_ITEM = "UPDATE_CALL_ITEM",
  CHANGE_OPERATOR_STATUS = "CHANGE_OPERATOR_STATUS",
  ADD_NEW_OPERATOR = "ADD_NEW_OPERATOR",
  UPDATE_CURRENT_CALL = "UPDATE_CURRENT_CALL",
}
