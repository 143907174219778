import React, { useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectTranslation,
  translationsGetAll,
} from "../../reducers/Translations/TranslationsSlice";
import moment from "moment";

import { TranslationToolBar } from "./TranslationToolBar";
import { useActions } from "../../hooks/useActions";
import { FilePreview } from "./FilePreview";
import { DefaultPage } from "./DefaultPage";
import { PreviewPage } from "./PreviewPage";
import { RefusalForm } from "./RefusalForm";
import { makeStyles, Box, Button, Paper, Modal } from "@material-ui/core";
import { TranslationFiles } from "./TranslationFiles";
import { TranslationButtons } from "./TranslationButtons";
import { TranslationsService } from "../../services/TranslationsService";
import toast, { Toaster } from "react-hot-toast";
import { useCallContext } from "../../reducers/Call/CallProvider";
import { applyTranslationFormat } from "../../utils/CustomDate";
import VideoRecorder from "../../components/VideoRecorder";
import { TranslationRecorder } from "./TranslationRecorder";
import { useIsOpen } from "../../hooks";

const useStyles = makeStyles({
  previewBox: {
    alignItems: "center",
    justifyContent: "space-between",
    gap: "16px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "20px 0",
  },
  toast: {
    background: "lime",
    display: "flex",
    flexDirection: "column",
    height: "max-content",
    borderRadius: "4px",
    padding: "5px 25px",
    color: "#fff",
  },
});

export const TranslationPage = () => {
  const classes = useStyles();
  const {
    refuseToTranslate,
    signForTranslation,
    setIsPressed,
    clearRecords,
    addRecords,
    selectFile,
    setCurrentTranslations,
    setCompleted,
    selectTranslation,
    addMessages,
  } = useActions();
  const {
    all,
    selectedId,
    selectedFileId,
    isPressed,
    signedForTranslation,
    refusedToTranslate,
    currentTranslations,
  } = useSelector((state) => state.translationsReducer);
  const { record, isCameraEnabled, recordToClear } = useSelector((state) => state.recordsReducer);
  const translationFilesRef = React.useRef(null);

  const { isOpen, close, open } = useIsOpen();

  const notify = () => {
    toast("Wow so easy!", {
      className: classes.toast,
      duration: 5000,
    });
  };

  const renderToast = ({ name, duration, startingAt }) => {
    const date = applyTranslationFormat(startingAt);

    return (
      <Paper className={classes.toast} elevation={5}>
        <Box>{name}</Box>
        <Box>{duration}</Box>
        <Box>{date}</Box>
      </Paper>
    );
  };

  useEffect(() => {
    if (!translationFilesRef.current) {
      return;
    }
    const element = translationFilesRef.current;
    setTimeout(() => {
      element.scrollTo({
        top: element.scrollHeight,
        behavior: "smooth",
      });
    }, 700);
  }, [translationFilesRef, currentTranslations?.messages]);

  useEffect(() => {
    // console.log('set current ==>',all.find((item) => item.id === selectedId));
    setCurrentTranslations(all.find((item) => item.id === selectedId));
    setIsPressed(false);
    signForTranslation(false);
    refuseToTranslate(false);
    setCompleted(false);
  }, [selectedId]);

  useEffect(() => {
    return () => {
      selectTranslation(null);
      setCurrentTranslations(null);
    };
  }, []);

  const handleSubmitVideo = async (form, setProgressBar, file) => {
    TranslationsService.sendRequestVideo(selectedId, form, setProgressBar);
    addMessages({
      id: moment().unix(),
      type: "video",
      content: URL.createObjectURL(file),
      thumbnail: null,
      created_at: moment().unix(),
    });
  };

  if (!selectedId) {
    return <DefaultPage />;
  }

  if (!currentTranslations) {
    return null;
  }

  return isPressed ? (
    <>
      <TranslationToolBar />
      <FilePreview />
    </>
  ) : (
    <Box className={classes.previewBox}>
      <Box position="absolute">
      </Box>
      <TranslationFiles ref={translationFilesRef} />
      <Modal open={isOpen} onClose={close}>
        <VideoRecorder onSubmit={handleSubmitVideo} close={close} />
      </Modal>
      {refusedToTranslate && <RefusalForm />}
      <TranslationButtons setIsOpen={open} />
    </Box>
  );
};
