import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Typography, makeStyles, Paper } from "@material-ui/core";
import { TranslationsService } from "../../services/TranslationsService";
import Icon from "../../assets/translation_icon.svg";
import { SearchInput } from "../SearchInput";
import { useActions } from "../../hooks/useActions";
import { Transition } from "react-transition-group";
import {
  applyTranslationFormat,
} from "../../utils/CustomDate";
import TranslationIconComplete from "../../assets/translation_icon_complete.svg";
import TranslationIconPending from "../../assets/translation_icon_pending.svg";
import { useTranslation } from "react-i18next";
import { TranslationStatus } from "../../types";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  search: {
    padding: "7px 19px",
    marginBottom: "-1px",
    width: "100%",
    borderWidth: "2px",
    borderLeftColor: "transparent",
    borderRightColor: "transparent",
  },
  paper: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      background: "#fafafa",
    },
    "&:focus": {
      background: "#f1f1f6",
    },
  },
  isActive: {
    background: "#f1f1f6",
  },
  textContent: {
    width: "100%",
    borderWidth: "1px",
    borderLeftColor: "transparent",
    borderRightColor: "transparent",
    background: "inherit",
  },
  iconCircle: {
    border: "1px solid #B9B9B9",
    borderRadius: "50%",
    width: "50px",
    height: "40px",
    margin: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    width: "20px",
    height: "20px",
  },
  requestString: {
    display: "flex",
    gap: "3px",
  },
  loadMore: {
    width: "max-content",
    alignSelf: "center",
    marginTop: "50px",
    textTransform: "uppercase",
  },
});

// const request = (quantity) => {
//   const translationsList = [];

//   for (let i = 0; i < quantity; i++) {
//     translationsList.push({
//       id: i + 1,
//       user_id: i + 1,
//       status: "created",
//       requestNumber: i + 1,
//       created_at: "01.01.2022",
//       files: [
//         {
//           file: `https://images.unsplash.com/photo-1571501679680-de32f1e7aad4?w=600&h=600`,
//         },
//         {
//           file: `https://images.unsplash.com/photo-1571501679680-de32f1e7aad4?w=600&h=600`,
//         },
//         {
//           file: `https://images.unsplash.com/photo-1571501679680-de32f1e7aad4?w=600&h=600`,
//         },
//       ],
//       // files: [
//       // 	`https://images.unsplash.com/photo-1571501679680-de32f1e7aad4?w=${i + 1}00&h=${i + 1}00`,
//       // 	`https://images.unsplash.com/photo-1571501679680-de32f1e7aad4?w=1000&h=1000`,
//       // 	`https://images.unsplash.com/photo-1571501679680-de32f1e7aad4?w=${i + 1}00&h=${i + 1}00`
//       // ],
//       records: [null, null, null],
//     });
//   }

//   return translationsList;
// };

export const TabTranslations = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { translationsGetAll, translationsGetUsers, selectTranslation } =
    useActions();
  const { all, users, selectedId, currentTranslations } = useSelector(
    (state) => state.translationsReducer
  );
  const { isCameraEnabled } = useSelector((state) => state.recordsReducer);
  const duration = 500;
  const [page] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  /* start */
  // useEffect(() => {
  // 	(async function() {
  // 		// const translationsResponse = await TranslationsService.getAllRequests();
  // 		// const usersResponse = await TranslationsService.getAllUsers(2);
  // 		// const findUser = userId => usersResponse.items.find(user => user.id === userId);
  // 		// const translationsWithUsers = translationsResponse.data.map(translation => ({
  // 		// 	...translation,
  // 		// 	user: findUser(translation.user_id)
  // 		// }));
  // 		const translationsResponse = request(10);
  // 		// const translationsWithUsers = translationsResponse.map(translation => ({
  // 		// 	...translation,
  // 		// 	user: findUser(translation.user_id)
  // 		// }));

  // 		translationsGetAll(translationsResponse);
  // 		// translationsGetAll(translationsWithUsers);
  // 		// translationsGetUsers(usersResponse);
  // 	})();
  // }, []);
  /* end */
  async function getTranslations() {
    const translationsResponse = await TranslationsService.getAllRequests();
    //   const translationsWithUsers = [];
    // console.log("translationsResponse", translationsResponse);
    // if (translationsResponse) {
    //   for (let i = 0; i < translationsResponse.requests.length; i++) {
    //     const user = await TranslationsService.getUser(translationsResponse.requests[i].user_id);
    //     const videos = translationsResponse.requests[i].translationResponse.files;
    //     // console.log(videos, 'videos');

    //     translationsWithUsers.push({
    //       ...translationsResponse.requests[i],
    //       user,
    //       records: videos ? videos : [null, null, null],
    //     });
    //   }
    // }
    //   translationsGetAll(translationsWithUsers);
    // console.log(translationsResponse);
    translationsGetAll(translationsResponse.new);
  }

  useEffect(() => {
    setIsLoading(true);
    getTranslations();
    setIsLoading(false);

    return () => translationsGetAll([]);
  }, []);

  // const search = async (query) => {
  //   // const translationsResponse = await TranslationsService.getAllRequests();
  //   // const newAll = translationsResponse.requests.filter((item) =>
  //   //   item.id.toString().includes(query)
  //   // );
  //   // translationsGetAll(newAll);
  //   const translationsResponse = await TranslationsService.getAllRequests();

  //   const filteredRequests = translationsResponse.requests.filter((request) => {
  //     return request.client_phone.includes(query);
  //   });

  //   translationsGetAll(filteredRequests);
  // };

  const defaultStyles = {
    display: "flex",
    flexDirection: "column",
    transition: `all ${duration}ms ease-in-out`,
  };

  const transitionStyles = {
    entering: {
      marginTop: "300px",
    },
    entered: {
      marginTop: "300px",
    },
    exiting: {
      marginTop: "0",
    },
    exited: {
      marginTop: "0",
    },
  };

  //   const setStatusIcon = (item) => {
  //     switch (item.status) {
  //       case "created":
  //         return TranslationIconPending;
  //       case "processing":
  //         return Icon;
  //       case "completed":
  //         return TranslationIconComplete;
  //     }
  //   };

  const setStatusIcon = (item) => {
    switch (item.status) {
      case TranslationStatus.NEW:
        return TranslationIconPending;
      case TranslationStatus.ASSIGNED:
        return Icon;
      case TranslationStatus.CLOSED:
        return TranslationIconComplete;
      default:
        return "";
    }
  };

  function getFullName(profile) {
    const lastName = profile?.last_name ?? "";
    const firstName = profile?.first_name ?? "";
    const patronymic = profile?.patronymic ?? "";

    const fullName = `${lastName} ${firstName} ${patronymic}`.trim();

    return fullName || t("translationsTab.notProvided");
  }

  const handleSearch = useCallback((value) => {
    setSearchValue(value);
  }, []);

  const visibleData = useMemo(() => {
    return all.filter((el) => el.client?.phone.includes(searchValue));
  }, [all, searchValue]);

  function TranslationItem({ item }) {
    return (
      item && (
        <Box
          className={`${classes.paper} ${
            item.id === currentTranslations?.id ? classes.isActive : ""
          }`}
          onClick={() => selectTranslation(item.id)}
          tabIndex="0"
        >
          <Box className={classes.iconCircle}>
            <Box
              component="img"
              className={classes.icon}
              alt="translation status icon"
              src={setStatusIcon(item)}
            />
          </Box>
          <Paper className={classes.textContent} variant="outlined" square>
            <Typography>{item.client.phone}</Typography>
            <Typography>{getFullName(item.client.profile)}</Typography>
            <Box className={classes.requestString}>
              <Typography color="textSecondary">{t("translationsTab.orderNumber")}</Typography>
              <Typography>{item.id}</Typography>
            </Box>
            <Typography color="textSecondary">{applyTranslationFormat(item.updated_at)}</Typography>
          </Paper>
        </Box>
      )
    );
  }

  return (
    <Transition in={isCameraEnabled} timeout={duration}>
      {(state) => (
        <Box
          style={{
            ...defaultStyles,
            ...transitionStyles[state],
          }}
        >
          <Paper variant="outlined" square className={classes.search}>
            <SearchInput handleClick={handleSearch} />
          </Paper>

          {visibleData.length ? (
            visibleData?.map((item) => <TranslationItem key={item.id} item={item} />)
          ) : (
            <Box style={{ padding: "19px" }}>
              {searchValue ? t("translationsTab.notFound") : null}
            </Box>
          )}
          {/* <Button
							variant="contained"
							color="primary"
							className={classes.loadMore}
							onClick={() => setPage(page + 1)}
						>
							{t("downloadMore")}
						</Button> */}
        </Box>
      )}
    </Transition>
  );
};
