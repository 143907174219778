import { Box, IconButton, makeStyles } from "@material-ui/core"
import React from "react"
import DeleteIcon from "../../assets/recorder/delete_icon.svg"
import DeleteIconFocus from "../../assets/recorder/delete_icon_focus.svg"
import SaveIcon from "../../assets/recorder/confirm_icon.svg"
import SaveIconFocus from "../../assets/recorder/confirm_icon_focus.svg"
import { useActions } from "../../hooks/useActions"
import { useSelector } from "react-redux"

const useStyles = makeStyles({
	saveDeleteButton: {
		background: "#333",
		opacity: "0.9",
		color: "#fff",
		width: "100%",
		height: "38px",
		display: "flex",
		justifyContent: "flex-start",
		borderRadius: "4px"
	},
	onHover: {
		"&:hover": {
			borderRadius: "0"
		}
	}
})

export const ConfirmDeleteButtons = () => {
	const classes = useStyles()
	const { callDeleteWindow, callConfirmationWindow } = useActions()
	const { confirmationWindow, deleteWindow } = useSelector((state) => state.recordsReducer)

	return (
		<Box className={classes.saveDeleteButton}>
			<IconButton onClick={() => callDeleteWindow(true)}>
				<img src={deleteWindow ? DeleteIconFocus : DeleteIcon} />
			</IconButton>
			<IconButton onClick={() => callConfirmationWindow(true)} className={classes.onHover}>
				<img src={confirmationWindow ? SaveIconFocus : SaveIcon} />
			</IconButton>
		</Box>
	)
}
