// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-param-reassign */

const assign = require('lodash/assign');
const applySdpTransform = require('./applySdpTransform.js');
const logging = require('../../helpers/log')('subscribeProcessor');

// Attempt to completely process a subscribe message. This will:
// * create an Offer
// * set the new offer as the location description
//
// If there are no issues, the Promise will resolve on completion.
// Errors during any step will result in the Promise being rejected.
//
module.exports = function subscribeProcessor({
  peerConnection,
  NativeRTCSessionDescription,
  sdpTransforms,
  simulcastStreams,
  offerOverrides,
  offerConstraints,
  replaceBaselineProfile,
}) {
  const generateErrorCallback = (message, prefix) => (
    (errorReason) => {
      logging.error(message, errorReason);

      const error = new Error(message);
      error.reason = errorReason;
      error.prefix = prefix;
      throw error;
    }
  );

  const setLocalDescription = (offer) => {
    logging.debug('subscribeProcessor: offer created', offer);

    const transformedSdp = applySdpTransform(
      sdpTransforms,
      'local',
      'offer',
      assign({ simulcastStreams, replaceBaselineProfile }, offerOverrides),
      offer.sdp
    );

    const newLocalOffer = new NativeRTCSessionDescription({
      type: offer.type,
      sdp: transformedSdp.local,
    });

    const newRemoteOffer = new NativeRTCSessionDescription({
      type: offer.type,
      sdp: transformedSdp.remote,
    });

    logging.debug('subscribeProcessor: setting local description');

    return peerConnection
      .setLocalDescription(newLocalOffer)
      .then(
        () => newRemoteOffer,
        generateErrorCallback('Error while setting LocalDescription', 'SetLocalDescription')
      );
  };

  logging.debug('subscribeProcessor: creating offer');

  return peerConnection
    .createOffer(offerConstraints)
    .then(
      setLocalDescription,
      generateErrorCallback('Error while creating Offer', 'CreateOffer')
    )
    .then(offer => ({
      sdp: offer.sdp,
      type: offer.type,
    }));
};
