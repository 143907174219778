import React from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import io from "socket.io-client";
import useSound from "use-sound";
import callSound from "../assets/groupcall.wav";
// import moment from "moment";

import {
  setCalls,
  setCurrentGroupSession,
  updateSpeakerStatusOnline,
  appendNewCall,
  updateCall,
} from "src/reducers/GroupCall/GroupCallSlice";
import { GroupTranslation } from "src/types";
import { switchPage } from "src/reducers/Pagination/PaginationSlice";
import { GroupCallService } from "src/services/GroupCallService";
import { usePopupContext } from "./PopupProvider";
import { useTranslation } from "react-i18next";
import { selectAllGroupCalls, selectCurrentGroupCall } from "src/reducers/GroupCall";
import { useCallContext } from "src/reducers/Call/CallProvider";
// import { convertTimezone } from "src/utils/convertTimezone";

export const GroupCallContext = React.createContext(null);
export const useGroupCallContext = () => React.useContext(GroupCallContext);

interface Props {
  children: React.ReactNode | null;
}

// interface InterpreterGroupMember {
//   group_id: number;
//   type: GroupCallMemberType;
//   id: null | number;
//   socket: null | any;
// }

export const GroupCallProvider = ({ children }: Props) => {
  const socket = React.useRef<typeof io | null>(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { callState }: any = useCallContext();
  const { openPopup, closePopup } = usePopupContext();
  const groupCalls: GroupTranslation[] = useSelector(selectAllGroupCalls);
  const selectedGroupCall: GroupTranslation = useSelector(selectCurrentGroupCall);
  const [playSound, exposedData] = useSound(callSound, {
    interrupt: true,
    volume: 1,
    loop: true,
  });
  const handleJoinToRoom = React.useCallback(
    async (sessionId: number) => {
      if (!sessionId) return;
      const foundedSession = handleFindSessionById({
        groupId: sessionId,
        searchArray: groupCalls,
      });

      try {
        const { api_key, session_id, token, success, message }: any =
          await GroupCallService.joinToGroupSession(sessionId);

        if (!success) {
          toast.error(message);
          return;
        }

        dispatch(setCurrentGroupSession({ ...foundedSession, api_key, token, session_id }));
      } catch (error) {
        console.error("Error joining group session:", error);
      }
    },
    [dispatch, groupCalls]
  );

  // @ts-ignore
  const handleSpeakerAwait = React.useCallback(
    ({ groupId, eventName }: { groupId: number; eventName: string }) => {
      dispatch(updateSpeakerStatusOnline(groupId));
      if (selectedGroupCall) return;

      openPopup({
        id: groupId,
        text: t("groupCall.inviteTosessionPopup", { event: eventName }),
        callback: () => {
          handleJoinToRoom(groupId);
          dispatch(switchPage(3));
          closePopup(groupId);
        },
      });
    },
    [closePopup, dispatch, handleJoinToRoom, openPopup, t, selectedGroupCall]
  );

  const handleFindSessionById = ({
    groupId,
    searchArray,
  }: {
    groupId: number;
    searchArray: GroupTranslation[];
  }) => {
    const session = searchArray.find((session) => session.id === groupId);

    return session;
  };

  React.useEffect(() => {
    socket.current = io(process.env.REACT_APP_SOCKET_URL);
    socket.current.on("connect", () => {
      console.log("socket connected");
    });
    socket.current.on(
      "interpreter-calls:connectproweb.group.call.planned",
      (payload: { call: GroupTranslation; socket: null }) => {
        console.log("interpreter-calls:connectproweb.group.call.planned: ", payload);
        // const processedData = {
        //   ...payload.call,
        //   started_at: convertTimezone(payload.call.started_at),
        //   scheduled_at: convertTimezone(payload.call.scheduled_at),
        // };
        dispatch(appendNewCall({ ...payload.call }));
      }
    );
    socket.current.on(
      "interpreter-calls:connectproweb.group.call.changed",
      (payload: GroupTranslation) => {
        console.log("interpreter-calls:connectproweb.group.call.changed", payload);
        dispatch(updateCall(payload));
      }
    );
    socket.current.on("interpreter-calls:connectproweb.group.call.soon", (payload: any) => {
      console.log("interpreter-calls:connectproweb.group.call.soon", payload);
    });
    socket.current.on("group-calls:call.started", (payload: any) => {
      console.log("group-calls:call.started", payload);
      dispatch(updateCall(payload));
    });

    GroupCallService.getPlannedGroups().then((d: any) => {
      if (!d.success) {
        return;
      } else {
        // const sortedCalls = d.calls
          // .map((event: GroupTranslation) => ({
          //   ...event,
          //   started_at: convertTimezone(event.started_at),
          //   scheduled_at: convertTimezone(event.scheduled_at),
          // }))
          // .sort((a: GroupTranslation, b: GroupTranslation) => {
          //   const scheduledA = moment(a.scheduled_at);
          //   const scheduledB = moment(b.scheduled_at);
          //   return scheduledB.diff(scheduledA);
          // });

        dispatch(setCalls([...d.calls]));
      }
    });

    return () => {
      socket.current.disconnect();
    };
  }, [dispatch]);

  React.useEffect(() => {

    if (
      groupCalls.some((el) => el.status === "new") &&
      !selectedGroupCall &&
      !callState.selectedCall.session_id
    ) {
      playSound();
    } else {
      exposedData.stop();
    }
  }, [groupCalls, selectedGroupCall, playSound, exposedData, callState.selectedCall]);

  return (
    <GroupCallContext.Provider value={null}>
      {/* <button
        onClick={() => {
          openPopup({
            id: 343434,
            text: t("groupCall.inviteTosessionPopup", { event: 'eventName' }),
            callback: () => {
              handleJoinToRoom(343434);
              dispatch(switchPage(3));
              closePopup(343434);
            },
          });
        }}
      >
        1231
      </button> */}
      {children}
    </GroupCallContext.Provider>
  );
};
