import React from "react";
import { OTSubscriber } from "opentok-react";
import { Warning, AvTimer } from "@material-ui/icons";

import { checkOpentokRole } from "src/utils/checkOpentokRole";

import CamOnIcon from "../../assets/camOnIcon.svg";
import CamOffIcon from "../../assets/camOffIcon.svg";
import AudioOnIcon from "../../assets/sound-off-icon.svg";
import AudioOffIcon from "../../assets/volumeIconOff.svg";
import DeclineBtn from "../../assets/declineButton.svg";
import NoUserIcon from "../../assets/no-user-icon.svg";
import { fullHeight } from "src/utils/constants";
import CountdownTimer from "src/utils/CountdownTimer";
import { CallTitleInfo } from "./CallTitleInfo";

interface Props {
  subscriber: any;
  setSubscribersToRender: any;
  sessionRef: any;
//   isGroupCall: boolean;
  isCountdownStart: boolean;
  setIsCountdownStart: any;
  countdownTime: any;
  selectedCall: any;
  handleDeclineCall: any;
}

export function Subscriber({
  subscriber,
  setSubscribersToRender,
  sessionRef,
//   isGroupCall,
  isCountdownStart,
  setIsCountdownStart,
  countdownTime,
  selectedCall,
  handleDeclineCall,
}: Props) {
  //   console.log("subscriber", subscriber);

  const hasRole = React.useCallback(checkOpentokRole, []);

  const isSpeaker = hasRole(subscriber.stream.connection.data, "speaker");

  //   const isTwoSubscribers = filteredSubscribers.length === 2;

  const userAudioHandler = () => {
    setSubscribersToRender((prevState: any) => {
      return prevState.map((el: any) =>
        el.stream.id === subscriber.stream.id
          ? {
              ...el,
              isAudioOn: !el.isAudioOn,
            }
          : el
      );
    });

    sessionRef.current.sessionHelper.session.signal({
      data: subscriber.stream.id,
      type: subscriber.isAudioOn ? "blockMicro" : "unblockMicro",
    });
  };

  const userVideoHandler = () => {
    setSubscribersToRender((prevState: any) => {
      return prevState.map((el: any) =>
        el.stream.id === subscriber.stream.id
          ? {
              ...el,
              isCameraOn: !el.isCameraOn,
            }
          : el
      );
    });

    sessionRef.current.sessionHelper.session.signal({
      data: subscriber.stream.id,
      type: subscriber.isCameraOn ? "blockCamera" : "unblockCamera",
    });

    // if (subscriber.isCameraOn && !subscriber.isAmsaanPro && isGroupCall) {
    //   sessionRef.current.sessionHelper.session.signal({
    //     data: subscriber.stream.id,
    //     type: "removeSelectedUser",
    //   });
    // }

    // setIsOffSpeaker(subscriber.isCameraOn && isGroupCall && subscriber.isAmsaanPro);
  };

  if (isSpeaker) {
    return (
      <div
        style={{
          position: "absolute",
          width: 120,
          height: 120,
          top: 44,
          right: 54,
          zIndex: 100,
          borderRadius: "50%",
          overflow: "hidden",
        }}
      >
        <OTSubscriber
        //   eventHandlers={{
        //     disconnected: (event) => {
        //       console.log("disconnected", event);
        //       // setSubscribersToRender((prev) =>
        //       //   prev.filter((el) => el.stream.id !== event.target.stream.id)
        //       // );
        //       // sessionRef.current.sessionHelper.session.unsubscribe(
        //       //   event.target.stream.connection
        //       // );
        //     },
        //     connected: (event) => {
        //       console.log("connected", event);
        //     },
        //   }}
          key={subscriber.stream.id}
          stream={subscriber.stream}
          session={sessionRef.current.sessionHelper.session}
          properties={{
            width: 120,
            height: 120,
            fitMode: "cover",
            // subscribeToAudio: subscriber.isAudioOn,
            subscribeToAudio: true,
            style: { buttonDisplayMode: "off" },
          }}
        />
        <div
          style={{
            position: "absolute",
            bottom: 4,
            left: "50%",
            transform: "translate(-50%,0)",
            zIndex: 100,
            display: "flex",
            gap: 4,
          }}
        >
          <button
            onClick={userAudioHandler}
            style={{
              backgroundColor: "transparent",
              border: "none",
              cursor: "pointer",
              padding: 0,
            }}
          >
            <img src={subscriber.isAudioOn ? AudioOffIcon : AudioOnIcon} alt="Audio" />
          </button>

          <button
            onClick={userVideoHandler}
            style={{
              backgroundColor: "transparent",
              border: "none",
              cursor: "pointer",
              padding: 0,
            }}
          >
            <img src={subscriber.isCameraOn ? CamOnIcon : CamOffIcon} alt="Audio" />
          </button>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        width: "100%",
        height: fullHeight,
        position: "relative",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#000",
      }}
      key={subscriber.stream.id}
    >
      <button
        disabled={isCountdownStart}
        onClick={() => {
          setIsCountdownStart(true);
        }}
        style={{
          display: "flex",
          alignItems: "center",
          gap: 4,
          position: "absolute",
          top: 16,
          left: 16,
          borderRadius: 12,
          border: "1px solid",
          borderColor: "orange",
          color: "orange",
          backgroundColor: "#fff",
          padding: 8,
          zIndex: 200,
          cursor: isCountdownStart ? "auto" : "pointer",
        }}
      >
        {isCountdownStart ? <Warning /> : <AvTimer />}
        {isCountdownStart ? (
          <CountdownTimer
            countdownTime={countdownTime}
            options={{ minutes: true, seconds: true }}
          />
        ) : null}
      </button>
      <div style={{ position: "absolute", top: 16, zIndex: 200 }}>
        <CallTitleInfo sessionData={selectedCall} />
      </div>
      {subscriber.isCameraOn ? (
        <>
          <OTSubscriber
            // onSubscribe={() => {
            //   //canStartCall &&
            //   // sessionRef?.current?.sessionHelper.session.subscribe(subscriber);
            //   try {
            //     if (canStartCall) {
            //       sessionRef.current.sessionHelper.session.subscribe(subscriber);
            //     }
            //   } catch (error) {
            //     console.error("Error subscribing:", error);
            //   }
            // }}
            eventHandlers={{
              disconnected: (event) => {
                console.log("disconnected", event);
                // setSubscribersToRender((prev) =>
                //   prev.filter((el) => el.stream.id !== event.target.streamId)
                // );
                // sessionRef.current.sessionHelper.session.unsubscribe(
                //   event.target.stream.connection
                // );
              },
              connected: (event) => {
                console.log("connected", event);
              },
            }}
            key={subscriber.stream.id}
            stream={subscriber.stream}
            session={sessionRef?.current?.sessionHelper?.session}
            //@ts-ignore
            style={{
              width: "100%",
              height: "100%",
            }}
            properties={{
              width: "100%",
              fitMode: "contain",
              height: fullHeight,
              subscribeToAudio: subscriber.isAudioOn,
              style: { buttonDisplayMode: "off" },
            }}
          />
        </>
      ) : (
        <div
          style={{
            width: "100%",
            maxWidth: 600,
            height: fullHeight,
            backgroundColor: "#000",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={NoUserIcon}
            alt="no user"
            style={{
              width: "20%",
              height: "20%",
              minHeight: 75,
              minWidth: 75,
            }}
          />
        </div>
      )}

      <div
        style={{
          position: "absolute",
          bottom: 15,
          left: 0,
          zIndex: 100,
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <button
          onClick={userAudioHandler}
          style={{
            backgroundColor: "transparent",
            border: "none",
            cursor: "pointer",
            margin: "0 10px",
          }}
        >
          <img src={subscriber.isAudioOn ? AudioOffIcon : AudioOnIcon} alt="Audio" />
        </button>

        <button
          onClick={userVideoHandler}
          style={{
            backgroundColor: "transparent",
            border: "none",
            cursor: "pointer",
            margin: "0 10px",
          }}
        >
          <img src={subscriber.isCameraOn ? CamOnIcon : CamOffIcon} alt="Audio" />
        </button>

        <button
          onClick={handleDeclineCall}
          style={{
            backgroundColor: "transparent",
            border: "none",
            cursor: "pointer",
            margin: "0 10px",
          }}
        >
          <img src={DeclineBtn} alt="Decline" />
        </button>
      </div>
    </div>
  );
}
