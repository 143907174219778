import React, { useContext } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Logo from '../../Logo';
import SignInForm from './SignInForm';
import { useTranslation } from 'react-i18next';

export default function SignIn() {
	const classes = useStyles();
	const { t } = useTranslation();
	return (
		<div className={classes.main}>
			<Container component="main" maxWidth="xs">
				<div className={classes.paper}>
					<div className={classes.avatar}>
						<Logo />
					</div>
					<Typography component="h1" variant="h5">
						{t('auth.auth')}
					</Typography>
					<SignInForm />
				</div>
				<Box mt={3}>
					<Copyright />
				</Box>
			</Container>
		</div>
	);
}

function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{'Copyright © '}
			<Link color="inherit" target={'_black'} href="https://tdl.com.ua/">
				Connect Operator
			</Link>{' '}
			{new Date().getFullYear()}.
		</Typography>
	);
}

const useStyles = makeStyles((theme) => ({
	main: {
		display: 'flex',
		flexWrap: 'wrap',
		position: 'absolute',
		margin: 'auto',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		justifyContent: 'center',
		alignItems: 'center',
	},
	paper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(3),
	},
}));
