import { makeStyles, Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
// import AddTranslationIcon from "../../assets/add_translation.svg";
import { useSelector } from "react-redux";
import { useActions } from "../../hooks/useActions";
// import { HoveredFile } from "./HoveredFile";
// import DeleteIcon from "../../assets/recorder/delete_icon.svg";
// import { t } from "i18next";
import { AuthorType, MessagesType } from "../../types";
import { TranslationsService } from "../../services/TranslationsService";
// import { io } from "socket.io-client";
// import { RequestService } from "../../services/RequestService";
import { formatTime } from "../../utils/CustomDate";
import Loader from "src/components/Loader";
import { getIdWithExtensionFromUrl } from "src/utils/getIdWithExtensionFromUrl";
// import VideoRecorder from "../../components/VideoRecorder";
// import { useIsOpen } from "../../hooks";
// import { TranslationRecorder } from "./TranslationRecorder";

const useStyles = makeStyles({
  previewfilesBox: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "10px",
    maxHeight: "75vh",
    overflowY: "auto",
    padding: 8,
  },
  translationBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "20px",
  },
  recordBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "350px",
    width: "400px",
  },
  translationFile: {
    height: "100%",
    // height: "600px",
    // width: "500px",
    // objectFit: "contain",

    // maxHeight: '800px'
  },
  deleteButton: {
    background: "#333",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "-1px",
    borderRadius: "0",
    "&:hover": {
      background: "#444",
    },
  },
  messageBlock: {
    display: "flex",
    flexDirection: "column",
    // padding: 8,
    // overflow: "hidden",
    maxHeight: "600px",
    borderRadius: 8,
    border: "1px solid #7dabd8",
    flexBasis: "70%",
    backgroundColor: "#bedfffc2",
    "& img, video": {
      maxHeight: "500px",
      // borderRadius: 8,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
    },
  },
  client: {
    alignSelf: "start",
  },
  interpreter: {
    alignSelf: "end",
    backgroundColor: "white",
  },
  text: {
    padding: 4,
    width: "70%",
  },
  timeChip: {
    // borderTop:"1px solid red"
    textAlign: "end",
    padding: 4,
    color: "#7dabd8",
  },
});

export const TranslationFiles = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const { signedForTranslation, currentTranslations, completed, all, selectedId } = useSelector(
    (state) => state.translationsReducer
  );
  const {
    selectFile,
    toggleCamera,
    setIsPressed,
    clearRecords,
    setCurrentTranslations,
    setInitialMessages,
  } = useActions();

  // const socketRef = useRef(null);
  // const [submittedVideo, setSubmittedVideo] = useState(null)
  // const { isOpen, close } = useIsOpen();
  // const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // const [messages, setMessages] = useState([]);
  // console.log('current ===>>',currentTranslations);
  // console.log("current iss loading ===>>", isLoading);

  const getMessages = async () => {
    await TranslationsService.getRequestMessages(selectedId).then((data) => {
      // console.log(data);
      // setMessages(data.messages);
      // TODO push messages
      setInitialMessages(data.messages);
      //setCurrentTranslations({ ...currentTranslations, messages: data.messages });
      // return data;
    });
    // .finally(() => {
    //   setIsLoading(false);
    // });
    // return response;
  };

  useEffect(() => {
    setIsLoading(true);
    getMessages().then(() => {
      setIsLoading(false);
    });
    // socketRef.current = io(process.env.REACT_APP_SOCKET_URL);
    // socketRef.current.on("error", console.log);
    // socketRef.current.on("interpreter-requests:message.new", (message) => {
    //   console.log("interpreter-requests:message.new", message);
    //   // setMessages((prev) => [message, ...prev]);
    //   //   let messages = this.state.messages;
    //   //   messages.push(message);
    //   //   this.setState({ messages });
    // });

    // socketRef.current.on("interpreter-requests:request.update", (message) => {
    //   if (message.id === this.props.request.id) {
    //     if (
    //       message.status === TranslationStatus.ASSIGNED &&
    //       message.interpreter_id === this.interpreterId
    //     ) {
    //       this.setState({ assigned: true });
    //     }
    //   }
    // });
    // console.log(socketRef);
  }, [selectedId]);

  const handleRecord = (i) => {
    selectFile(i);
    toggleCamera(true);
    clearRecords(null);
  };
  const openFile = (i) => {
    selectFile(i);
    setIsPressed(true);
  };
  const adjustSource = (source) => {
    if (source === null) {
      return source;
    }

    if (typeof source === "string") {
      return `https://dev.api.soc.business/v2/filesystem${source}`;
    }

    if (typeof source === "object") {
      return URL.createObjectURL(source);
    }
  };

  //console.log(currentTranslations, "currentTranslations=====>");

  // const isCompleted = currentTranslations.status === TranslationStatus.CLOSED || completed;
  // const inProgress =
  //   currentTranslations.status === TranslationStatus.ASSIGNED || signedForTranslation;

  // console.log("completed", currentTranslations.status === "completed")
  // console.log("in progress", inProgress)

  const MessageContainer = ({ message }) => {
    const { type, content, author_type, created_at } = message;

    let DOMNode = null;

    switch (type) {
      case MessagesType.IMAGE:
      case MessagesType.FILE:
        // DOMNode = (
        //   <a target="new_blank" rel="noreferrer" href={content}>
        //     <img src={content} alt="translation file" className={classes.translationFile} />
        //   </a>
        // );
        DOMNode = (
          <img
            onClick={() => {
              window.open("/image/" + getIdWithExtensionFromUrl(content));
            }}
            src={content}
            alt="translation file"
            className={classes.translationFile}
          />
        );
        break;
      case MessagesType.TEXT:
        DOMNode = <p className={classes.text}>{content}</p>;
        break;
      case MessagesType.VIDEO:
        DOMNode = <video style={{ width: 500, height: 300 }} controls src={content} />;
        break;
      default:
        // DOMNode = (
        //   <a target="new_blank" rel="noreferrer" href={message}>
        //     <img src={message} alt="translation file" className={classes.translationFile} />
        //   </a>
        // );
        break;
    }

    const className = `${classes.messageBlock} ${
      author_type === AuthorType.CLIENT ? classes.client : classes.interpreter
    }`;
    const isText = type === MessagesType.TEXT ? { width: "70%" } : null;

    return (
      <div className={`${className} ${isText ? classes.text : ""}`}>
        {DOMNode}
        {created_at ? <p className={classes.timeChip}>{formatTime(created_at)}</p> : null}
      </div>
    );
  };

  // const handleFormSubmit = (formData) => {
  //   const submittedVideo = formData.get('video');
  //   setSubmittedVideo(submittedVideo);

  // };
  // console.log(currentTranslations);
  return (
    <Box ref={ref} className={classes.previewfilesBox}>
      {isLoading ? (
        <Loader />
      ) : (
        currentTranslations.messages.map((message) => {
          return <MessageContainer key={message.id} message={message} />;
        })
      )}

      {/* <Box className={classes.recordBox}>
        {(inProgress || isCompleted) &&
          // (!currentTranslations.records[i] ? (
          (!currentTranslations ? (
            !isCompleted && (
              <>
                <IconButton onClick={() => handleRecord(1)}>
                  <img
                    src={AddTranslationIcon}
                    alt="Add Translation Icon"
                    style={{
                      width: "35px",
                      height: "35px",
                    }}
                  />
                </IconButton>
                <Typography color="textSecondary">Додати переклад</Typography>
              </>
            )
          ) : (
            <Box>
              <video
                height="100%"
                width="100%"
                type="video/mp4"
                src={adjustSource(currentTranslations.records[1])}
                controls
              />
              {!isCompleted && (
                <IconButton className={classes.deleteButton} onClick={() => clearRecords(1)}>
                  <img src={DeleteIcon} />
                  <Typography
                    style={{
                      color: "#fff",
                      fontWeight: "400",
                      fontSize: "13px",
                    }}
                  >
                    Видалити
                  </Typography>
                </IconButton>
              )}
            </Box>
          ))}
      </Box> */}
    </Box>
  );
});

// {
//   messages.map((message, i) => (
//     <Box key={i} className={classes.translationBox}>
//       <Box position="relative" display="flex" justifyContent="center" alignItems="center">
// <a target="_blank" rel="noreferrer" href={message.content}>
//   <img
//     // src={`https://dev.api.soc.business/v2/filesystem${file.file}`}
//     src={message.content}
//     alt="translation file"
//     className={classes.translationFile}
//   />
// </a>
//         {/* <HoveredFile i={i} openFile={openFile} /> */}
//       </Box>
//       {/* {console.log('show videos', currentTranslations.records[i])} */}
//   {/* <Box className={classes.recordBox}>
// 	{(inProgress || isCompleted) &&
// 		(!currentTranslations.records[i] ? (
// 			!isCompleted && (
// 				<>
// 					<IconButton onClick={() => handleRecord(i)}>
// 						<img
// 							src={AddTranslationIcon}
// 							alt="Add Translation Icon"
// 							style={{
// 								width: "35px",
// 								height: "35px"
// 							}}
// 						/>
// 					</IconButton>
// 					<Typography color="textSecondary">Додати переклад</Typography>
// 				</>
// 			)
// 		) : (
// 			<Box>
// 				<video
// 					height="100%"
// 					width="100%"
// 					type="video/mp4"
// 					src={adjustSource(currentTranslations.records[i])}
// 					controls
// 				/>
// 				{!isCompleted && (
// 					<IconButton className={classes.deleteButton} onClick={() => clearRecords(i)}>
// 						<img src={DeleteIcon} />
// 						<Typography
// 							style={{
// 								color: "#fff",
// 								fontWeight: "400",
// 								fontSize: "13px"
// 							}}
// 						>
// 							Видалити
// 						</Typography>
// 					</IconButton>
// 				)}
// 			</Box>
// 		))}
// </Box> */}
//     </Box>
//   ));
// }
