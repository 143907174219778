import { Store, configureStore } from "@reduxjs/toolkit";

import translationsReducer from "./Translations/TranslationsSlice";
import paginationReducer from "./Pagination/PaginationSlice";
import recordsReducer from "./Records/RecordsSlice";
import fileReducer from "./Translations/FileSlice";
import groupCallReducer from "./GroupCall/GroupCallSlice";
import { interpreterSlice } from "./Interpreter";
import { reasonsSlice } from "./Reasons";
import { admissionsSlice } from "./Admissions";

export const store: Store = configureStore({
  reducer: {
    translationsReducer,
    paginationReducer,
    recordsReducer,
    fileReducer,
    groupCallReducer,
    interpreterSlice,
    reasonsSlice,
    admissionsSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
