import { URI } from "../constants/defaultConstants";
import { RequestService } from "./RequestService";
import { AuthService } from "./AuthService";

export const InterpreterService = {
  async handleActivity(status, cb) {
    try {
      const response = await RequestService({
        data: {
          status,
        },
        headers: {
          "X-Interpreter-Token": AuthService.getUser()?.token,
          "Content-Type": "application/json",
        },
      }).postRequest(URI.status);
      // cb(status);
      return response;
    } catch (error) {
      console.error("HANDLE ACTIVITY ERROR: ", error);
      throw error;
    }
  },
  async getInterpreters() {
    try {
      const response = await RequestService({
        data: {},
        headers: {
          "X-Interpreter-Token": AuthService.getUser()?.token,
          "Content-Type": "application/json",
        },
      }).getRequest(URI.getInterpreters);

      return response;
    } catch (error) {
      console.error("getInterpreters ERROR: ", error);
      throw error;
    }
  },
  async redirectToInterpreterById({ session_id, interpreter_id }) {
    try {
      const response = await RequestService({
        data: { session_id, interpreter_id },
        headers: {
          "X-Interpreter-Token": AuthService.getUser()?.token,
          "Content-Type": "application/json",
        },
      }).postRequest(URI.redirectCall);

      return response
    } catch (error) {
      console.error("redirectToInterpreterById ERROR: ", error);
      throw error;
    }
  },
  getUser() {
    return JSON.parse(localStorage.getItem("@user"));
  },
};
