import { makeStyles, Box } from "@material-ui/core"
import React from "react"
import { TranslationFiles } from "./TranslationFiles"
import { useSelector } from "react-redux"
import { TranslationButtons } from "./TranslationButtons"
import { TranslationToolBar } from "./TranslationToolBar"

const useStyles = makeStyles({
	previewBox: {
		alignItems: "center",
		justifyContent: "space-between",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		padding: "20px 0"
	}
})

export const PreviewPage = ({ translations }) => {
	const classes = useStyles()
	const { signedForTranslation, isPressed } = useSelector((state) => state.translationsReducer)

	return !isPressed ? (
		<Box className={classes.previewBox}>
			<TranslationFiles translations={translations} />
			{!signedForTranslation && <TranslationButtons />}
		</Box>
	) : (
		<>
			<TranslationToolBar />
			{/* <FilePreview translations={translations} /> */}
		</>
	)
}
