import React from "react"
import { Container, Grid } from "@material-ui/core"
import { InterpreterPanel } from "./InterpreterPanel"
import { TabsPanel } from "./TabsPanel"
import { CallProvider } from "../reducers/Call/CallProvider"
import { NewTabsPanel } from "./NewTabsPanel"

export const BasePanel = ({ authState }) => {
	return (
		<Grid item container xs={3} direction="column">
			<InterpreterPanel authState={authState} />
			{/* <CallProvider> */}
			<TabsPanel />
			{/* <NewTabsPanel /> */}
			{/* </CallProvider> */}
		</Grid>
	)
}
