import { Box, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
	timer: {
		width: '100px',
		// height: '25px',
		// background: '#fff',
		background: 'transparent',
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	typography: {
		fontSize: '13px',
		fontWeight: '400',
		color: '#fff',
	},
});

export const Timer = ({ start, pause = false }) => {
	const classes = useStyles();
	const [seconds, setSeconds] = useState(0);
	const [minutes, setMinutes] = useState(0);
	const { t } = useTranslation();

	useEffect(() => {
		let timer;

		// console.log(pause, 'pause', start, 'start');

		if (!pause) {
			// if (!start) {
			// 	if (seconds !== 0 && minutes !== 0) {
			// 		setSeconds(0);
			// 		setMinutes(0);
			// 	}
			// }

			timer = setTimeout(() => {
				setSeconds(seconds + 1);
				if (seconds === 60) {
					setSeconds(0);
					setMinutes(minutes + 1);
				}
			}, 1000);
		}

		return () => clearTimeout(timer);
	});

	if (!start) {
		if (!pause) {
			return (
				<Box className={classes.timer}>
					<Typography className={classes.typography}>
						{t('translationsTab.start')}
					</Typography>
				</Box>
			);
		}

		if (seconds !== 0 && minutes !== 0) {
			setSeconds(0);
			setMinutes(0);
		}
	}

	return (
		<Box className={classes.timer} style={{ marginLeft: '-1px' }}>
			<Typography className={classes.typography}>
				{minutes < 10 && '0'}
				{minutes}
				{':'}
			</Typography>
			<Typography className={classes.typography}>
				{seconds < 10 && '0'}
				{seconds}
			</Typography>
		</Box>
	);
};
