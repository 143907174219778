// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable global-require, no-underscore-dangle, no-param-reassign, no-void, no-shadow */
/* eslint-disable func-names */

const assign = require('lodash/assign');
const eventing = require('../../helpers/eventing');

module.exports = function SubscriberPeerConnectionFactory(deps = {}) {
  const OTHelpers = deps.OTHelpers || require('../../common-js-helpers/OTHelpers.js');
  const PeerConnection = deps.PeerConnection || require('./peer_connection.js')();
  const setCertificates = deps.setCertificates || require('./set_certificates.js')();
  const Errors = deps.Errors || require('../Errors.js');
  const ExceptionCodes = deps.ExceptionCodes || require('../exception_codes.js');
  const OTErrorClass = deps.OTErrorClass || require('../ot_error_class.js');
  const watchSubscriberAudio = deps.watchSubscriberAudio || require('./watchSubscriberAudio.js');

  /*
   * Abstracts PeerConnection related stuff away from Subscriber.
   *
   * Responsible for:
   * * setting up the underlying PeerConnection (delegates to PeerConnections)
   * * triggering a connected event when the Peer connection is opened
   * * triggering a disconnected event when the Peer connection is closed
   * * creating a video element when a stream is added
   * * responding to stream removed intelligently
   * * providing a destroy method
   * * providing a processMessage method
   *
   * Once the PeerConnection is connected and the video element playing it
   * triggers the connected event
   *
   * Triggers the following events
   * * connected
   * * disconnected
   * * remoteStreamAdded
   * * remoteStreamRemoved
   * * error
   *
   */

  return function SubscriberPeerConnection({
    clientCandidates,
    iceConfig,
    send,
    logAnalyticsEvent,
    p2p,
    codecFlags,
  }) {
    const _subscriberPeerConnection = this;
    let _peerConnection;
    let _destroyed = false;
    let _awaitingIceRestart = false;
    let _subscriberAudioWatcher = null;

    // Private
    const _onPeerClosed = function () {
      this.destroy();
      if (_awaitingIceRestart) {
        this.trigger('iceRestartFailure', this);
      }
      this.trigger('disconnected', this);
    };

    const _onRemoteStreamAdded = function (remoteRTCStream) {
      this.trigger('remoteStreamAdded', remoteRTCStream, this);
    };

    const _onRemoteStreamRemoved = function (remoteRTCStream) {
      this.trigger('remoteStreamRemoved', remoteRTCStream, this);
    };

    const _onRemoteVideoSupported = (supported) => {
      this.trigger('remoteVideoSupported', supported);
    };

    // Note: All Peer errors are fatal right now.
    const _onPeerError = function ({ reason, prefix }) {
      this.trigger('error', null, reason, this, prefix);
    };

    const _onIceConnectionStateChange = function (state) {
      if (_awaitingIceRestart && (state === 'connected' || state === 'completed')) {
        _awaitingIceRestart = false;
        this.trigger('iceRestartSuccess');
      }
      this.trigger('iceConnectionStateChange', state);
    };

    const _onsignalingStateChange = function (state) {
      this.trigger('signalingStateChange', state);
    };

    const _onsignalingStateStable = function (state) {
      this.trigger('signalingStateStable', state);
    };

    const _relayMessageToPeer = (type, content) => {
      if (type === 'answer' || type === 'pranswer') {
        this.trigger('connected');
      }
      send(type, content);
    };

    eventing(this);

    // Public

    this.close = function () {
      if (_destroyed) { return; }

      _destroyed = true;

      if (_peerConnection) {
        _peerConnection.disconnect();
        _peerConnection = null;
      }

      this.off();
    };

    this.destroy = function () {
      this.stopAudioStatsWatcher();
      if (_destroyed) { return; }

      this.close();
    };

    this.getDataChannel = function (label, options, completion) {
      _peerConnection.getDataChannel(label, options, completion);
    };

    this.processMessage = function (type, message) {
      _peerConnection.processMessage(type, message);
    };

    this.remoteDescription = function () {
      return _peerConnection.remoteDescription();
    };

    this.getStats = function (callback) {
      if (_peerConnection) {
        _peerConnection.getStats(callback);
      } else {
        const errorCode = ExceptionCodes.PEER_CONNECTION_NOT_CONNECTED;
        callback(new OTHelpers.Error(OTErrorClass.getTitleByCode(errorCode),
          Errors.PEER_CONNECTION_NOT_CONNECTED, {
            code: errorCode,
          }));
      }
    };

    this.getRtcStatsReport = function (callback) {
      if (_peerConnection) {
        _peerConnection.getRtcStatsReport(callback);
      } else {
        const errorCode = ExceptionCodes.PEER_CONNECTION_NOT_CONNECTED;
        callback(new OTHelpers.Error(OTErrorClass.getTitleByCode(errorCode),
          Errors.PEER_CONNECTION_NOT_CONNECTED, {
            code: errorCode,
          }));
      }
    };

    this.startAudioStatsWatcher = function (disableAudioLevelStuckAt0) {
      if (!_subscriberAudioWatcher) {
        _subscriberAudioWatcher = watchSubscriberAudio(
          _peerConnection.getStats.bind(_peerConnection),
          (reason) => {
            this.stopAudioStatsWatcher();
            this.trigger('audioLevelStuckWarning', reason);
          },
          disableAudioLevelStuckAt0
        );
      }
    };

    this.stopAudioStatsWatcher = function () {
      if (_subscriberAudioWatcher) {
        _subscriberAudioWatcher.stop();
      }
      _subscriberAudioWatcher = null;
    };

    // Helper method used by subscribeToAudio/subscribeToVideo
    const _createSetEnabledForTracks = function (kind) {
      return function (enabled) {
        if (!_peerConnection) {
          // We haven't created the peer connection yet, so there are no remote streams right now.
          // Subscriber will try again after onRemoteStreamAdded so this works out ok.
          return;
        }

        _peerConnection.remoteTracks().forEach((track) => {
          if (track.kind === kind && track.enabled !== enabled) {
            track.enabled = enabled;
          }
        });
      };
    };

    this.subscribeToAudio = _createSetEnabledForTracks('audio');
    this.subscribeToVideo = _createSetEnabledForTracks('video');

    this.hasRelayCandidates = function () {
      return _peerConnection.hasRelayCandidates();
    };

    this.iceRestart = function () {
      _awaitingIceRestart = true;
      return _peerConnection.iceRestart();
    };

    this.iceConnectionStateIsConnected = function () {
      return _peerConnection.iceConnectionStateIsConnected();
    };

    // Init
    this.init = function (completion) {
      const pcConfig = { iceConfig };

      setCertificates(pcConfig, (err, pcConfigWithCerts) => {
        if (err) {
          completion(err);
          return;
        }

        const peerConnectionConfig = assign(
          {
            logAnalyticsEvent,
            clientCandidates,
            codecFlags,
          },
          pcConfigWithCerts
        );

        _peerConnection = new PeerConnection(
          assign({ sendMessage: _relayMessageToPeer, p2p }, peerConnectionConfig)
        );

        _peerConnection.on({
          iceConnected: () => _subscriberPeerConnection.emit('iceConnected'),
          close: _onPeerClosed,
          streamAdded: _onRemoteStreamAdded,
          streamRemoved: _onRemoteStreamRemoved,
          signalingStateChange: _onsignalingStateChange,
          signalingStateStable: _onsignalingStateStable,
          error: _onPeerError,
          qos: qos => this.trigger('qos', qos),
          iceConnectionStateChange: _onIceConnectionStateChange,
          remoteVideoSupported: _onRemoteVideoSupported,
        }, _subscriberPeerConnection);

        // If there are already remoteStreams, add them immediately
        // (Using .remoteTracks to avoid deprecated .remoteStreams where possible.
        // FIXME: Is this even possible anyway? How could we already have remote streams in the same
        // tick the peer connection was created?)
        if (_peerConnection.remoteTracks().length > 0) {
          // @todo i really don't think this branch is ever entered, it might be an artifact of the
          // unit tests
          // @todo ahh maybe reconnections?
          _peerConnection.remoteStreams().forEach(_onRemoteStreamAdded, _subscriberPeerConnection);
        } else {
          completion(undefined, _subscriberPeerConnection);
        }
      });
    };
  };
};
