/* eslint-disable no-underscore-dangle */

const now = require('../now');

/*
 * A <code>RTCPeerConnection.getStats</code> based audio level sampler.
 *
 * It uses the <code>getStats</code> method to get the <code>audioOutputLevel</code>.
 * This implementation expects the single parameter version of the <code>getStats</code> method.
 *
 * Currently the <code>audioOutputLevel</code> stats is only supported in Chrome.
 *
 * @param {function} getStatsFn the "getStats" function
 * @constructor
 */

module.exports = class GetStatsAudioLevelSampler {
  _lastCalled = now();
  _audioLevel = undefined;
  _running = false;
  constructor(getStats, { requestAnimationFrame = ::window.requestAnimationFrame } = {}) {
    this._requestAnimationFrame = requestAnimationFrame;
    this._getStats = getStats;
    this._startLoop();
  }
  _startLoop() {
    if (this._running) {
      return;
    }

    this._running = true;

    this._requestAnimationFrame(::this._loop);
  }
  _loop() {
    this._getStats((error, stats) => {
      // @todo we should measure how long the getStats so that we can determine
      // our impact on the render loop, and skip frames if need to reduce our
      // CPU utilization


      // Stat entries without audio can have audioLevel 0 so we just look for the max level
      let maxLevel = null;


      if (!error) {
        for (let idx = 0; idx < stats.length; idx += 1) {
          const stat = stats[idx];

          let level = null;

          if (stat.audioOutputLevel !== undefined) {
            // the max value delivered by getStats via audioOutputLevel is 2^15
            const audioOutputLevel = parseFloat(stat.audioOutputLevel) / 32768;

            if (!isNaN(audioOutputLevel)) {
              level = audioOutputLevel;
            }
          } else if (stat.audioLevel !== undefined) {
            level = stat.audioLevel;
          }

          if (level !== null && (maxLevel === null || level > maxLevel)) {
            maxLevel = level;
          }
        }
      }

      this._audioLevel = maxLevel;

      if (this._running && now() - this._lastCalled < 10000) {
        this._requestAnimationFrame(::this._loop);
      } else {
        this._running = false;
      }
    });
  }
  destroy() {
    this._running = false;
  }
  sample(done) {
    if (typeof done === 'function') {
      throw new Error('sample no longer takes a callback');
    }

    this._startLoop();

    this._lastCalled = now();
    return this._audioLevel;
  }
};
