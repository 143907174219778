import React, { Suspense } from "react";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { Route, Routes } from "react-router-dom";
import NoSleep from "@uriopass/nosleep.js";

import SharedLayout from "src/components/SharedLayout/SharedLayout";
import { RoutesMap } from "src/types";

import { ImagePlayground } from "src/pages/ImagePlayground";
import SignIn from "src/pages/signin/SignIn";
import PublicRoute from "src/routes/PublicRoute";
import PrivateRoute from "src/routes/PrivateRoute";

// import { CallPage } from "src/pages/CallPage/CallPage";
// import { ContactsPage } from "src/pages/ContactsPage/ContactsPage";
// import { TranslationPage } from "src/pages/TranslationPage";
// import { GroupCallPage } from "src/pages/GroupCallPage";

export function App() {
  React.useEffect(() => {
    const noSleep = new NoSleep();

    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        noSleep.enable();
      } else {
        noSleep.disable();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      noSleep.disable(); // Disable the wake lock when the component unmounts
    };
  }, []);

  return (
    <Suspense>
      <Routes>
        <Route path="" Component={PublicRoute}>
          <Route path={RoutesMap.SIGN_IN} Component={SignIn} />
        </Route>
        <Route path="" Component={PrivateRoute}>
          <Route index element={<SharedLayout />} />

          {/* <Route index element={<CallPage />} />
            <Route path={RoutesMap.CALLS} element={<CallPage />} />
            <Route path={RoutesMap.CONTACTS} element={<ContactsPage />} />
            <Route path={RoutesMap.TRANSLATIONS} element={<TranslationPage />} />
            <Route path={RoutesMap.GROUP_CALLS} element={<GroupCallPage />} />
            <Route path="*" element={null} /> */}
        </Route>
        <Route path={RoutesMap.IMAGE_PLAYGROUND} Component={ImagePlayground} />
      </Routes>
    </Suspense>
  );
}
