// We whitelist property keys for logging purposes
const pick = require('lodash/pick');

module.exports = x => pick(x, [
  'audioDeviceId',
  'audioFallbackEnabled',
  'audioSource',
  'channels',
  'classNames',
  'constraints',
  'disableAudioProcessing',
  'enableRenegotiation',
  'enableStereo',
  'facingMode',
  'fitMode',
  'frameRate',
  'height',
  'insertDefaultUI',
  'insertMode',
  'audioBitrate',
  'maxResolution',
  'minVideoBitrate',
  'mirror',
  'name',
  'publishAudio',
  'publishVideo',
  'resolution',
  'showControls',
  'style',
  'videoDeviceId',
  'videoDimensions',
  'videoSource',
  'width',
]);
