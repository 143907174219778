import React from "react";
import styled from "styled-components";

import { CallReason } from "src/types";

const CustomRadio = ({
  label,
  checked,
  value,
  handleCheck,
}: {
  label: CallReason | string;
  checked: boolean;
  value: string;
  handleCheck: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <RadioContainer checked={checked}>
      <RadioInput onChange={handleCheck} checked={checked} value={value} />
      <RadioLabel>{label}</RadioLabel>
    </RadioContainer>
  );
};

export default CustomRadio;

const RadioContainer = styled.label<{ checked: boolean }>(({ checked }) => ({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  padding: 8,
  borderRadius: 8,
  color: checked ? "#fff" : "black",
  backgroundColor: checked ? "#008dff" : "#fff",

  transition: "background-color 100ms linear, color 100ms linear",

  "&:hover": {
    ...(checked
      ? {}
      : {
          backgroundColor: "#9ac9f0",
          color: "#fff",
        }),
  },
}));

const RadioInput = styled.input.attrs({ type: "radio" })({
  display: "none",
});
const RadioLabel = styled.span({
  fontSize: "1rem",
});
