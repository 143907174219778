import { makeStyles, Box, Typography } from "@material-ui/core"
import React from "react"
import { useTranslation } from "react-i18next"
import Icon from "../../assets/folder.svg"
import Logo from "../../Logo"

const useStyles = makeStyles({
	defaultPage: {
		alignItems: "center",
		justifyContent: "center",
		height: "100%",
		display: "flex",
		flexDirection: "column"
	}
})

export const DefaultPage = () => {
	const { t } = useTranslation()
	const classes = useStyles()

	return (
		<Box className={classes.defaultPage}>
			<Logo />
		</Box>
	)
}
