module.exports = () => {
  const queue = [];

  let processing = false;
  const processQueue = async () => {
    if (processing) { return; }
    processing = true;
    while (queue.length) {
      const { fn, resolve, reject } = queue.shift();

      try {
        resolve(await fn());
      } catch (err) {
        reject(err);
      }
    }
    processing = false;
  };

  return (fn) => {
    const result = new Promise((resolve, reject) => {
      queue.push({ fn, resolve, reject });
    });
    processQueue();
    return result;
  };
};
