export const checkOpentokRole = (streamConnectionData: string, roleToCheck: string) => {
  try {
    const parsedData = JSON.parse(streamConnectionData);
    if (parsedData && parsedData.role) {
      const role = parsedData.role;
      return role === roleToCheck;
    } else {
      console.error("Missing role data or role is null/undefined.");
      return false;
    }
  } catch (error) {
    console.error("Error parsing connection data or missing role data:", error);
    return false;
  }
};
