import React, { useContext, useEffect, useState, useRef } from "react"
import {
	createSession,
	OTPublisher,
	OTSession,
	OTStreams,
	OTSubscriber,
	SessionHelper
} from "opentok-react"
import Lottie from "react-lottie"

import { RequestService } from "../../services/RequestService"
import { SocketService } from "../../services/SocketService"
import { CallService } from "../../services/CallService"
import { Box, Button, ButtonBase, Container, Typography } from "@material-ui/core"
import { useAuth } from "../../hooks/useAuth"
import { AuthService } from "../../services/AuthService"
import { AuthContext } from "../../reducers/AuthReducer"
import { useSocket } from "../../hooks/useSocket"
import { initSession, Session } from "@opentok/client"
import io from "socket.io-client"
import { useCallContext } from "../../reducers/Call/CallProvider"
import { CallActionTypes } from "../../reducers/Call/CallActionsTypes"
import PhoneIcon from "../../assets/ring-volume.svg"
import Logo from "../../Logo"
import DeclindeBtn from "../../assets/declineButton.svg"
import CamOnIcon from "../../assets/camOnIcon.svg"
import CamOffIcon from "../../assets/camOffIcon.svg"
import MicOnIcon from "../../assets/micOnIcon.svg"
import MicOffIcon from "../../assets/micOffIcon.svg"
import AcceptCallIcon from "../../assets/voiceCallButtonIcon.svg"
import AcceptCallLottie from "../../assets/lottie/call-incoming.json"
import { AccountCircle, ContactlessRounded, Contacts } from "@material-ui/icons"
import { fullHeight } from "../../utils/constants"

export const ContactsPage = () => {
	const [authState] = useContext(AuthContext)
	const { callState, dispatchCall, publisher, reserveCall, sessionRef } = useCallContext()

	const { selectedContact } = callState

	// useEffect(() => {
	// 	return () =>
	// 		dispatchCall({
	// 			type: CallActionTypes.SET_SELECTED_CONTACT,
	// 			payload: {},
	// 		});
	// }, []);

	return (
		<Box
			width="100%"
			// height={fullHeight}
			height="100px"
			style={{
				verticalAlign: "center",
				alignSelf: "center",
				alignItems: "center",
				justifyContent: "center",
				height: "100%",
				display: "flex",
				flexDirection: "column"
			}}
		>
			{/* {!!selectedCall.sessionId.length && !canStartCall ? (
				<Box
					display="flex"
					flexDirection="column"
					justifyContent="space-between"
					// bgcolor="red"
					height="70%"
					alignItems="center"
					padding={0}
					margin={0}
					style={{ overflowY: 'hidden' }}
				>
					<Typography variant="h4" align="center">
						Дзвінок за рахунок абонента
					</Typography>
					<Box alignItems="center">
						<Typography
							align="center"
							variant="subtitle1"
							style={{ color: '#757575' }}
						>
							Очікування
						</Typography>
						<Typography align="center" variant="h3">
							00:00
						</Typography>
					</Box>
					<Box
						style={{
							width: 180,
							// height: 180,
						}}
					>
						<Lottie
							options={{
								animationData: AcceptCallLottie,
								loop: true,
								autoplay: true,
							}}
						/>
						<img
							onClick={() => {
								reserveCall();
								setCanStartCall(true);
							}}
							src={AcceptCallIcon}
							style={{
								width: 120,
								height: 120,
								borderRadius: 120,
								alignSelf: 'center',
								boxShadow:
									'0px 11px 15px rgba(0, 0, 0, 0.2), 0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12)',
								// marginBottom:  20,
								backgroundColor: '#4CAF50',
								position: 'relative',
								left: '17%',
								bottom: '70%',
							}}
						/>
					</Box>
					<Typography
						align="center"
						style={{ color: '#757575', marginTop: -180 }}
					>
						З’єднати
					</Typography>
				</Box>
			) : !!sessionId.length ? (
				<Box width="100%">
					<OTSession
						ref={sessionRef}
						eventHandlers={sessionEventHandlers}
						onConnect={() => {
							// initSession(sessionParams.apiKey, sessionParams.sessionId);
							console.log('WE ARE CONNECTED');
							canStartCall &&
								sessionRef?.current?.sessionHelper.session.publish(
									publisher
								);
						}}
						apiKey={'47345991'}
						// apiKey={'47345711'}
						sessionId={sessionId}
						// token={currentCall.token}
						token={token}
					>
						<OTStreams>
							<OTSubscriber
								properties={{
									width: '100%',
									height: '100%',
									width: '100%',
									height: '100vh',
									subscribeToVideo: true,
									subscribeToAudio: false,
								}}
							/>
						</OTStreams>
					</OTSession>

					<Box
						style={{
							position: 'absolute',
							bottom: 40,
							width: 420,
							left: 0,
							right: 0,
							marginLeft: 'auto',
							marginRight: 'auto',
						}}
					>
						<Box
							width={140}
							display="inline-block"
							alignItems="center"
						>
							<ButtonBase
								onClick={() => {
									publisher.publishVideo(publishVideo);
									setPublishVideo(!publishVideo);
								}}
								style={{
									// 	width: 60,
									// 	height: 60,
									borderRadius: 60,
									// 	// backgroundColor: '#00000050',
								}}
							>
								<img
									src={!publishVideo ? CamOnIcon : CamOffIcon}
								/>
							</ButtonBase>
							<Typography align="justify">Камера</Typography>
						</Box>
						<Box
							width={140}
							display="inline-block"
							alignItems="center"
						>
							<ButtonBase
								onClick={() => {
									publisher.publishAudio(publishAudio);
									setPublishAudio(!publishAudio);
								}}
								style={{
									borderRadius: 60,
								}}
							>
								<img
									src={!publishAudio ? MicOnIcon : MicOffIcon}
								/>
							</ButtonBase>
							<Typography align="justify">Мікрофон</Typography>
						</Box>
						<Box
							width={140}
							display="inline-block"
							alignItems="center"
						>
							<ButtonBase
								onClick={() => {
									dispatchCall({
										type: CallActionTypes.END_CALL,
									});
									setCanStartCall(false);
								}}
								style={{
									width: 56,
									height: 58,
									borderRadius: 58,
								}}
							>
								<img src={DeclindeBtn} />
							</ButtonBase>
							<Typography align="justify">Завершити</Typography>
						</Box>
					</Box>
				</Box>
			) : ( */}
			<>
				{/* <img
					src={ContactlessRounded}
					style={{
						marginLeft: 'auto',
						marginRight: 'auto',
						display: 'block',
					}}
				/> */}
				<Logo />
			</>
			{/* )} */}
		</Box>
	)
}
