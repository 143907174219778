import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Logo from "src/Logo";
import { useCallContext } from "src/reducers/Call/CallProvider";
import { selectCurrentGroupCall } from "src/reducers/GroupCall";
// import { setCalls } from "src/reducers/GroupCall";
import {
  cleanCurrentGroupSession,
  // removeCall,
  // setCurrentGroupSession,
} from "src/reducers/GroupCall/GroupCallSlice";

import { GroupTranslation } from "src/types";
import GroupCallView from "./GroupCallView";
import { CallService } from "src/services/CallService";

// import TestGroup from "./TestGroup";
// import { GroupCallService } from "src/services/GroupCallService";

// import React from "react";
// import { OTSession, OTPublisher, OTStreams, OTSubscriber, createSession } from "opentok-react";
// import { CallPage } from "../CallPage/CallPage";

// class GroupSessionComponent extends React.Component<
//   { apiKey: string; sessionId: string; token: string; selectedGroupSession: GroupSession },
//   { streams: any[] }
// > {
//   sessionHelper: any;
//   constructor(props: any) {
//     super(props);
//     this.state = {
//       streams: [],
//     };
//   }

//   componentWillMount() {
//     this.sessionHelper = createSession({
//       apiKey: this.props.apiKey,
//       sessionId: this.props.sessionId,
//       token: this.props.token,
//       onStreamsUpdated: (streams) => {
//         console.log("Current subscriber streams:", streams);
//         // this.setState({ streams });
//         this.setState((prevState) => ({
//           streams: [...prevState.streams, { stream: streams }],
//         }));
//       },
//     });
//   }
//   componentWillUnmount() {
//     this.sessionHelper.disconnect();
//   }

//   render() {
//     console.log("helper ===>", this.sessionHelper.streams);
//     const { streams } = this.state;
//     console.log("streams array --====>", streams);

//     return (
//       <>
//         <a rel="noreferrer" target="_blank" href={this.props.selectedGroupSession.link}>
//           {this.props.selectedGroupSession.link}
//         </a>
//         <OTSession
//           apiKey={this.props.apiKey}
//           sessionId={this.props.sessionId}
//           token={this.props.token}
//         >
//           <>
//             <OTPublisher />
//             <>
//               {streams.map(({ id, stream }: any) => {
//                 console.log("stream map ===>", stream[0]);

//                 return (
//                   <OTSubscriber key={id} session={this.sessionHelper.session} stream={stream[0]} />
//                 );
//               })}
//             </>
//             {/* {this.sessionHelper.streams.map((stream: any) => {
//             return (
//               <OTSubscriber key={stream.id} session={this.sessionHelper.session} stream={stream} />
//             );
//           })} */}
//             {/* <OTPublisher />
//           <OTStreams>
//             <OTSubscriber />
//           </OTStreams> */}
//           </>
//         </OTSession>
//       </>
//     );
//   }
// }

export default function GroupCallPage() {
  const selectedGroupCall: GroupTranslation = useSelector(selectCurrentGroupCall);
  const dispatch = useDispatch();
  const { publisher } = useCallContext() as any;
  // useEffect(() => {
  //   return () => {
  //     dispatch(cleanCurrentGroupSession());
  //   };
  // }, [dispatch]);
  // console.log("selectedGroupCall", selectedGroupCall);
  //   {
  //     "id": 193,
  //     "session_name": "разработчик тест (можно не брать)",
  //     "company_id": "7945f8c0-e805-4906-8cda-47bac009388d",
  //     "company_name": "Фора",
  //     "employee": "hgak0k74@gmail.com",
  //     "duration": 9,
  //     "link": "https://api.tdl.com.ua/sessions/fc9d35bed2c8d33989ccab3985e8f2e1",
  //     "status": "closed",
  //     "interpreter_id": null,
  //     "ot_session_id": "1_MX40NjAzMzQ3Mn5-MTYxMDQzOTYwMzg5OX5wcEswWDlOMmNySVpQQzZyOWlnRkNBTXB-UH4",
  //     "listeners": 0,
  //     "speaker_connected": false,
  //     "actual_duration": 0,
  //     "event_date": 1610438400
  // }

  // <GroupCallView
  //   selectedGroupSession={selectedGroupCall}
  //   publisher={publisher.current}
  //   // apiKey={selectedGroupCall.api_key}
  //   // sessionId={selectedGroupCall.ot_session_id}
  //   // token={selectedGroupCall.token}
  //   onDisconnect={() => {
  //     console.log("hello");
  //   }}
  // />
  // console.log("selectedGroupCall", selectedGroupCall);
  return selectedGroupCall ? (
    <>
      {selectedGroupCall.api_key && selectedGroupCall.session_id && selectedGroupCall.token ? (
        <>
          <GroupCallView
            selectedGroupSession={selectedGroupCall}
            publisher={publisher.current}
            onDisconnect={() => {
              CallService.endGroupWebProCall(selectedGroupCall.session_id).then((d: any) => {
                if (!d.success) {
                  console.log(d.message);
                } else {
                  dispatch(cleanCurrentGroupSession());
                  // dispatch(removeCall(d.call.id))
                }
              });
            }}
          />
          {/* <GroupCallView
            apiKey={selectedGroupCall.api_key}
            publisher={publisher.current}
            sessionId={selectedGroupCall.ot_session_id}
            token={selectedGroupCall.token}
          /> */}
        </>
      ) : null}
    </>
  ) : (
    <PlaceholderLogo />
  );
}

function PlaceholderLogo() {
  return (
    <div
      style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}
    >
      <Logo />
    </div>
  );
}
