import React from "react";
import { useParams } from "react-router";

import { DynamicParams } from "src/types";
// import { HoveredFile } from "src/pages/TranslationPage/HoveredFile";
import ImageManipulator from "./ImageManipulator";

export default function ImagePlayground() {
  const { imageSrc } = useParams<Partial<DynamicParams>>();

  const imageUrl = "https://api.tdl.com.ua/storage/requests/" + imageSrc;

  return (
    <>
      <ImageManipulator
        wrapperStyle={{
          width: "70vw",
          height: "70vh",
          margin: "auto",
          marginTop: "60px",
        }}
        imageUrl={imageUrl}
      />
    </>
  );
}
