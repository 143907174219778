import { URI } from "../constants/defaultConstants";
import { AuthService } from "./AuthService";
import { RequestService } from "./RequestService";

// const commonGroupCallHeaders = {
//   "Content-Type": "application/json",
//   Authorization: `Bearer ${AuthService.getUser()?.token}`,
//   "X-Interpreter-Token": AuthService.getUser()?.token as string,
// };

export const GroupCallService = {
  async getGroupCallsList() {
    try {
      const response = await RequestService({
        data: {},
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AuthService.getUser()?.token}`,
          "X-Interpreter-Token": AuthService.getUser()?.token as string,
        },
      }).getRequest(URI.getGroupCalls);

      return response;
    } catch (error) {
      const errorMessage = (error as string).toString();
      console.log("getGroupCallsList:", errorMessage);
      throw new Error(errorMessage);
    }
  },

  async getPlannedGroups() {
    try {
      const response = await RequestService({
        data: {},
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AuthService.getUser()?.token}`,
          "X-Interpreter-Token": AuthService.getUser()?.token as string,
        },
      }).getRequest(URI.getPlannedGroups);

      return response;
    } catch (error) {
        const errorMessage = (error as string).toString();
      console.log("getGroupCallsList:", errorMessage);
      throw new Error(errorMessage);
    }
  },

  async joinToGroupSession(sessionId: number) {
    try {
      const response = await RequestService({
        data: {},
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AuthService.getUser()?.token}`,
          "X-Interpreter-Token": AuthService.getUser()?.token as string,
        },
      }).getRequest("/api/interpreter/group/" + sessionId + "?lang=ru");

      return response;
    } catch (error) {
      const errorMessage = (error as string).toString();
      console.log("joinToGroupSession:", errorMessage);
      throw new Error(errorMessage);
    }
  },
};
